import React, {
  useEffect,
  useLayoutEffect,
  useState,
  useContext,
  Suspense,
  lazy,
} from 'react';
// import { useHistory } from "react-router-dom";
import { useHistory } from 'react-router';

//Assets
// import PlaceHolderLogo from '../../../assets/images/placeHolder.svg';
// import FullColorBlackText from "../../../assets/images/FullColorBlackText.svg";
// import defaultLogo from "../../../assets/images/FullColorOnlyLogo.png";
import whiteLogo from '../../../assets/images/WhiteLogoWithText.svg';

//Muicons
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import Close from '@mui/icons-material/Close';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import MenuIcon from '@mui/icons-material/Menu';
import AccountCircle from '@mui/icons-material/AccountCircle';

//MuiComponents
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import AlerTitle from '@mui/material/AlertTitle';
import Fade from '@mui/material/Fade';
import MobileStepper from '@mui/material/MobileStepper';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Drawer from '@mui/material/Drawer';
import Home from '@mui/icons-material/Home';
import Icon from '@mui/material/Icon';
import ArrowDropDown from '@mui/icons-material/ArrowDropDown';
import { Collapse, Link, ListItem } from '@mui/material';

//Services
// import enrollmentService from "../../../services/enrollment-service";
// import attachmentService from "../../../services/attachment-service";
import memberService from '../../../services/member-service';
import authService from '../../../services/auth-service';

//Our Components
// import ExternalEnrollmentHeader from "./ExternalEnrollmentHeader";
import PoweredBy from '../external-enrollments/PoweredBy';
import InfinityLoader from '../../UH-loading-animation/InfinityLoader';
import DrawerMenuItem from './portal-home/DrawerMenuItem';
import PortalHome from './portal-home/PortalHome';

import { ColorModeContext } from '../../../ExternalApp';
import PortalPlanInfo from './portal-planinfo/PortalPlanInfo';
import PortalSupport from './portal-support/PortalSupport';
import PortalSettings from './portal-settings/PortalSettings';
import enrollmentService from '../../../services/enrollment-service';
import walletService from '../../../services/wallet-service';
import paymentsService from '../../../services/payments-service';
import attachmentService from '../../../services/attachment-service';
import userService from '../../../services/user-service';

// const DrawerMenuItem = lazy(() => import('./DrawerMenuItem'));

// const DashHome = React.lazy(() => import('../../external-dashboard/dash-home/DashHome'));
// const DashPersonal = React.lazy(() => import('../../external-dashboard/dash-personal/DashPersonal'));
// // const DashBenefits = React.lazy(() => import('../../external-dashboard/dash-benefits/DashBenefits'));
// const DashWallet = React.lazy(() => import('../../external-dashboard/dash-wallet/DashWallet'));
// const DashDocuments = React.lazy(() => import('../../external-dashboard/dash-documents/DashDocuments'));
// const DashClaims = React.lazy(() => import('../../external-dashboard/dash-claims/DashClaims'));
// const DashSettings = React.lazy(() => import('../../external-dashboard/dash-settings/DashSettings'));
// const DashHelp = React.lazy(() => import('../../external-dashboard/dash-help/DashHelp'));

export default function MemberPortal(props) {
  const history = useHistory();
  let colorMode = useContext(ColorModeContext);
  const componentStyles = {
    imageContainer: { display: 'flex', justifyContent: 'center' },
    imageStyles: {
      maxHeight: '100px',
      maxWidth: '100%',
      objectFit: 'contain',
    },
    infoContainer: {
      display: 'flex',
      flexDirection: 'column',
      maxWidth: '100vw',
      margin: '0 auto',
      overflowY: 'auto',
    },
    loginButton: {
      height: 50,
      borderRadius: 100,
      width: '152px',
      fontWeight: '700!important',
      fontSize: '15px',
      margin: '20px auto',
    },
    //'What you will need' section
    stepsContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      maxWidth: '270px',
      margin: '0px auto',
    },
    stepsTitle: {
      textAlign: 'center',
    },
    iconListContainer: {
      display: 'flex',
      flexDirection: 'column',
      margin: '10px auto',
    },
    iconRow: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      margin: '6px 0px',
      // maxWidth: "280px",
    },
    iconText: {
      fontFamily: 'Archivo',
      fontSize: '14px',
      fontWeight: 400,
      color: 'text.primary',
      lineHeight: '20px',
    },
    icon: { height: '22px', marginRight: '30px', color: 'text.secondary' },
    //Large Blue Button
    largeButtonContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      width: '100%',
    },
    //Secure Message
    secureMessageContainer: {
      display: 'flex',
      flexDirecton: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      margin: '25px auto 10px',
    },
    secureMessageIcon: {
      height: '24px',
      color: 'primary.secureDataMsg',
      marginRight: '6px',
    },
    secureMessageText: {
      color: 'primary.secureDataMsg',
      fontSize: '12px',
      lineHeight: '32px',
      letterSpacing: '1px',
    },
    //Text Description/Paragraph
    textDescription: {
      textAlign: 'center',
      margin: '20px auto',
      fontFamily: 'Archivo',
      fontWeight: 500,
      color: 'text.secondary',
      fontSize: '14px',
    },
    mobileStepper: { padding: '0px', backgroundColor: 'transparent' },
  };

  const [isAuthenticated, setIsAuthenticated] = useState();
  useEffect(() => {
    let _isAuthenticated = props.checkAuthentication();
    setIsAuthenticated(_isAuthenticated);
  }, []);
  //potentially we can do one call to get all the data we need for the dashboard, so
  //actively covered benefits | some personal info | payment info | claims and more

  const [thisUser, setThisUser] = useState({});
  const getThisUser = async () => {
    try {
      // let _thisUser = await userService.getMeExternal();
      let _thisUser = await userService.getMe();
      if (_thisUser) setThisUser(_thisUser);
    } catch (error) {
      console.log('error getting user', error);
    }
  };

  const [thisMember, setThisMember] = useState({});
  const getThisMember = async () => {
    try {
      let _thisMember = await memberService.getMe();
      if (_thisMember) setThisMember(_thisMember);
    } catch (error) {
      console.log('error getting member', error);
    }
  };
  const [thisMemberPhone, setThisMemberPhone] = useState({});
  const getThisMemberPhone = async () => {
    try {
      let _thisMemberPhone = await memberService.getPhones();
      if (_thisMemberPhone) setThisMemberPhone(_thisMemberPhone);
    } catch (error) {
      console.log('error getting member phone', error);
    }
  };

  const [thisMembersDocuments, setThisMembersDocuments] = useState([]);
  const getThisMembersDocuments = async () => {
    try {
      let _thisMembersDocuments = await enrollmentService.getAttachments();
      if (_thisMembersDocuments) setThisMembersDocuments(_thisMembersDocuments);
    } catch (error) {
      console.log('error getting member documents', error);
    }
  };

  const [activeCoverage, setActiveCoverage] = useState({});
  const getActiveCoverage = async () => {
    try {
      let _activeCoverage = await memberService.getActiveCoverage();
      if (_activeCoverage) setActiveCoverage(_activeCoverage);
    } catch (error) {
      console.log('error getting active coverage', error);
    }
  };

  const [enrollments, setEnrollments] = useState([]);
  const getEnrollments = async () => {
    try {
      let _enrollments = await enrollmentService.getMe();
      if (_enrollments) setEnrollments(_enrollments);
    } catch (error) {
      console.log('error getting enrollments', error);
    }
  };

  const [allPayments, setAllPayments] = useState([]);
  const getAllPayments = async () => {
    try {
      let _allPayments = await paymentsService.getAllPaymentsByMemberId(
        thisMember.id
      );
      // console.log("ALL PAYMENTS", _allPayments);
      setAllPayments(_allPayments);
    } catch (error) {
      console.error('error getting all payments', error);
    }
  };

  //get any one-time payments that have been scheduled
  const [oneTimePayments, setOneTimePayments] = useState([]);
  const getOneTimePayments = async () => {
    try {
      let _oneTimePayments = await paymentsService.getOneTimePaymentsByMemberId(
        thisMember.id
      );

      setOneTimePayments(_oneTimePayments);
    } catch (error) {
      console.error('error getting one-time payments', error);
    }
  };

  const [payment, setPayment] = useState({});
  const getPayment = async () => {
    let _pay = await walletService.getAllPaymentSchedules();
    // console.log('payment', _pay);
    setPayment(_pay);
  };

  const [loading, setLoading] = useState(false);

  const [dashPageState, setDashPageState] = useState({
    home: true,
    planInfo: false,
    support: false,
    settings: false,
  });

  const [pageSummaryObj, setPageSummaryObj] = useState({
    thisMember: {},
    thisMemberBenefits: {},
    thisMemberPayment: {},
  });

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  // useEffect(() => {
  //    setLoading(true);
  //    setTimeout(() => {
  //       setLoading(false);
  //    }, 2000);
  //    // return () => {
  //    //    setLoading(false);
  //    // }
  // }, [anchorEl]);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const goTo = (page) => {
    setDashPageState({
      // ...dashPageState,
      [page]: true,
    });
    handleClose();
  };

  const swapTheme = () => {
    colorMode.ToggleColorMode();
  };

  // const [collapse, setCollapse] = useState(false);
  const [enrollmentAlert, setEnrollmentAlert] = useState(true);
  const onEnrollmentAlertClose = () => {
    setEnrollmentAlert(false);
  };

  const handleLogoutClick = async () => {
    // console.log('logout clicked');
    await authService.signout();
    history.push('/login');
  };

  useEffect(() => {
    getThisMember();
    getThisMemberPhone();
    getEnrollments();
    getPayment();
    getThisUser();
  }, []);
  useEffect(() => {
    if (thisMember.id) {
      getThisMembersDocuments();
      // getActiveCoverage();
      // getAllPayments();
      // getOneTimePayments();
    }
  }, [thisMember]);

  //   console.log('thisMember', thisMember);
  // console.log('thisMemberPhone', thisMemberPhone);
  // console.log('activeCoverage', activeCoverage);
  //   console.log('enrollments', enrollments);
  //   console.log('allPayments', allPayments);
  //   console.log('oneTimePayments', oneTimePayments);
  console.log('Docs', thisMembersDocuments);

  return (
    <Box sx={componentStyles.infoContainer}>
      {!isAuthenticated && (
        <>
          <Typography sx={componentStyles.textDescription}>
            Not Authenticated, please login.
          </Typography>
          <Button
            variant="contained"
            sx={componentStyles.loginButton}
            onClick={() => history.push('/login')}
          >
            Login
          </Button>
        </>
      )}
      {isAuthenticated && (
        <>
          {loading && (
            <>
              <InfinityLoader
                style={{
                  position: 'absolute',
                  zIndex: 5,
                  width: '100%',
                  height: '100vh',
                  backgroundColor: '#00000050',
                  backdropFilter: 'blur(10px)',
                }}
              />
              <style>
                {`
                           div.lottie-div > svg {
                              height:55px!important;
                              position:absolute;
                              top:50%;
                           }
                        `}
              </style>
            </>
          )}
          <AppBar
            component={'nav'}
            sx={{ display: 'flex', backgroundColor: 'primary.main' }}
          >
            <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <IconButton
                size="large"
                edge="start"
                // sx={{ color: 'background.paper', mr: 2 }}
                aria-label="menu"
                onClick={handleMenu}
                // disableRipple={true}
                sx={{
                  color: 'background.paper',
                  ml: 0,
                  svg: { height: '35px', width: '35px' },
                  padding: '0px',
                  // transition: 'transform 0.2s ease-in-out',
                  // transform: collapse ? 'rotate(-180deg)' : 'rotate(0deg)',
                }}
              >
                <MenuIcon />
              </IconButton>

              <Button
                edge="end"
                size="large"
                // aria-label="account of current user"
                // aria-controls="menu-appbar"
                // aria-haspopup="true"
                sx={{
                  color: 'background.paper',
                  pr: 0,
                  // height: '35px', width: '35px' ,
                  // position: 'absolute', right: '0px',bottom:'-29px'
                }}
                disableRipple={true}
                onClick={swapTheme}
              >
                <img style={{ height: '25px' }} src={whiteLogo} />
              </Button>
            </Toolbar>
          </AppBar>
          <Drawer open={open} onClose={handleClose} anchor={'left'}>
            <AppBar
              position="static"
              sx={{ display: 'flex', backgroundColor: 'primary.main' }}
            >
              <Toolbar
                sx={{ display: 'flex', justifyContent: 'space-between' }}
              >
                <IconButton
                  size="large"
                  edge="start"
                  color="inherit"
                  // aria-label="menu"
                  onClick={handleClose}
                  sx={{ color: 'background.paper', mr: 2 }}
                  disableRipple={true}
                >
                  <Close />
                </IconButton>

                <Box>
                  <Button
                    edge="end"
                    size="large"
                    // aria-label="account of current user"
                    // aria-controls="menu-appbar"
                    // aria-haspopup="true"
                    sx={{
                      color: 'background.paper',
                      pr: 0,
                      // height: '35px', width: '35px' ,
                      // position: 'absolute', right: '0px',bottom:'-29px'
                    }}
                    disableRipple={true}
                    onClick={swapTheme}
                  >
                    <img style={{ height: '25px' }} src={whiteLogo} />
                  </Button>
                </Box>
              </Toolbar>
            </AppBar>
            <Box
              className="flex-container"
              flex={1}
              overflow="auto"
              style={{
                flexWrap: 'wrap',
                minHeight: 'calc(100vh)',
                margin: 'auto',
                padding: '8px',
                width: '100vw',
                // maxWidth: '500px'
              }}
              sx={{
                bgcolor: 'background.default',
                color: 'text.primary',
              }}
            >
              <DrawerMenuItem NavigateClick={() => goTo('home')} text="Home" />
              <DrawerMenuItem
                NavigateClick={() => goTo('planInfo')}
                text="Plan Information"
              />
              <DrawerMenuItem
                NavigateClick={() => goTo('support')}
                text="Support"
              />
              <DrawerMenuItem
                NavigateClick={() => goTo('settings')}
                text="Settings"
              />
              <DrawerMenuItem NavigateClick={handleLogoutClick} text="Logout" />
            </Box>
          </Drawer>
          {dashPageState.home && (
            <>
              <PortalHome
                isAuthenticated={isAuthenticated}
                thisMember={thisMember}
                getThisMember={getThisMember}
                thisMemberPhone={thisMemberPhone}
                enrollments={enrollments}
                payment={payment}
              />
            </>
          )}
          {dashPageState.planInfo && (
            <>
              <PortalPlanInfo isAuthenticated={isAuthenticated} />
            </>
          )}
          {dashPageState.support && (
            <>
              <PortalSupport isAuthenticated={isAuthenticated} />
            </>
          )}
          {dashPageState.settings && (
            <>
              <PortalSettings
                isAuthenticated={isAuthenticated}
                thisMember={thisMember}
                getThisMember={getThisMember}
                thisUser={thisUser}
              />
            </>
          )}

          <PoweredBy />

          {/* {!isAuthenticated && history.push('/login')} */}
        </>
      )}
    </Box>
  );
}
