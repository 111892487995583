import React, { useEffect, useLayoutEffect, useState, useContext } from 'react';
import { useHistory } from 'react-router';
//Assets
import PlaceHolderLogo from '../../../assets/images/placeHolder.svg';
import FullColorBlackText from '../../../assets/images/FullColorBlackText.svg';
import FallbackLogo from '../../../assets/images/SourceFileonly icon.png';

//Services
import authService from '../../../services/auth-service';
import memberService from '../../../services/member-service';

//MuiComponents
import { useTheme, useMediaQuery } from '@mui/material';
import Button from '@mui/material/Button';
import { Divider, FormControlLabel, Typography } from '@mui/material';
import Link from '@mui/material/Link';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';

//Our Components
import ExternalForgot from '../external-forgot/ExternalForgot';
import PoweredBy from '../external-enrollments/PoweredBy';
import { ColorModeContext } from '../../../ExternalApp';
import InfinityLoader from '../../UH-loading-animation/InfinityLoader';
import PasswordVisibilityIcon from '../../../assets/visibilityIcons/PasswordVisibilityIcon';
import ExternalRegisterOrLoginEnrollmentHeader from '../external-enrollments/ExternalRegisterOrLoginEnrollmentHeader';

//shared styles
import externalEnrollmentSharedStyles from '../externalEnrollmentSharedStyles';
import CheckBox from '@mui/icons-material/CheckBox';

//component styles
const componentStyles = {
  input: {
    margin: '10px auto 10px ',
  },
  alert: {
    margin: '0px auto 20px',
  },
  forgotPasswordContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  keepLoggedInContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: '20px',
    height: '38px',
  },
  checkbox: { padding: '0px', marginRight: '5px' },
};

function ExternalLoginAccount(props) {
  console.log('ExternalLoginAccount.js props:', props);
  const colorMode = useContext(ColorModeContext);

  const [values, setValues] = useState({
    userName: '',
    password: '',
  });
  const [stayLoggedIn, setStayLoggedIn] = useState(true);

  const history = useHistory();
  const [displayLoginError, setDisplayLoginError] = useState(false);

  //Forgot password
  const [forgotPassword, setForgotPassword] = useState(false);

  const forgetClick = () => {
    setForgotPassword(true);
  };

  //Handlers
  const handleValueChange = (e) => {
    let inputVal = e.target.value;
    let inputProperty = e.target.getAttribute('data-property');
    // console.log(inputProperty, inputVal)
    setValues({
      ...values,
      [inputProperty]: inputVal,
    });
  };
  const passwordKeyPressHandler = (e) => {
    if (e.key === 'Enter') loginAsync();
    if (e.key === ' ') e.preventDefault();
  };

  const getCookie = (cookieName) => {
    let name = `${cookieName}=`;
    let cookieArray = document.cookie.split(';');
    let value = '';
    cookieArray.forEach((cookie) => {
      if (cookie.includes(name)) value = cookie.replace(name, '').trim();
    });
    return value;
  };
  const removeCookie = (cookieName) => {
    let hostArray = window.location.hostname.split('.').reverse();
    let domain = `.${hostArray[1]}.${hostArray[0]}`;

    let name = `${cookieName}=`;
    let cookieArray = document.cookie.split(';');

    cookieArray.forEach((cookie) => {
      if (cookie.includes(name))
        document.cookie = `${name};Domain=${domain};expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
    });
  };

  const loginAsync = async (e) => {
    props.setLoading(true);
    //e.preventDefault();

    let isAuthenticated = false;
    const credentials = {
      username: values.userName,
      password: values.password,
    };
    isAuthenticated = await authService.authenticate(credentials);
    // props.checkAuthentication()
    // console.log('isAuthenticated',isAuthenticated)

    if (isAuthenticated) {
      setDisplayLoginError(false);
      let domain = getCookie('subdomain');
      console.log('domain', domain);
      if (!domain.includes('cornerstone')) {
        window.location.href = `https://${domain}/enrollments`;
      } else {
        window.location.href = `https://${domain}`;
      }
      props.setLoading(false);
    } else {
      // console.log('not successful');
      props.setLoading(false);
      setDisplayLoginError(true);
    }
  };

  const registerClickFromLogin = () => {
    props.setLoading(true);
    setTimeout(() => {
      history.push('/register');
      props.setLoading(false);
    }, 250);
  };

  const [organization, setOrganization] = useState({});

  const getThisOrganization = async () => {
    try {
      let thisOrganization = await props.getOrganization();
      setOrganization(thisOrganization);
    } catch (e) {
      setOrganization('');
    }
  };

  // Check if the user is already logged in, and if the token is still valid, redirect them.
  const checkToken = async () => {
    let domain = getCookie('subdomain');
    let expiration = getCookie('expiration');
    let currentDate = new Date();
    console.log(
      'expiration:',
      expiration,
      'currentDate:',
      currentDate,
      'domain:',
      domain
    );
    if (domain.length > 0 && expiration.length > 0) {
      if (new Date(expiration) < currentDate) {
        console.log('Token expired.');
      } else {
        console.log('Valid token');
        if (!domain.includes('cornerstone')) {
          window.location.href = `https://${domain}/enrollments`;
        } else {
          window.location.href = `https://${domain}`;
        }
      }
    }
  };

  useEffect(() => {
    getThisOrganization();
    checkToken();
  }, []);

  const getOrganizationLogo = () => {
    if (organization.id !== null) {
      return `/api/image/organization/${organization.id}`;
    } else return FallbackLogo;
  };

  let theme = useTheme();

  const debugTheme = () => {
    // console.log('THEME DEBUG',theme)
    // console.log('COLOR MODE DEBUG',colorMode)
    colorMode.ToggleColorMode();
  };

  const [imgFetchAttempts, setImgFetchAttempts] = useState(0);
  const handleLogoError = (e) => {
    console.log('logo img error', FallbackLogo);
    setImgFetchAttempts((prev) => prev++);
    if (imgFetchAttempts <= 10) e.target.src = FallbackLogo;
  };

  return (
    <Box
      sx={externalEnrollmentSharedStyles.externalPreEnrollmentParentContainer}
    >
      {props.loading && (
        <>
          <InfinityLoader
            style={externalEnrollmentSharedStyles.infinityLoader}
          />
          <style>
            {`
              div.lottie-div > svg {
                  height:55px!important;
                  position:absolute;
                  top:50%;
              }
              `}
          </style>
        </>
      )}

      <Box
        id="enrollment-login"
        sx={externalEnrollmentSharedStyles.componentContainer}
      >
        <Box>
          {/* Header */}
          <ExternalRegisterOrLoginEnrollmentHeader
            organization={organization}
            login={true}
            forgotPassword={forgotPassword}
          />
          {/* Input fields */}
          {!forgotPassword ? (
            <>
              <TextField
                fullWidth
                sx={componentStyles.input}
                label="Email"
                variant="outlined"
                value={values.userName}
                onChange={handleValueChange}
                inputProps={{ 'data-property': 'userName' }}
                autoComplete="email"
                type="email"
                pattern=".+@.+\.com"
              />
              <TextField
                fullWidth
                sx={componentStyles.input}
                type="password"
                label="Password"
                variant="outlined"
                value={values.password}
                onChange={handleValueChange}
                inputProps={{ 'data-property': 'password' }}
                onKeyPress={passwordKeyPressHandler}
                InputProps={{
                  // endAdornment: passwordIcon,
                  endAdornment: <PasswordVisibilityIcon />,
                  sx: { paddingRight: '0px' },
                }}
              />

              {/* Forgot password, keep logged in, submit and register buttons */}
              <Box sx={componentStyles.forgotPasswordContainer}>
                <Button onClick={forgetClick}>Forgot Password</Button>
              </Box>

              <Box sx={componentStyles.keepLoggedInContainer}>
                <Checkbox
                  sx={componentStyles.checkbox}
                  checked={stayLoggedIn}
                  onClick={() => setStayLoggedIn((prev) => !prev)}
                />
                <Typography variant="body1">Keep me logged in </Typography>
              </Box>

              {displayLoginError && (
                <Alert severity="error" sx={componentStyles.alert}>
                  Invalid Email or Password
                </Alert>
              )}
              <Box>
                <Button
                  variant="contained"
                  fullWidth
                  sx={{
                    ...externalEnrollmentSharedStyles.largeBlueButton,
                    marginBottom: '10px',
                  }}
                  onClick={loginAsync}
                  color="primary"
                >
                  Log in
                </Button>
              </Box>

              <Divider sx={externalEnrollmentSharedStyles.divider}>Or</Divider>

              <Box>
                <Button
                  variant="outlined"
                  fullWidth
                  sx={externalEnrollmentSharedStyles.largeBlueButton}
                  onClick={registerClickFromLogin}
                >
                  Register
                </Button>
              </Box>
            </>
          ) : (
            <>
              <ExternalForgot setForgotPassword={setForgotPassword} />
            </>
          )}
        </Box>
        <PoweredBy />
      </Box>
    </Box>
    //
  );
}

export default ExternalLoginAccount;
