import { format, parseISO } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';

let today = new Date();
export let day = today.getDate();
export let month = today.getMonth() + 1;
export let year = today.getFullYear();
day = day < 10 ? '0' + day : day;
month = month < 10 ? '0' + month : month;
let dateMax = `${year - 18}-${month}-${day}`;
let dateMaxObj = new Date(dateMax);
let dateMin = `${year - 100}-${month}-${day}`;
let dateMinObj = new Date(dateMin);

//TIME ZONE
export const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

//Format all UTC dates to date obj with user's local timezone
// date comes in looking like this: 1984-08-10T00:00:00+00:00

//USE FOR all dats where we do not care about time
export function formatUTCDateToObjWithTimeZone(date) {
  //take off the time part of the date string for DOBs
  date = date.split('T')[0];
  const dateObj = parseISO(date); //updates any utc date string into date object using local timezone
  const localTimezoneDateObj = utcToZonedTime(dateObj, userTimeZone);
  // console.log('localDate',localTimezoneDateObj)
  return localTimezoneDateObj;
}
//format date AND time for history tab display or when we care about the TIME portion of UTC date string
export function parseUTCDateToZonedTime(date) {
  const dateObj = parseISO(date);
  const localTimezoneDateObj = utcToZonedTime(dateObj, userTimeZone);
  return localTimezoneDateObj;
}

//format date obj to short date string 'yyyy-MM-dd'
export function formatDateObjYYYYMMDD(dateObj) {
  const formattedDate = format(dateObj, 'yyyy-MM-dd');
  return formattedDate;
}

//format date obj to short date string for nice display 'MM/dd/yyyy'
export function formatDateObjMMDDYYYYDisplay(dateObj) {
  const formattedDate = format(dateObj, 'MM/dd/yyyy');
  return formattedDate;
}

//format utc date string to short date string
export function formatUTCToShortDate(utcDate) {
  const dateObj = formatUTCDateToObjWithTimeZone(utcDate);
  const formattedDate = format(dateObj, 'MM/dd/yyyy');
  return formattedDate;
}

export function formatUTCToMonthDay(utcDate) {
  const dateObj = formatUTCDateToObjWithTimeZone(utcDate);
  const formattedDate = format(dateObj, 'MMM do');
  return formattedDate;
}

//get user's time converted to MST in 24hr formate
export function convertUserTimeToMST() {
  const date = new Date();
  const mountainTime = date.toLocaleTimeString('en-US', {
    timeZone: 'America/Denver',
    hour12: false,
  });
  return mountainTime;
}

//DEVICE DETECT
export function checkMobileDevice() {
  if (
    navigator.userAgent.match(/Android/i) ||
    navigator.userAgent.match(/webOS/i) ||
    navigator.userAgent.match(/iPhone/i) ||
    navigator.userAgent.match(/iPad/i) ||
    (navigator.userAgent.includes('Mac') && 'ontouchend' in document) ||
    navigator.userAgent.match(/iPod/i) ||
    navigator.userAgent.match(/BlackBerry/i) ||
    navigator.userAgent.match(/Windows Phone/i)
  ) {
    return true;
  } else {
    return false;
  }
}

//Compare two objects
export function compareObjects(obj1, obj2) {
  // Get the keys of the first object
  const keys = Object.keys(obj1);

  // Iterate over the keys
  for (let key of keys) {
    // Check if the key exists in both objects and their values are different
    if (
      obj1.hasOwnProperty(key) &&
      obj2.hasOwnProperty(key) &&
      obj1[key] !== obj2[key]
    ) {
      console.log('obj1[key]', obj1[key]);
      return true; // Values have changed
    }
  }
  // If no differences found, return false
  return false;
}

//format large numbers with commas  ***********need to replace this where it is used in reporting dashboard
const formatNumWithCommas = (number) => {
  if (number === 0 || !number) return 0; //default to 0 if 0 or undefined
  if (number) {
    return number.toLocaleString('en-Us');
  }
};

export function millisecondToYear(milliseconds) {
  return Math.floor(milliseconds / 31536000000);
}

export function capitalizeFirstLetter(string) {
  if (!string) return;
  else if (string.length === 1) return string.toUpperCase();
  else return string.charAt(0).toUpperCase() + string.slice(1);
}

export function formatPhoneNumber(phoneNumberString) {
  if (!phoneNumberString) return;
  const cleaned = ('' + phoneNumberString).replace(/\D/g, '');
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return '(' + match[1] + ') ' + match[2] + '-' + match[3];
  }
  return null;
}

export function formatCurrency(number) {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  }).format(number);
}

//get display name from personTypeId for active coverage lines of coverage
export function getPersonTypeFromId(personTypeId){
  switch (personTypeId) {
    case 0:
      return "Member"
    case 1:
      return "Member + Spouse"
    case 2:
      return "Member + Dependent"
    case 3:
      return "Member + Family"
    default:
      return ""
  }
}
