import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

//MuiIcons

//MuiComponents
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Button, Chip, Paper, Typography } from '@mui/material';
import { border, Box } from '@mui/system';
import Fade from '@mui/material/Fade';

//Assets

//Our components
import { thisUserContext } from '../nav-container/NavContainer';
import OurDataGrid from '../DataGrid/OurDataGrid';

export default function PlanManagement(props) {
  let history = useHistory();

  let myContext = useContext(thisUserContext);
  const [thisUser, setThisUser] = useState();
  const [permissions, setPermissions] = useState();

  let getThisUser = async () => {
    setThisUser(myContext.thisUser);
    setPermissions(myContext?.permissionsEnabler);
  };

  useEffect(() => {
    if (myContext?.thisUser && thisUser == null) getThisUser();
  }, [myContext?.thisUser]);

  const [groupPlans, setGroupPlans] = useState([
    {
      groupPlanId: 18,
      name: 'Teamsters Voluntary Income Protection VIP+',
      description:
        "This plan offers financial support for expenses like mortgage, car payments, and other obligations if you're unable to work due to disability or death. Enrollment is quick, your information is secure, and you can cancel anytime.",
      subdomain: 'teamstersvip',
      isEnrollmentOpen: true,
      enrollmentWindows: [
        {
          id: 1,
          openEnrollmentStart: '2024-05-29T04:00:00+00:00',
          openEnrollmentEnd: '2024-07-26T03:59:59+00:00',
          paymentStart: '2024-08-01T00:00:00-07:00',
        },
        {
          id: 2,
          openEnrollmentStart: '2024-05-29T04:00:00+00:00',
          openEnrollmentEnd: '2024-07-26T03:59:59+00:00',
          paymentStart: '2024-08-01T00:00:00-07:00',
        },
      ],
    },
    {
      groupPlanId: 19,
      name: 'Teamsters Voluntary Income Protection VIP+',
      description:
        "This plan offers financial support for expenses like mortgage, car payments, and other obligations if you're unable to work due to disability or death. Enrollment is quick, your information is secure, and you can cancel anytime.",
      subdomain: 'teamstersvip',
      isEnrollmentOpen: false,
      enrollmentWindows: [
        {
          id: 1,
          openEnrollmentStart: '2024-05-29T04:00:00+00:00',
          openEnrollmentEnd: '2024-07-26T03:59:59+00:00',
          paymentStart: '2024-08-01T00:00:00-07:00',
        },
        {
          id: 2,
          openEnrollmentStart: '2024-05-29T04:00:00+00:00',
          openEnrollmentEnd: '2024-07-26T03:59:59+00:00',
          paymentStart: '2024-08-01T00:00:00-07:00',
        },
      ],
    },
  ]);

  const [groupPlanRows, setGroupPlanRows] = useState([]);
  const buildGroupPlanRows = () => {
    let _rows = [];
    groupPlans.forEach((resultObj) => {
      // console.log('resultObj in all members', resultObj)
      let { id, subdomain, title, isEnrollmentOpen, description } = resultObj;
      // let result = resultObj.result

      // console.log(resultObj.result)
      let builtRow = {
        id: resultObj?.groupPlanId,
        title: resultObj?.name,
        subdomain: resultObj?.subdomain,
        isEnrollmentOpen: resultObj?.isEnrollmentOpen,
        description: resultObj?.description,
      };
      console.log(builtRow);
      _rows.push(builtRow);
    });
    setGroupPlanRows(_rows);
  };

  useEffect(() => {
    if (groupPlans?.length > 0) buildGroupPlanRows();
  }, [groupPlans?.length]);

  const groupPlanColumns = [
    { field: 'id', headerName: 'ID', flex: 90, hide: true },
    {
      field: 'title',
      headerName: 'Title',
      flex: 100,
      // valueGetter: (params) => {
      //    console.log(params)
      //    return params.row.enrollmentId
      // },
      renderCell: (params) => {
        // console.log(params)
        return <div style={{ display: 'block' }}>{params.value}</div>;
      },
    },
    {
      field: 'subdomain',
      headerName: 'Subdomain',
      flex: 100,
      headerClassName: 'hide-right-sep',
      renderCell: (params) => {
        return <div>{params.value}</div>;
      },
    },
    {
      field: 'isEnrollmentOpen',
      headerName: 'Is In Open Enrollment',
      flex: 100,
      renderCell: (params) => {
        return (
          <Chip
            sx={{
              backgroundColor: params.value
                ? 'action.selected'
                : 'background.activeWorking',
              color: params.value ? 'text.primary' : 'primary.activeChip',
              fontWeight: '500',
              fontFamily: 'Archivo',
              fontSize: '.9rem',
            }}
            label={
              params.value ? 'Not In Open Enrollment' : 'In Open Enrollment'
            }
          />
        );
      },
    },
  ];

  //THIS OVERRIDES THE NO ROWS DATAGRID COMPONENT
  function NoGroupPlanRows(props) {
    return (
      <div style={{ backgroundColor: '', height: '100%', textAlign: 'center' }}>
        <Box
          sx={{ backgroundColor: 'primary.light' }}
          style={{
            margin: '54px auto 13px',
            width: '58px',
            height: '58px',
            borderRadius: 100,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        ></Box>
        <div style={{ marginBottom: '19px' }}>No Rows!</div>
      </div>
    );
  }
  //THIS OVERRIDES THE NO RESULTS DATAGRID COMPONENT
  function NoGroupPlanResults(props) {
    return (
      <div
        style={{
          backgroundColor: '',
          height: '100%',
          textAlign: 'center',
          zIndex: 5,
        }}
      >
        <Box
          sx={{ backgroundColor: 'primary.light' }}
          style={{
            margin: '54px auto 13px',
            width: '58px',
            height: '58px',
            borderRadius: 100,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          No Results!
        </Box>
      </div>
    );
  }

  //   Drawer Logic
  //NEW MEMBER DRAWER
  const [drawerOpen, setDrawerOpen] = useState(false);

  const handleRowClick = (e) => {
    console.log(e);
    history.push(`/planmanagement/${e.id}`, { id: e.id });
  };

  return (
    <>
      <Box
        sx={{
          bgcolor: 'background.default',
          color: 'text.primary',
          borderColor: 'background.border',
        }}
        style={{ height: 'calc(100vh - 56px)', padding: '20px 40px' }}
      >
        <Typography variant="h4" paddingBottom="42px">
          Plan Management
        </Typography>
        {/* <Typography variant="body1">Create new or update existing group plans.</Typography> */}
        <Box
          className=""
          sx={{ justifyContent: 'space-between', marginBottom: '10px' }}
        >
          <OurDataGrid
            manualHeight
            rows={groupPlanRows}
            columns={groupPlanColumns}
            manualStyle={{
              border: '0px',
              borderRadius: '15px',
              borderTopLeftRadius: '0px',
              borderTopRightRadius: '0px',
              minHeight: '700px',
              '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': {
                py: '14px',
              },
            }}
            noRowsComp={NoGroupPlanRows}
            autoPageSize
            noResultsComp={NoGroupPlanResults}
            handleRowClick={handleRowClick}
          />
        </Box>
      </Box>
    </>
  );
}
