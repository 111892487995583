import React, {
  useEffect,
  useLayoutEffect,
  useState,
  useContext,
  Suspense,
  lazy,
} from 'react';
import { useHistory } from 'react-router';

//Assets
// import PlaceHolderLogo from '../../../assets/images/placeHolder.svg';
// import FullColorBlackText from "../../../assets/images/FullColorBlackText.svg";
// import defaultLogo from "../../../assets/images/FullColorOnlyLogo.png";

//Muicons
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import Close from '@mui/icons-material/Close';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import MenuIcon from '@mui/icons-material/Menu';
import AccountCircle from '@mui/icons-material/AccountCircle';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

//MuiComponents
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import AlerTitle from '@mui/material/AlertTitle';
import Fade from '@mui/material/Fade';
import MobileStepper from '@mui/material/MobileStepper';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Drawer from '@mui/material/Drawer';
import Home from '@mui/icons-material/Home';
import Icon from '@mui/material/Icon';
import ArrowDropDown from '@mui/icons-material/ArrowDropDown';
import Divider from '@mui/material/Divider';
import { Collapse, Link, ListItem, TextField } from '@mui/material';
import PortalPlanSummary from './PortalPlanSummary';
import { Download } from '@mui/icons-material';

//Our services
import enrollmentService from '../../../../../services/enrollment-service';

export default function PortalPlanContainer(props) {
  const [planList, setPlanList] = useState([]);
  const buildOrderedPlanList = () => {
    let results = props.enrollment;
    //Build array of nodes with sort order, then map over to display
    let unorderedList = [
      // {plan: ReactNode, order: int}
    ];
    if (results?.stdPlan && !results.stdPlan?.isIncremental) {
      unorderedList.push({
        plan: (
          <PortalPlanSummary
            groupPlan={results}
            title="Short-Term Disability"
            key={results.stdPlan.planId}
            plan={results.stdPlan}
            planId={results.stdPlan.planId}
            expanded={expanded}
            setExpanded={setExpanded}
          />
        ),
        order: results.stdPlan.sortOrder,
        planId: results.stdPlan.planId,
      });
    }
    if (results?.stdPlan && results.stdPlan?.isIncremental) {
      unorderedList.push({
        plan: (
          <PortalPlanSummary
            groupPlan={results}
            title="Short-Term Disability"
            key={results.stdPlan.planId}
            plan={results.stdPlan}
            planId={results.stdPlan.planId}
            expanded={expanded}
            setExpanded={setExpanded}
          />
        ),
        order: results.stdPlan.sortOrder,
        planId: results.stdPlan.planId,
      });
    }
    if (results?.ltdPlan && !results.ltdPlan?.isIncremental) {
      unorderedList.push({
        plan: (
          <PortalPlanSummary
            groupPlan={results}
            title="Long-Term Disability"
            key={results.ltdPlan.planId}
            plan={results.ltdPlan}
            planId={results.ltdPlan.planId}
            expanded={expanded}
            setExpanded={setExpanded}
          />
        ),
        order: results.ltdPlan.sortOrder,
        planId: results.ltdPlan.planId,
      });
    }
    if (results?.ltdPlan && results.ltdPlan?.isIncremental) {
      unorderedList.push({
        plan: (
          <PortalPlanSummary
            groupPlan={results}
            title="Long-Term Disability"
            key={results.ltdPlan.planId}
            plan={results.ltdPlan}
            planId={results.ltdPlan.planId}
            expanded={expanded}
            setExpanded={setExpanded}
          />
        ),
        order: results.ltdPlan.sortOrder,
        planId: results.ltdPlan.planId,
      });
    }
    if (results?.accidentPlan) {
      unorderedList.push({
        plan: (
          <PortalPlanSummary
            groupPlan={results}
            title="Accident"
            key={results.accidentPlan.planId}
            plan={results.accidentPlan}
            planId={results.accidentPlan.planId}
            expanded={expanded}
            setExpanded={setExpanded}
          />
        ),
        order: results.accidentPlan.sortOrder,
        planId: results.accidentPlan.planId,
      });
    }
    if (results?.lifeADDPlan) {
      unorderedList.push({
        plan: (
          <PortalPlanSummary
            groupPlan={results}
            title="Life and AD&D Insurance"
            key={results.lifeADDPlan.planId}
            plan={results.lifeADDPlan}
            planId={results.lifeADDPlan.planId}
            expanded={expanded}
            setExpanded={setExpanded}
          />
        ),
        order: results.lifeADDPlan.sortOrder,
        planId: results.lifeADDPlan.planId,
      });
    }
    if (results?.cancerPlan) {
      unorderedList.push({
        plan: (
          <PortalPlanSummary
            groupPlan={results}
            title="Cancer"
            key={results.cancerPlan.planId}
            plan={results.cancerPlan}
            planId={results.cancerPlan.planId}
            expanded={expanded}
            setExpanded={setExpanded}
          />
        ),
        order: results.cancerPlan.sortOrder,
        planId: results.cancerPlan.planId,
      });
    }
    if (results?.addPlan) {
      unorderedList.push({
        plan: (
          <PortalPlanSummary
            groupPlan={results}
            title="AD&D"
            key={results.addPlan.planId}
            plan={results.addPlan}
            planId={results.addPlan.planId}
            expanded={expanded}
            setExpanded={setExpanded}
          />
        ),
        order: results.addPlan.sortOrder,
        planId: results.addPlan.planId,
      });
    }

    if (results?.hospitalPlan) {
      unorderedList.push({
        plan: (
          <PortalPlanSummary
            groupPlan={results}
            title="Hospital Indemnity"
            key={results.hospitalPlan.planId}
            plan={results.hospitalPlan}
            planId={results.hospitalPlan.planId}
            expanded={expanded}
            setExpanded={setExpanded}
          />
        ),
        order: results.hospitalPlan.sortOrder,
        planId: results.hospitalPlan.planId,
      });
    }

    let orderedList = unorderedList.sort((a, b) => a.order - b.order);
    setPlanList(orderedList);

    //find index of ladd is in the list of plans
    //  let laddLocation = orderedList.findIndex(
    //    (planObj) => planObj.planId === props?.results?.lifeADDPlan?.planId
    //  );

    //if it is the last plan, set state to true, else false
    //  if (laddLocation === orderedList.length - 1) {
    //    setLaddLastPlan(true);
    //  } else {
    //    setLaddLastPlan(false);
    //  }

    //  let _expanded = [];
    //  let _i = 0;
    //  for (let planObj of orderedList) {
    //    if (_i == 0) {
    //      _expanded.push({ [planObj.planId]: true });
    //    } else {
    //      _expanded.push({ [planObj.planId]: false });
    //    }
    //    _i++;
    //  }
    //  let newPlansDetected = false;
    //  for (let existingPlanId of Object.keys(expanded)) {
    //    let found = false;
    //    for (let newPlanId of Object.keys(_expanded)) {
    //      if (existingPlanId == newPlanId) {
    //        found = true;
    //      }
    //    }
    //    if (!found) {
    //      newPlansDetected = true;
    //    }
    //  }
    //  if (expanded?.length <= 0 || newPlansDetected) {
    //    if (history.location.state && history.location.state?.planId) {
    //      let expandedWithHistory = [];
    //      for (let expandObj of _expanded) {
    //        let planId = Object.keys(expandObj)[0];
    //        expandObj = { [planId]: false };
    //        if (planId == history.location.state.planId) {
    //          expandObj = { [planId]: true };
    //        }
    //        expandedWithHistory.push(expandObj);
    //      }
    //      _expanded = expandedWithHistory;
    //    }
    //    setExpanded(_expanded);
    //  }
  };
  useEffect(() => {
    if (props.enrollment) buildOrderedPlanList();
  }, [props.enrollment]);
  const [expanded, setExpanded] = useState({});

  //this could work with a route for member to allow basic auth,though this would create a new file each time
  // or we could have that new endpoint just not upload to blob, though then there's not a record of what they got
  // or we could add enrollmentId to Attachment or MemberAttachment and pull it directly
  const handleDownloadClick = async () => {
    let sendEmail = false;
    let guid;
    try {
      guid = await enrollmentService.generateEnrollmentReport(
        props.enrollment.enrollmentId,
        sendEmail
      );
    } catch (error) {
      console.log('error generating enrollment report', error);
    }
    console.log('guid', guid);
    if (guid) {
      const link = document.createElement('a');
      link.download = `/api/attachment/${guid}`;
      link.href = `./api/attachment/${guid}`;
      link.click();
      link.remove();
    }
  };

  return (
    <>
      {planList.map((planObj) => planObj.plan)}

      <Box sx={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
        <Button onClick={handleDownloadClick} disabled>
          <Download />
          Download Enrollment Summary
        </Button>
      </Box>
    </>
  );
}
