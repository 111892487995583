import React, {
  useEffect,
  useLayoutEffect,
  useState,
  useContext,
  Suspense,
  lazy,
} from 'react';
// import { useHistory } from "react-router-dom";
import { useHistory } from 'react-router';
// import { format, differenceInCalendarYears } from "date-fns";

//Assets
// import PlaceHolderLogo from '../../../assets/images/placeHolder.svg';
// import FullColorBlackText from "../../../assets/images/FullColorBlackText.svg";
// import defaultLogo from "../../../assets/images/FullColorOnlyLogo.png";

//Muicons
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import Close from '@mui/icons-material/Close';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import MenuIcon from '@mui/icons-material/Menu';
import AccountCircle from '@mui/icons-material/AccountCircle';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

//MuiComponents
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Fade from '@mui/material/Fade';
import MobileStepper from '@mui/material/MobileStepper';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Drawer from '@mui/material/Drawer';
import Home from '@mui/icons-material/Home';
import Icon from '@mui/material/Icon';
import ArrowDropDown from '@mui/icons-material/ArrowDropDown';
import Divider from '@mui/material/Divider';
import { Collapse, Link, ListItem, TextField } from '@mui/material';

//Services
import enrollmentService from '../../../../services/enrollment-service';
// import attachmentService from "../../../services/attachment-service";

//Our Components
// import ExternalEnrollmentHeader from "./ExternalEnrollmentHeader";
import PoweredBy from '../../external-enrollments/PoweredBy';
import InfinityLoader from '../../../UH-loading-animation/InfinityLoader';
import DrawerMenuItem from './DrawerMenuItem';
import HeaderCollapseContainer from './HeaderCollapseContainer';

import { ColorModeContext } from '../../../../ExternalApp';
import PortalPlanContainer from './plans-and-container/PortalPlanContainer';
import PortalPersonalSummary from './PortalPersonalSummary';
import PortalPaymentSummary from './PortalPaymentSummary';

//utils
import { capitalizeFirstLetter, formatPhoneNumber } from '../../../utils';

// const DrawerMenuItem = lazy(() => import('./DrawerMenuItem'));

// const DashHome = React.lazy(() => import('../../external-dashboard/dash-home/DashHome'));
// const DashPersonal = React.lazy(() => import('../../external-dashboard/dash-personal/DashPersonal'));
// // const DashBenefits = React.lazy(() => import('../../external-dashboard/dash-benefits/DashBenefits'));
// const DashWallet = React.lazy(() => import('../../external-dashboard/dash-wallet/DashWallet'));
// const DashDocuments = React.lazy(() => import('../../external-dashboard/dash-documents/DashDocuments'));
// const DashClaims = React.lazy(() => import('../../external-dashboard/dash-claims/DashClaims'));
// const DashSettings = React.lazy(() => import('../../external-dashboard/dash-settings/DashSettings'));
// const DashHelp = React.lazy(() => import('../../external-dashboard/dash-help/DashHelp'));

export default function PortalHome(props) {
  let mockActiveCoverageResult = [
    {
      desc: '60% Monthly Benefits',
      ageBandGroupId: 36,
      startContractPeriod: '2023-04-01T00:00:00-07:00',
      carrierName: 'Sun Life',
      benefitOffsets: 'Benefits are subject to offsets',
      eliminationPeriod: 0,
      benefitDuration: 0,
      policyNumber: '960620',
      isCancelled: false,
      isModified: false,
      groupPlanName: 'UA Local 140',
      id: 6257,
      enrollmentId: 1335,
      memberId: 2174,
      planId: 14,
      planOptionId: 20,
      calculatedPlanTypeId: 2,
      personTypeId: 0,
      benefitAmount: 1934.05,
      premiumAmount: 14.6,
      feeAmount: 3,
      beginDate: '2023-04-01T06:00:00+00:00',
      endDate: '9999-12-31T23:59:59.9999999+00:00',
      lastModifiedDate: '2024-01-25T11:54:47.8988342-07:00',
    },
    {
      desc: 'Life and Death',
      ageBandGroupId: 40,
      startContractPeriod: '2023-04-01T00:00:00-07:00',
      carrierName: 'Sun Life',
      benefitOffsets: null,
      eliminationPeriod: 0,
      benefitDuration: 0,
      policyNumber: '960620',
      isCancelled: false,
      isModified: false,
      groupPlanName: 'UA Local 140',
      id: 6258,
      enrollmentId: 1335,
      memberId: 2174,
      planId: 15,
      planOptionId: 0,
      calculatedPlanTypeId: 4,
      personTypeId: 0,
      benefitAmount: 90000,
      premiumAmount: 100,
      feeAmount: 3,
      beginDate: '2023-04-01T06:00:00+00:00',
      endDate: '9999-12-31T23:59:59.9999999+00:00',
      lastModifiedDate: '2024-01-25T11:54:47.8205129-07:00',
    },
    {
      desc: 'Life and Death Spouse',
      ageBandGroupId: 41,
      startContractPeriod: '2023-04-01T00:00:00-07:00',
      carrierName: 'Sun Life',
      benefitOffsets: null,
      eliminationPeriod: 0,
      benefitDuration: 0,
      policyNumber: '960620',
      isCancelled: false,
      isModified: false,
      groupPlanName: 'UA Local 140',
      id: 6259,
      enrollmentId: 1335,
      memberId: 2174,
      planId: 15,
      planOptionId: 0,
      calculatedPlanTypeId: 5,
      personTypeId: 0,
      benefitAmount: 30000,
      premiumAmount: 12,
      feeAmount: 3,
      beginDate: '2023-04-01T06:00:00+00:00',
      endDate: '9999-12-31T23:59:59.9999999+00:00',
      lastModifiedDate: '2024-01-25T11:54:47.8205129-07:00',
    },
  ];
  let stdHelpText =
    '<ul> <li>Coverage in $50 increments.</li> <li>Guaranteed approved coverage. You cannot be denied during open enrollment.</li>  <li>Loss of DOT certification due to medical reasons is covered.</li> <li>Stackable with other eligible benefits, up to 100% of pre-disability earnings.</li>  <li>Covers off-the-job disabilities for injuries, illnesses, or surgeries.</li>  <li>Benefits paid are tax-free.</li> <li>Pre-existing conditions are covered after 12 months of continuous coverage</li>  </ul>';

  // console.log('props in home', props);

  const [thisMember, setThisMember] = useState(props?.thisMember);
  useEffect(() => {
    setThisMember(props.thisMember);
  }, [props.thisMember]);

  const [enrollments, setEnrollments] = useState(props?.enrollments);
  useEffect(() => {
    setEnrollments(props.enrollments);
  }, [props.enrollments]);

  //potentially we can do one call to get all the data we need for the dashboard, so
  //actively covered benefits | some personal info | payment info | claims and more

  let colorMode = useContext(ColorModeContext);

  const [loading, setLoading] = useState(false);

  const [pageSummaryObj, setPageSummaryObj] = useState({
    thisMember: {},
    thisMemberBenefits: {},
    thisMemberPayment: {},
  });

  const getSummaryData = async () => {
    let results;
    // console.log(await activeCoverageService.getActiveCoveragebyMemberId(1455564));
    // console.log(await activeCoverageService.getActiveCoveragebyMemberId(1455565));
    // console.log(await activeCoverageService.getActiveCoveragebyMemberId(1453158));
    // console.log(await activeCoverageService.getActiveCoveragebyMemberId(2476));
    // console.log(await activeCoverageService.getActiveCoveragebyMemberId(2174));

    // try {
    //    results = await activeCoverageService.getActiveCoveragebyMemberId(1453158);
    // } catch (error) {
    //    console.log('getSummaryData error:', error)
    // }
    results = mockActiveCoverageResult;
    console.log('getSummaryData results:', results);
  };
  // useEffect(() => {
  //    setLoading(true);
  //    getSummaryData();
  //    setTimeout(() => {
  //       setLoading(false);
  //    }, 2000);
  //    // return () => {
  //    //    setLoading(false);
  //    // }
  // }, []);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  // useEffect(() => {
  //    setLoading(true);
  //    setTimeout(() => {
  //       setLoading(false);
  //    }, 2000);
  //    // return () => {
  //    //    setLoading(false);
  //    // }
  // }, [anchorEl]);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const goTo = (page) => {
    handleClose();
  };

  const swapTheme = () => {
    colorMode.ToggleColorMode();
  };

  // const [collapse, setCollapse] = useState(false);
  const [mostRecentEnrollment, setMostRecentEnrollment] = useState(null);
  const [mostRecentEnrollmentCommLabels, setMostRecentEnrollmentCommLabels] =
    useState([]);

  const getEnrollmentCommunicationLabels = async (enrollmentId) => {
    // let _labels = await enrollmentService.getEnrollmentCommunicationLabels(enrollmentId);
    let _labels = await enrollmentService.getCommunicationLabelsByMemberId(
      thisMember.id
    );
    if (_labels) {
      setMostRecentEnrollmentCommLabels(_labels);
    }
  };
  useEffect(() => {
    if (enrollments?.length > 0) {
      for (let i = 0; i < enrollments.length; i++) {
        if (enrollments[i].isEnrolled && enrollments[i].isEnrollmentOpen) {
          setMostRecentEnrollment(enrollments[i]);
          setEnrollmentAlert(true);
          break;
        }
      }
    }
  }, [thisMember, enrollments]);

  useEffect(() => {
    if (thisMember?.id > 0) {
      getEnrollmentCommunicationLabels(thisMember.id);
    }
  }, [thisMember?.id]);

  const [enrollmentAlert, setEnrollmentAlert] = useState(false);
  const onEnrollmentAlertClose = () => {
    setEnrollmentAlert(false);
  };

  const [expanded, setExpanded] = useState(false);
  const handleExpandSection = () => {
    setExpanded(!expanded);
  };

  let expandIcon = expanded ? (
    <RemoveIcon
      fontSize="large"
      sx={{
        //  position: "absolute",
        //  right: "14%",
        //  top: "20%",
        color: 'white',
        backgroundColor: '#195ffb',
        borderRadius: '100px',
        boxShadow: '-1px 1px 4px 0px #00000040',
      }}
    />
  ) : (
    <AddIcon
      fontSize="large"
      sx={{
        //  position: "absolute",
        //  right: "14%",
        //  top: "20%",
        color: '#195ffb',
        borderRadius: '100px',
        boxShadow: '-1px 1px 4px 0px #00000040',
      }}
    />
  );

  // console.log('mostRecentEnrollment:', mostRecentEnrollmentCommLabels);

  return (
    <>
      {loading && (
        <>
          <InfinityLoader
            style={{
              position: 'absolute',
              zIndex: 5,
              width: '100%',
              height: '100vh',
              backgroundColor: '#00000050',
              backdropFilter: 'blur(10px)',
            }}
          />
          <style>
            {`
                     div.lottie-div > svg {
                        height:55px!important;
                        position:absolute;
                        top:50%;
                     }
                  `}
          </style>
        </>
      )}
      <Box
        // component={'main'}
        className="flex-container"
        flex={1}
        overflow="auto"
        style={{
          flexWrap: 'wrap',
          minHeight: '700px',
          margin: 'auto',
        }}
        sx={{
          bgcolor: 'background.default',
          color: 'text.primary',
          paddingTop: '66px',
          paddingBottom: '66px',
        }}
      >
        <div
          // className="row"
          // component={'main'}
          style={{
            maxWidth: '575px',
            margin: '0 auto',
            // maxHeight: "1000px",
            // height:'10000px',
            // padding: "18px",
          }}
        >
          <div style={{ padding: '18px' }}>
            <Typography variant="subtitle1" sx={{ fontWeight: 600 }}>
              welcome
              {thisMember && ', '}
              <Fade in={thisMember?.firstName ? true : false}>
                <Typography
                  variant="subtitle1"
                  component={'span'}
                  sx={{ color: 'primary.main', fontWeight: 600 }}
                >
                  {capitalizeFirstLetter(thisMember?.firstName)}{' '}
                  {capitalizeFirstLetter(thisMember?.lastName)}!
                </Typography>
              </Fade>
            </Typography>
          </div>
          {enrollmentAlert && (
            <div style={{ padding: '18px' }}>
              <Alert
                severity="success"
                variant="outlined"
                sx={{
                  borderRadius: '8px',
                  padding: '6px 16px',
                  display: 'flex',
                  alignItems: 'flex-start',
                  justifyContent: 'space-between',
                  color: 'var(--Light-Success-Shades-160p, #2E7D32)',
                  borderColor: 'var(--Light-Success-Shades-160p, #2E7D32)',
                }}
                icon={
                  <CheckCircleOutlineIcon
                    sx={{ color: 'var(--Light-Success-Shades-160p, #2E7D32)' }}
                  />
                }
                action={
                  <IconButton
                    aria-label="close"
                    // color="inherit"
                    sx={{ color: '#123214' }}
                    size="small"
                    onClick={onEnrollmentAlertClose}
                  >
                    <Close fontSize="inherit" />
                  </IconButton>
                }
                // open={false}
              >
                <AlertTitle>
                  <Typography
                    variant="body1"
                    sx={{
                      fontWeight: 500,
                      fontSize: '16px',
                      letterSpacing: '.15px',
                      color: '#123214',
                      marginBottom: '4px',
                    }}
                  >
                    Enrollment Successfully Submitted
                  </Typography>
                </AlertTitle>

                <Typography
                  variant="body1"
                  sx={{
                    fontWeight: 400,
                    fontSize: '14px',
                    letterSpacing: '.15px',
                    color: '#123214',
                    marginBottom: '4px',
                  }}
                >
                  {mostRecentEnrollment?.name}
                  <br />
                  If you need to make any changes please call
                </Typography>
                <Link
                  href={`tel:${
                    mostRecentEnrollmentCommLabels[0]?.enrollPhone || '5555555'
                  }`}
                  underline="always"
                  sx={{
                    color: 'primary.main',
                    fontWeight: 600,
                    marginLeft: '8px',
                  }}
                >
                  {formatPhoneNumber(
                    mostRecentEnrollmentCommLabels[0]?.enrollPhone
                  )}
                </Link>
              </Alert>
            </div>
          )}

          <div
            className="col-12"
            style={{
              display: 'flex',
              justifyContent: 'center',
              flex: 1,
              flexWrap: 'wrap',
            }}
          >
            <HeaderCollapseContainer
              title="My Benefits"
              childBoxSx={{ gap: '0px' }}
            >
              <PortalPlanContainer enrollment={mostRecentEnrollment} />
            </HeaderCollapseContainer>

            <HeaderCollapseContainer title="My Information">
              <PortalPersonalSummary
                thisMember={thisMember}
                thisMemberPhone={props.thisMemberPhone}
                getThisMember={props.getThisMember}
              />
            </HeaderCollapseContainer>

            <HeaderCollapseContainer
              title="My Payment"
              childBoxSx={{ gap: '0px' }}
            >
              <PortalPaymentSummary
                payment={props.payment}
                enrollment={mostRecentEnrollment}
              />
            </HeaderCollapseContainer>
          </div>
        </div>
      </Box>

      {/* {!isAuthenticated && history.push('/login')} */}
    </>
  );
}
