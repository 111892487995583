import React, { useEffect, useState, useContext } from "react"
import { format, differenceInCalendarYears, set } from "date-fns"
import { useHistory } from "react-router-dom"

//Assets
// import teamstersLogo from '../../../../assets/images/TeamstersLogo.png'
// import bankLogo from '../../../../assets/images/bankOfAmerica.png'

//Services
import memberService from "../../../../services/member-service"
import enrollmentService from "../../../../services/enrollment-service"
import walletService from "../../../../services/wallet-service"
import optionService from "../../../../services/optionService"
import organizationService from "../../../../services/organization-service"
import paymentsService from "../../../../services/payments-service"
import activeCoverageService from "../../../../services/activeCoverage-service"
import timelineService from "../../../../services/timeline-service"

//Mui icons
import CircleIcon from "@mui/icons-material/Circle"
import ReceiptIcon from "@mui/icons-material/Receipt"
import ContentCopyIcon from "@mui/icons-material/ContentCopy"
import LocalActivityIcon from "@mui/icons-material/LocalActivity"
import ContentPasteIcon from "@mui/icons-material/ContentPaste"
import AttachMoneyIcon from "@mui/icons-material/AttachMoney"
import SettingsIcon from "@mui/icons-material/Settings"
import FolderOpenIcon from "@mui/icons-material/FolderOpen"

//Mui Components
import Snackbar from "@mui/material/Snackbar"
import MuiAlert from "@mui/material/Alert"
import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"

//Our Components
import MemberInfo from "./member-info/MemberInfo"
import MemberPlansTab from "./MemberTabs/member-plans-tab/MemberPlansTab"
import MemberTicketsTab from "./MemberTabs/member-tickets-tab/MemberTicketsTab"
import MemberEnrollTab from "./MemberTabs/agent-enroll-tab/MemberEnrollTab"
// import AgentEnrollmentWrapper from "./MemberTabs/agent-enroll-tab/AgentEnrollmentWrapper";
import MemberNotesTab from "./MemberTabs/member-notes-tab/MemberNotesTab"
import MemberSettingsTab from "./MemberTabs/member-settings-tab/MemberSettingsTab"
import MemberPaymentsTab from "./MemberTabs/member-payments-tab/MemberPaymentsTab"
import MemberDocumentsTab from "./MemberTabs/member-documents-tab/MemberDocumentsTab"
import MemberHistoryTab from "./MemberTabs/member-history-tab/MemberHistoryTab"
import { thisUserContext } from "../../nav-container/NavContainer"

const componentStyles = {
  memberProfileContainer: {
    display: "flex",
    flexDirection: "row",
    bgcolor: "background.default",
    color: "text.primary",
    width: "100%",
    // flexWrap: "wrap",
  },
  tabAndBodyContainer: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    height: "calc(100vh - 56px)",
  },
  tabRowContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    maxWidth: "100%",
    height: "48px",
    paddingLeft: "10px",
    borderBottom: "solid 1px",
    borderColor: "background.border",
  },
  tabContainer: (activePage) => ({
    color: activePage ? "primary.main" : "text.gray",
    fontWeight: 500,
    height: "100%",
    display: "flex",
    alignItems: "center",
    margin: "0px",
    cursor: "pointer",
    padding: "4px 0px",
  }),
  tabOutline: (activePage) => ({
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "100px",
    padding: "8px 16px",
    height: "28px",
    backgroundColor: activePage ? "#195FFB26" : "",
  }),
  tabText: {
    fontSize: "13px",
    fontWeight: 500,
    fontFamily: "Archivo",
    paddingLeft: "7px",
  },
  icon: {
    height: "20px",
  },
}

export default function MemberProfile(props) {

  
  const history = useHistory();
  let UsdFormatterDec = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 2,
  })
  let percentFormatter = new Intl.NumberFormat("en-US", { style: "percent" })

  const formatDate = (rawDate) => {
    let [yyyy, mm, dd, hh, m, s] = rawDate.split(/[^\d]+/)
    let date = new Date()
    date.setUTCFullYear(+yyyy)
    date.setUTCDate(+dd)
    date.setUTCMonth(mm - 1)
    date.setUTCHours(+hh)
    date.setUTCMinutes(+m + date.getTimezoneOffset())
    date.setUTCSeconds(+s)
    return date
  }

  let _thisUserContext = useContext(thisUserContext)

  const [permissionsEnabler, setPermissionsEnabler] = useState()
  useEffect(() => {
    if (_thisUserContext)
      setPermissionsEnabler(_thisUserContext.permissionsEnabler);
      // console.log('MY PERMISSIONS', permissionsEnabler)
  }, [_thisUserContext]);

  // Page Navigation
  const [activePage, setActivePage] = useState(0)

  const handleActivePageChange = (event) => {
    let newVal = Number(event.target.getAttribute("value"))
    if (activePage == newVal) {
      setSelectedPlan()
    }
    setActivePage(newVal)
  }

  //Member
  const [thisMember, setThisMember] = useState()
  let getThisMember = async (memberId) => {
    console.log("getting member in member profile", props.match?.params?.id)
    let _member
    try {
      _member = await memberService.getMemberByIdAsync(props.match?.params?.id)
    } catch (error) {
      console.error("error getting member in member profile", error)
    }

    console.log("member in profile", _member)
    setThisMember(_member)
  }

  useEffect(() => {
    if (props.match?.params?.id) {
      let memberId = Number(props.match?.params?.id)
      if (memberId > 0) {
        getThisMember(memberId)
        // getDependents()
        getPhones()
        getActiveCoverage(memberId)
      }
    }
  }, [props.match?.params?.id])

  //PHONE LOGIC
  const [phones, setPhones] = useState([])
  // const [primaryPhone, setPrimaryPhone] = useState();
  const [primaryPhone, setPrimaryPhone] = useState({})
  const [secondaryPhone, setSecondaryPhone] = useState({})

  const getPhones = async () => {
    let memberId = Number(props.match?.params?.id)
    let _phones
    try {
      _phones = await memberService.getPhonesForMember(memberId)
    } catch (error) {
      console.error("error getting phones in agent wrapper", error)
    }
    setPhones(_phones)

    let preferredNumber = _phones?.find((phone) => {
      if (phone.isPreferred) {
        return phone
      }
    })

    let secondPhoneNumber = _phones?.find((phone) => {
      if (phone.isPreferred === false) {
        return phone
      }
    })
    setPrimaryPhone(preferredNumber ? preferredNumber : {})
    setSecondaryPhone(secondPhoneNumber ? secondPhoneNumber : {})
  }

  const handlePhoneInsert = async () => {
    //if primary or secondary numbers already exist, update these numbers w/ phone id
    if (primaryPhone && primaryPhone.id) {
      // console.log("PRIMARY PHONE FROM MEMBER PROFILE updated", primaryPhone);
      try {
        await memberService.updatePhoneForMember(primaryPhone)
      } catch (error) {
        console.error("error updating primary/mobile phone", error)
      }
    }
    if (secondaryPhone && secondaryPhone.id) {
      // console.log(
      //   "Secondary PHONE FROM MEMBER PROFILE updated",
      //   secondaryPhone
      // );
      try {
        await memberService.updatePhoneForMember(secondaryPhone)
      } catch (error) {
        console.error("error updating secondary/home phone", error)
      }
    }
    //  //if primary or secondary numbers don't exist, but user has added new data, create these numbers w/ NO phone id
    if (primaryPhone.number && !primaryPhone.id) {
      // console.log("PRIMARY PHONE FROM MEMBER PROFILE added", primaryPhone);
      try {
        await memberService.insertPhoneForMember(primaryPhone)
      } catch (error) {
        console.error("error inserting a new primary/mobile phone", error)
      }
    }
    if (secondaryPhone.number && !secondaryPhone.id) {
      // console.log("Secondary PHONE FROM MEMBER PROFILE added", secondaryPhone);
      try {
        await memberService.insertPhoneForMember(secondaryPhone)
      } catch (error) {
        console.error("error inserting a new secondary/home phone", error)
      }
    }
    getPhones()
  }

  //SPOUSE
  const [spouseInDb, setSpouseInDb] = useState({})
  const [spouse, setSpouse] = useState({
    firstName: "",
    lastName: "",
    dateOfBirth: "",
    email: "",
    informationAuthorize: false,
    coverageAuthorize: null,
    spouseInDb: false, //Dont send this property back
  })
  const getSpouse = async (e) => {
    let _spouse
    try {
      _spouse = await memberService.getSpouseForMember(thisMember.id);
      if (_spouse){
        _spouse.spouseInDb = true;
        if (_spouse.dateOfBirth) {
          _spouse.dateOfBirth = format(
            formatDate(_spouse?.dateOfBirth),
            "yyyy-MM-dd"
          );
        }
        
      } else {
        _spouse = {
          firstName: "",
          lastName: "",
          dateOfBirth: "",
          email: "",
          informationAuthorize: false,
          coverageAuthorize: null,
          spouseInDb: false,
        }
      }
      setSpouse(_spouse)
      setSpouseInDb({ ..._spouse })
    } catch (error) {
      _spouse = {
        firstName: "",
        lastName: "",
        dateOfBirth: "",
        email: "",
        informationAuthorize: false,
        coverageAuthorize: null,
        spouseInDb: false,
      }
      setSpouse(_spouse)
    }
  }

  let saveSpouseInfo = async () => {
    console.log("saving spouse")
    console.log("spouseObj", spouse, "spouseInDbObj", spouseInDb)
    //spouseInDb must exist, compare the new spouse info to spouseInDb to make sure its actually new info
    if (
      spouse.spouseInDb &&
      JSON.stringify(spouse) != JSON.stringify(spouseInDb)
    ) {
      let updateSpouseObj = { ...spouse }
      //remove the previous spouseInDb info
      delete updateSpouseObj.spouseInDb
      console.log("updating spouse", updateSpouseObj)
      try {
        await memberService.updateSpouseForMember(
          thisMember.id,
          updateSpouseObj
        )
      } catch (error) {
        console.log("error updating spouse", error)
      }
    } else if (!spouse.spouseInDb && spouse.firstName != "") {
      console.log("adding spouse")
      let addSpouseObj = { ...spouse }
      delete addSpouseObj.spouseInDb
      try {
        await memberService.addSpouseForMember(thisMember.id, addSpouseObj)
      } catch (error) {
        console.log("error adding spouse", error)
      }
    }
    getSpouse()
  }

  let updateMember = async (updatedInfo) => {
    console.log("updated info on save", updatedInfo)

    let overwritten = Object.assign({}, thisMember, updatedInfo)

    //update member info besides organization
    try {
      await memberService.updateForMember(thisMember.id, overwritten)
    } catch (error) {
      console.error("error updating member", error)
    }

    //update organization
      //check if a organizationId on updatedInfo is different from original thisMember obj
    if(updatedInfo?.organizationId !== thisMember?.organizationId){
        let updatedMemberOrg = {memberId: thisMember?.id, organizationId: updatedInfo?.organizationId, isPrimary: true}
  
        try {
          await memberService.updateMemberOrganization(updatedMemberOrg)
          
        } catch (error) {
          console.error('error updating member organization', error)
        }
    }

    //refresh member
    getThisMember()
    //refresh history
    setTimeout(() => {
      refreshHistory()
    }, 500)
  }

  //Dependent logic
  const [dependentsInDB, setDependentsInDB] = useState([])
  const [dependents, setDependents] = useState([])
  const getDependents = async () => {
    let _dependents
    try {
      _dependents = await memberService.getDependentsForMember(thisMember.id)
    } catch (error) {
      console.log("error getting deps", error)
    }
    let _dependentsInDB = []

    if (_dependents?.length > 0) {
      _dependents.forEach((_dependent) => {
        if (_dependent.dob) {
          _dependent.dob = format(formatDate(_dependent.dob), "yyyy-MM-dd")
          // _dependent.dob = `${_dependent.dob}T00:00:00`;
          // console.log('depDOBWRAPPER',_dependent.dob)
        }
        _dependentsInDB.push({ ..._dependent })
      })
    }
    // console.log(_dependents)
    setDependentsInDB(_dependentsInDB)
    setDependents(_dependents)
  }
  let saveDependents = async () => {
    // console.log("saving deps, compare db state with edited state");
    for (let editDependent of dependents) {
      let dependentInDB = dependentsInDB.find(
        (DBdependent) => DBdependent.id == editDependent.id
      )
      console.log("edit", editDependent)
      console.log("db", dependentInDB)
      //check for delete dep
      if (dependentInDB && editDependent.isDeleted != dependentInDB.isDeleted) {
        console.log("deleted on the front")
        try {
          await memberService.deleteDependentForMember(
            thisMember.id,
            editDependent.id
          )
        } catch (error) {
          console.log("error deleting dep", error)
        }
      }
      //check for updated dep
      else if (
        dependentInDB &&
        JSON.stringify(editDependent) != JSON.stringify(dependentInDB)
      ) {
        console.log("updated On the front")
        try {
          await memberService.updateDependentForMember(
            thisMember.id,
            editDependent
          )
        } catch (error) {
          console.log("error uopdating dep", error)
        }
      }

      //check for new dep
      else if (!dependentInDB) {
        console.log("added dep on front")
        try {
          await memberService.addDependentForMember(
            thisMember.id,
            editDependent
          )
        } catch (error) {
          console.log("error adding dep", error)
        }
      }
    }
  }

  let addDependent = async (dependent) => {
    await memberService.addDependentForMember(thisMember.id, dependent)
    getDependents()
  }

  let deleteDependent = async (dependentId) => {
    await memberService.deleteDependentForMember(thisMember.id, dependentId)
    getDependents()
  }

  // getting plan costs from the calc premium stuffs
  const [PlanCosts, setPlanCosts] = useState([])
  const [totalPlanCosts, setTotalPlanCosts] = useState()

  let getPlanCosts = async (enrollmentId) => {
    // let _planCosts = await memberService.getMemberCosts(params.id, selectedEnrollment.enrollmentId)
    let _planCosts = await memberService.getMemberCosts(
      props.match?.params?.id,
      enrollmentId
    )

    if (enrollmentId != 0) {
      let _plan
      try {
        _plan = await enrollmentService.getEnrollmentById(enrollmentId)
        
      } catch (error) {
        console.error("error getting enrollment", error)
      }
      // console.log(_plan);
      setSelectedPlan(_plan)
    } else {
      setSelectedPlan(selectedPlan)
    }

    setPlanCosts(_planCosts)
  }

  //get costs for ALL group plans combined for a single member
  const getTotalPlanCosts = async () => {
    let _totalCosts

    for (let availablePlan of availablePlans) {
      let _planCosts
      try {
        _planCosts = await memberService.getMemberCosts(
          props.match?.params?.id,
          availablePlan.enrollmentId
        )
      } catch (error) {
        console.log("error getting total plan costs", error)
      }
      if (!_totalCosts) _totalCosts = _planCosts
      else {
        //if more than one plan, add the costs together for each match ing line of coverage
        for (let [key, value] of Object.entries(_planCosts)) {
          if (_totalCosts[key]) {
            let _value = Number(value)
            _totalCosts[key] += _value
          } else _totalCosts[key] = Number(value)
        }
      }
    }
    console.log("total costs", _totalCosts)
    setTotalPlanCosts(_totalCosts)
  }

  //Language types
  const [languageTypes, setLanguageTypes] = useState([]);
  const getLanguageTypes = async () => {
    try {
      let _languageTypes = await optionService.getLanguageTypes();
      setLanguageTypes(_languageTypes);
    } catch (error) {
      console.error("error getting language types", error)
    }
  }


  
  //Member Enrollments
  const [availableEnrollments, setAvailableEnrollments] = useState([])
  const [availablePlans, setAvailablePlans] = useState([])
  const [enrollments, setEnrollments] = useState([])

  let getEnrollments = async () => {
    let _enrollments = await enrollmentService.getEnrollmentsByMemberId(
      thisMember.id
    )
    setEnrollments(_enrollments)
    let selectedInArray

    for (let enrollment of _enrollments) {
      if (
        selectedEnrollment?.enrollmentId == 0 &&
        enrollment?.groupPlanId == selectedEnrollment?.groupPlanId &&
        enrollment?.name == selectedEnrollment?.name
      )
        selectedInArray = enrollment
      else if (enrollment.enrollmentId == selectedEnrollment?.enrollmentId)
        selectedInArray = enrollment
    }
    if (selectedInArray) {
      setSelectedEnrollment(selectedInArray)
    }
  }

  useEffect(() => {
    if (thisMember?.id > 0) {
      getEnrollments()
      getPaymentSchedule()
      getSpouse()
      getDependents()
    }
  }, [thisMember?.id])

  //get bank accounts - refresh if member OR permissions change
  useEffect(() => {
    getBankAccount()
  }, [thisMember?.id, permissionsEnabler?.viewFullBankAccounts])

  //OPTION CALLS
  useEffect(() => {
    getBankAccountTypes();
    getPaymentFrequencies();
    getPaymentTransactionTypes();
    getPaymentStatuses();
    getBankAccountPrenoteStatuses();
    getEnrollmentStates();
    getRelationshipTypes();
    getCancellationReasons();
    getLanguageTypes();
  }, []);

  //Selected Plan
  const [selectedPlan, setSelectedPlan] = useState()

  //Split our enrollments between closed viewable plans, and open enrollments
  useEffect(() => {
    if (enrollments?.length > 0) {
      let _enrollments = [...enrollments]
      let _availablePlans = []
      let _availableEnrollments = []

      for (let enrollmentObj of _enrollments) {
        //if they're enrolled and enrollment is closed, push to availablePlans
        if (enrollmentObj.isEnrolled && !enrollmentObj.isEnrollmentOpen) {
          _availablePlans.push(enrollmentObj)
        }
        //open enrollment, push to availableEnrollments
        else if (enrollmentObj.isEnrollmentOpen) {
          _availableEnrollments.push(enrollmentObj)
        }
      }

      setAvailablePlans(_availablePlans)
      setAvailableEnrollments(_availableEnrollments)

      //DEBUG
      // setAvailablePlans(_availableEnrollments);
    } else {
      setAvailablePlans([])
      setAvailableEnrollments([])
    }
  }, [enrollments, selectedPlan?.enrollmentStateId])

  useEffect(() => {
    // only grab total cost of all plans, enrollment and plan costs if we have data here
    if (availablePlans.length > 0) {
      if (availablePlans.length == 1) {
        // getTotalPlanCosts()
        getPlanCosts(availablePlans[0].enrollmentId)
      }
      getTotalPlanCosts()
    }
  }, [availablePlans])

  //Selected Enrollment
  const [selectedEnrollment, setSelectedEnrollment] = useState()

  const [newEnrollment, setNewEnrollment] = useState(false)

  let getOneEnrollment = async (enrollment) => {
    // console.log("getting single from this enrollment: ", availableEnrollments);
    if (enrollment.enrollmentId != 0) {
      let _enrollment
      try {
        _enrollment = await enrollmentService.getEnrollmentById(
          enrollment.enrollmentId
        )
      } catch (error) {
        console.error("error getting enrollment in getOneEnrollment", error)
      }
      console.log("getting enrollment", _enrollment)
      setSelectedEnrollment(_enrollment)
    } else {
      setNewEnrollment(true)
      setSelectedEnrollment(enrollment)
    }
  }

  //ACTIVE COVERAGE LOGIC

  const [activeCoverage, setActiveCoverage] = useState([])

  //this get's all current and past active coverages including modified/cancelled past coverages
  //organized as individual object per line of coverage
  //does not get future coverages
  const getActiveCoverage = async (memberId) => {
    let _activeCoverage
    try {
      _activeCoverage = await activeCoverageService.getActiveCoveragebyMemberId(
        memberId
      )
    } catch (error) {
      console.error("error getting active coverage", error)
    }
    if (_activeCoverage && _activeCoverage?.length > 0) {
      console.log("ACTIVE COVERAGE", _activeCoverage)
      setActiveCoverage(_activeCoverage)
    }
  }

  //separate active coverage into current, past, and future coverages
  const [currentActiveCoverage, setCurrentActiveCoverage] = useState([])
  const [pastActiveCoverage, setPastActiveCoverage] = useState([])
  const [futureActiveCoverage, setFutureActiveCoverage] = useState([])

  //separate active coverages by group plan name and enrollmentId AND date (past, current, future)
  useEffect(() => {
    if (activeCoverage?.length > 0) {
      let currentDate = new Date()
      let currentDateYyyyDdMm = currentDate.toISOString()?.slice(0, 10)
      let _currentActiveCoverage = []
      let _pastActiveCoverage = []
      let _futureActiveCoverage = []
      let _enrollments = [...enrollments]
        let _activeEnrollmentId = 0; 

        _enrollments?.forEach((enrollment) => {
            if (enrollment.isEnrollmentOpen) {
                _activeEnrollmentId = enrollment.id;
            }
        });
      
      //separate based on begin and end dates
      activeCoverage?.forEach((coverage) => {
        console.log("end date", coverage.endDate)
        if (
          coverage.endDate >= currentDateYyyyDdMm &&
            (_activeEnrollmentId !== 0 || coverage.beginDate < currentDateYyyyDdMm)
        ) {           
          _currentActiveCoverage.push(coverage)
        } else if (coverage.endDate < currentDateYyyyDdMm) {
          _pastActiveCoverage.push(coverage)
        } else {
          _futureActiveCoverage.push(coverage)
        }
      })

      //organize current lines of coverage into objects based on enrollment ID. ex: [{enrollmentId: [coverage1, coverage2]}]
      let _currentActiveCoverageByEnrollmentId = []
      if (_currentActiveCoverage?.length > 0) {
        _currentActiveCoverageByEnrollmentId = Object.values(
          _currentActiveCoverage.reduce((acc, coverage) => {
            if (!acc[coverage.enrollmentId]) {
              acc[coverage.enrollmentId] = []
            }
            acc[coverage.enrollmentId].push(coverage)
            return acc
          }, {})
        ).map((coverage) => {
          const enrollmentId = coverage[0].enrollmentId
          const groupPlanName = coverage[0].groupPlanName
          return {
            enrollmentId: enrollmentId,
            groupPlanName: groupPlanName,
            linesOfCoverage: coverage,
          }
        })
      }

      setCurrentActiveCoverage(_currentActiveCoverageByEnrollmentId)
      setPastActiveCoverage(_pastActiveCoverage)
      setFutureActiveCoverage(_futureActiveCoverage)
    }
  }, [activeCoverage])

  // console.log('current active coverage', currentActiveCoverage)
  // console.log('past active coverage', pastActiveCoverage)

  //useEffect(() => {
  //  // only grab enrollment and plan costs if we have data here
  //  if (availableEnrollments.length > 0) {
  //    //only one, so no choice necessary
  //    if (availableEnrollments.length == 1) {
  //      // setSelectedEnrollment(availableEnrollments[0])
  //      // getOneEnrollment(availableEnrollments[0]);
  //    }
  //    // getPlanCosts()
  //  }
  //}, [availableEnrollments?.length]);

  //NOT using this anymore with new active coverage data - need to remove from all Enrollment displays
  let updateSinglePlan = async (plan, updatedInfo, reasonId = 0) => {
    // let overwritten = {
    //   ...selectedEnrollment,
    //   [plan]: updatedInfo,
    // };
    console.log(
      "Save Option for member",
      thisMember.id,
      "enrollment",
      selectedPlan.enrollmentId,
      "option",
      updatedInfo.selectedOptionId,
      "reason",
      reasonId
    )
    await enrollmentService.editOptionForMember(
      thisMember.id,
      selectedPlan.groupPlanId,
      updatedInfo.planId,
      updatedInfo.selectedOptionId,
      selectedPlan.enrollmentId,
      reasonId
    )
    await getPlanCosts(selectedPlan.enrollmentId)
    await getTotalPlanCosts()
    //refresh payment schedules
    getPaymentSchedule()
  }

  //get bank accounts - full vs. last 4 depending on permissions
  const [bankAccount, setBankAccount] = useState([])
  let getBankAccount = async () => {
    let _bankAccount = []
    if (permissionsEnabler?.viewFullBankAccounts) {
      try {
        _bankAccount = await walletService.getFullBankAccountForMember(
          props.match?.params?.id
        )
      } catch (error) {
        console.error("Error getting full bank account", error)
      }
    } else {
      try {
        _bankAccount = await walletService.getBankAccountsForMember(
          props.match?.params?.id
        )
      } catch (error) {
        console.error("error getting bank account for member", error)
      }
    }
    setBankAccount(_bankAccount)
  }

  //get list of Bank account Types
  const [bankAccountTypes, setBankAccountTypes] = useState([])

  let getBankAccountTypes = async () => {
    let accountTypes = await optionService.getBankAccountTypes()
    // console.log(accountTypes);
    setBankAccountTypes(accountTypes)
  }

  //get wallet stuffs -> payment schedule
  const [PaymentSchedule, setPaymentSchedule] = useState([]);

  //need to add the GET ALL payment schedules here in member profile - not enrollment specific for display
  let getPaymentSchedule = async () => {
    let _paymentSchedule = [];
    try {
      _paymentSchedule = await walletService.getAllPaymentSchedulesForMember(
        props.match?.params?.id
      )
    } catch (error) {
      console.error('error getting payment schedule', error)
    }
    //filter out only schedules on PeakPay
    if(_paymentSchedule?.length > 0){
      let _schedulesOnPeakPay = _paymentSchedule?.filter(schedule => schedule?.isPeakPay)
      setPaymentSchedule(_schedulesOnPeakPay);
    }else{
      setPaymentSchedule(_paymentSchedule);
    }
  };

  const [PaymentFrequencies, setPaymentFrequencies] = useState([])
  let getPaymentFrequencies = async () => {
    let _paymentFrequencies = await optionService.getPaymentFrequencies()
    // console.log(_paymentFrequencies)
    setPaymentFrequencies(_paymentFrequencies)
  }

  const [paymentTransactionTypes, setPaymentTransactionTypes] = useState([])
  let getPaymentTransactionTypes = async () => {
    let _paymentTransactionTypes =
      await optionService.getPaymentTransactionTypes()
    setPaymentTransactionTypes(_paymentTransactionTypes)
  }

  const [paymentStatuses, setPaymentStatuses] = useState([])
  let getPaymentStatuses = async () => {
    let _paymentStatuses = await optionService.getPaymentStatuses()
    setPaymentStatuses(_paymentStatuses)
  }

  const [bankAccountPrenoteStatuses, setBankAccountPrenoteStatuses] = useState(
    []
  )
  let getBankAccountPrenoteStatuses = async () => {
    let _bankAccountPrenoteStatuses =
      await optionService.getBankAccountPrenoteStatuses()
    setBankAccountPrenoteStatuses(_bankAccountPrenoteStatuses)
  }

  //value to determine if an agent is attempting to edit the wallet from the plans tab vs payments tab
  //restricts some editing options based on value
  const [editWalletFromPlans, setEditWalletFromPlans] = useState(false)

  const [enrollmentStates, setEnrollmentStates] = useState([])
  const getEnrollmentStates = async () => {
    let _enrollmentStates = await optionService.getEnrollmentStates()
    setEnrollmentStates(_enrollmentStates)
  }

  const [relationshipTypes, setRelationshipTypes] = useState([])
  const getRelationshipTypes = async () => {
    let _relationshipTypes = await optionService.getRelationshipTypes()
    setRelationshipTypes(_relationshipTypes)
  }

  const [cancellationReasons, setCancellationReasons] = useState([])
  const getCancellationReasons = async () => {
    let _cancellationReasons = await optionService.getCancellationReasons()
    setCancellationReasons(_cancellationReasons)
  }

  // Error / Success Messages
  const [message, setMessage] = useState({})

  useEffect(() => {
    const timeId = setTimeout(() => {
      // After 3 seconds set the show value to false
      setMessage({
        ...message,
        messageDisplay: false,
        autoHideDuration: 1,
        severity: "info",
        backgroundColor: "",
        message: "",
      })
    }, 5000)

    return () => {
      clearTimeout(timeId)
    }
  }, [message.messageDisplay])

  const handleMessageClose = (event, reason) => {
    if (reason === "clickaway") {
      return
    }
    setMessage({
      ...message,
      messageDisplay: false,
      autoHideDuration: "",
      severity: "",
      backgroundColor: "",
      message: "",
    })
  }

  const [organizations, setOrganizations] = useState("")

  const getOrganizations = async () => {
    let organizationArray = await organizationService.getOrganizations()
    setOrganizations(organizationArray)
    // console.log(organizations)
  }

  const [documents, setDocuments] = useState([])
  const [attachmentTypes, setAttachmentTypes] = useState([])

  const getAttachmentTypes = async () => {
    let _attachmentTypes = await optionService.getAttachmentTypes()
    setAttachmentTypes(_attachmentTypes)
  }
  useEffect(() => {
    getAttachmentTypes()
  }, [])

  const getDocuments = async () => {
    let _documents = []
    if (thisMember?.id) {
      _documents = await enrollmentService.getAttachmentsForMember(
        thisMember?.id
      )
    }
    setDocuments(_documents)
  }

  const backListener = () => {
    if ((history.back = "/members")) {
      history.push("/members", { useLastHistory: true })
    } else {
      // console.log("no");
    }
  }

  useEffect(() => {
    getOrganizations()
    window.onpopstate = () => {
      backListener()
    }
  }, [])

  useEffect(() => {
    if (thisMember?.id && !documents.length > 0 && !topOutstandingBalance) {
      getDocuments()
      getOutstandingBalance()
    }
  }, [thisMember])

  const [topOutstandingBalance, setTopOutstandingBalance] = useState(0)
  const getOutstandingBalance = async () => {
    try {
      let _balance = await paymentsService.getPaymentBalanceByMemberId(
        thisMember?.id
      )
      // console.log("PaymentBalance", _balance);
      setTopOutstandingBalance(_balance)
    } catch (error) {
      console.error(
        "error with getting outstanding balances in plans tab",
        error
      )
      //temporarily set balance to 0
      setTopOutstandingBalance({ ...topOutstandingBalance, balanceAmount: 0 })
    }
  }

  //History Tab
  const [unsortedHistoryData, setUnsortedHistoryData] = useState([])

//Date logic
const [dateRange, setDateRange] = useState({})
const [historyError, setHistoryError] = useState(null)
//Pre-selected date range
let today = new Date()

let beginningOfTimeForMember = new Date();
// needed to do if statement because the member object is not always available
if(thisMember){
  beginningOfTimeForMember = new Date(thisMember.createdDateTime);
}
  //don't convert to date strings for datepicker
  const preSelectedDateRange = [
    {
      name: "All",
      startDate: beginningOfTimeForMember,
      endDate: today,
    },
    {
      name: "Past month",
      startDate: 
        new Date(today.getFullYear(), today.getMonth() - 1, today.getDate())
    ,
      endDate: today,
    },
    {
      name: "Past 6 months",
      startDate: 
        new Date(today.getFullYear(), today.getMonth() - 6, today.getDate())
      ,
      endDate: today,
    },
    {
      name: "Past 12 months",
      startDate: 
        new Date(today.getFullYear(), today.getMonth() - 12, today.getDate()),
       
      
      endDate: today,
    },
    {
      name: "Past 24 months",
      startDate: 
        new Date(today.getFullYear(), today.getMonth() - 24, today.getDate()),
     
      
      endDate: today,
    },
    {
      name: "Custom",
      startDate: null,
      endDate: null,
    },
  ]

  //default date range is past 24 months
  useEffect(() => {
    setDateRange(preSelectedDateRange[4])
  }, [])

const getMemberHistory = async (startDate, endDate) => {
  let _history = []
  let endDateTomorrow = new Date(endDate)
  endDateTomorrow.setDate(endDateTomorrow.getDate() + 1)
  endDateTomorrow = new Date(endDateTomorrow)
  setHistoryError(null)
  //takes in member id, start date, end date
  try {
    _history = await timelineService.getHistoryForMember(
      thisMember?.id,
      startDate.toJSON(),
      endDate.toJSON()
    )
  } catch (error) {
    console.error("Error getting history", error?.message)
    setHistoryError(error?.message)
  }

    setUnsortedHistoryData(_history)
    return _history
  }

  //get initial history
  useEffect(() => {
    if (thisMember?.id && dateRange?.startDate && dateRange?.endDate) {
      getMemberHistory(dateRange?.startDate, dateRange?.endDate)
    }
  }, [thisMember?.id])

  const refreshHistory = async () => {
    if (thisMember?.id && dateRange?.startDate && dateRange?.endDate) {
      console.log("refreshing history")
      await getMemberHistory(dateRange?.startDate, dateRange?.endDate)
    }
  }

  return (
    <Box sx={componentStyles.memberProfileContainer}>
      {/* LEFTSIDE MEMBER INFO */}
        <MemberInfo
          member={thisMember}
          getThisMember={getThisMember}
          dependents={dependents}
          saveDependents={saveDependents}
          updateMember={updateMember}
          addDependent={addDependent}
          deleteDependent={deleteDependent}
          phones={phones}
          setPhones={setPhones}
          primaryPhone={primaryPhone}
          setPrimaryPhone={setPrimaryPhone}
          secondaryPhone={secondaryPhone}
          setSecondaryPhone={setSecondaryPhone}
          organizations={organizations}
          handlePhoneInsert={handlePhoneInsert}
          getSpouse={getSpouse}
          spouse={spouse}
          setSpouse={setSpouse}
          saveSpouseInfo={saveSpouseInfo}
          permissionsEnabler={permissionsEnabler}
          refreshHistory= {refreshHistory}
          languageTypes={languageTypes}
        />
     
      <Box sx={componentStyles.tabAndBodyContainer} >

        {/* NAV ROW */}
        <Box sx={componentStyles.tabRowContainer}>
          <Box
            value="0"
            sx={componentStyles.tabContainer(
              activePage === 0 || activePage === 4
            )}
            onClick={handleActivePageChange}
          >
            <Box
              value="0"
              sx={componentStyles.tabOutline(
                activePage === 0 || activePage === 4
              )}
            >
              <ContentCopyIcon sx={componentStyles.icon} />
              <Typography
                value="0"
                variant="body1"
                sx={componentStyles.tabText}
              >
                PLANS
              </Typography>
            </Box>
          </Box>

          <Box
            sx={componentStyles.tabContainer(activePage === 1)}
            value="1"
            onClick={handleActivePageChange}
          >
            <Box value="1" sx={componentStyles.tabOutline(activePage === 1)}>
              <LocalActivityIcon sx={componentStyles.icon} />
              <Typography
                value="1"
                variant="body1"
                fontWeight="500"
                sx={componentStyles.tabText}
              >
                TICKETS
              </Typography>
            </Box>
          </Box>

          {/* hiding notes tab while implementing new history tab*/}
          {/* <Box 
              sx={componentStyles.tabContainer(activePage === 2)}
              value="2"
              onClick={handleActivePageChange}
            >
              <Box
                value="2"
                sx={componentStyles.tabOutline(activePage === 2)}
              >
                <ContentPasteIcon sx={componentStyles.icon}/>
                <Typography
                  value="2"
                  variant="body1"
                  sx={componentStyles.tabText}
                >
                  NOTES
                </Typography>
              </Box>
            </Box> */}

          {/* Comment out for Humboldt release to prod */}
          <Box
            sx={componentStyles.tabContainer(activePage === 7)}
            value="7"
            onClick={handleActivePageChange}
          >
            <Box value="7" sx={componentStyles.tabOutline(activePage === 7)}>
              <FolderOpenIcon sx={componentStyles.icon} />
              <Typography
                value="7"
                variant="body1"
                sx={componentStyles.tabText}
              >
                HISTORY
              </Typography>
            </Box>
          </Box>

          {permissionsEnabler && permissionsEnabler.viewPayments && (
            <Box
              sx={componentStyles.tabContainer(activePage === 5)}
              value="5"
              onClick={handleActivePageChange}
            >
              <Box value="5" sx={componentStyles.tabOutline(activePage === 5)}>
                <AttachMoneyIcon sx={componentStyles.icon} />
                <Typography
                  value="5"
                  variant="body1"
                  sx={componentStyles.tabText}
                >
                  PAYMENTS
                </Typography>
              </Box>
            </Box>
          )}

          <Box
            sx={componentStyles.tabContainer(activePage === 3)}
            value="3"
            onClick={handleActivePageChange}
          >
            <Box value="3" sx={componentStyles.tabOutline(activePage === 3)}>
              <SettingsIcon sx={componentStyles.icon} />
              <Typography
                value="3"
                variant="body1"
                fontWeight="500"
                sx={componentStyles.tabText}
              >
                SETTINGS
              </Typography>
            </Box>
          </Box>

          <Box
            sx={componentStyles.tabContainer(activePage === 6)}
            value="6"
            onClick={handleActivePageChange}
          >
            <Box value="6" sx={componentStyles.tabOutline(activePage === 6)}>
              <FolderOpenIcon sx={componentStyles.icon} />
              <Typography
                value="6"
                variant="body1"
                sx={componentStyles.tabText}
              >
                DOCUMENTS
              </Typography>
            </Box>
          </Box>
        </Box>

        {/* BODY CONTENT */}
        {activePage === 0 && (
          <>
            <MemberPlansTab
              //ENROLLMENTS AND ACTIVE COVERAGES
              availableEnrollments={availableEnrollments}
              activeCoverage={activeCoverage}
              currentActiveCoverage={currentActiveCoverage}
              pastActiveCoverage={pastActiveCoverage}
              futureActiveCoverage={futureActiveCoverage}
              getActiveCoverage={getActiveCoverage}
              UsdFormatterDec={UsdFormatterDec}
              percentFormatter={percentFormatter}
              //getThisMember={getThisMember}
              member={thisMember}
              dependents={dependents}
              spouse={spouse}
              documents={documents}
              attachmentTypes={attachmentTypes}
              getAllDocuments={getDocuments}
              //these are the same and will be replaced by activeCoverage including current and past
              availablePlans={availablePlans}
              enrollments={availablePlans}
              getEnrollments={getEnrollments}
              getOneEnrollment={getOneEnrollment}
              // singleEnrollment={selectedPlan}
              singleEnrollment={selectedEnrollment}
              setSelectedEnrollment={setSelectedEnrollment}
              setActivePage={setActivePage}
              // singlePlan={selectedPlan}
              selectedPlan={selectedPlan}
              setSelectedPlan={setSelectedPlan}
              updateSinglePlan={updateSinglePlan}
              planCosts={PlanCosts}
              totalPlanCosts={totalPlanCosts}
              bankAccount={bankAccount}
              paymentSchedule={PaymentSchedule}
              paymentFrequencies={PaymentFrequencies}
              enrollmentStates={enrollmentStates}
              relationshipTypes={relationshipTypes}
              cancellationReasons={cancellationReasons}
              getPaymentSchedule={getPaymentSchedule}
              getPlanCosts={getPlanCosts}
              getBankAccount={getBankAccount}
              permissionsEnabler={permissionsEnabler}
              setBankAccount={setBankAccount}
              bankAccountTypes={bankAccountTypes}
              editWalletFromPlans={editWalletFromPlans}
              setEditWalletFromPlans={setEditWalletFromPlans}
              handleActivePageChange={handleActivePageChange}
              topOutstandingBalance={topOutstandingBalance}
              getMemberHistory={getMemberHistory}
            />
          </>
        )}

        {activePage === 1 && (
          <>
            <MemberTicketsTab
              member={thisMember}
              singleEnrollment={selectedEnrollment}
              permissionsEnabler={permissionsEnabler}
            />
          </>
        )}

        {/* {activePage === 2 && (
          <>
            <MemberNotesTab
              member={thisMember}
              singleEnrollment={selectedEnrollment}
              permissionsEnabler={permissionsEnabler}
            />
          </>
          )} */}

        {activePage === 3 && (
          <>
            <MemberSettingsTab
            // update
              member={thisMember}
              getThisMember={getThisMember}
              updateMember={updateMember}
              singleEnrollment={selectedEnrollment}
              message={message}
              setMessage={setMessage}
              permissionsEnabler={permissionsEnabler}
            />
          </>
        )}

        {activePage === 4 && selectedEnrollment && (
          <>
            <MemberEnrollTab
              newEnrollment={newEnrollment}
              member={thisMember}
              getThisMember={getThisMember}
              singleEnrollment={selectedEnrollment}
              getEnrollments={getEnrollments}
              getOneEnrollment={getOneEnrollment}
              documents={documents}
              UsdFormatterDec={UsdFormatterDec}
              permissionsEnabler={permissionsEnabler}
              setActivePage={setActivePage}
              getPaymentSchedule={getPaymentSchedule}
            />
          </>
        )}
        {activePage === 5 && (
          <>
            <MemberPaymentsTab
              UsdFormatterDec={UsdFormatterDec}
              member={thisMember}
              enrollments={availablePlans} //will need to replace checks agains this w/ active coverage in future
              // enrollmentsList={enrollments}
              getOneEnrollment={getOneEnrollment}
              // singleEnrollment={selectedPlan}
              currentActiveCoverage={currentActiveCoverage}
              planCosts={PlanCosts}
              bankAccount={bankAccount}
              setBankAccount={setBankAccount}
              bankAccountTypes={bankAccountTypes}
              // setBankAccountTypes={setBankAccountTypes}
              paymentSchedule={PaymentSchedule}
              setPaymentSchedule={setPaymentSchedule}
              paymentFrequencies={PaymentFrequencies}
              getPaymentSchedule={getPaymentSchedule}
              getPlanCosts={getPlanCosts}
              getBankAccount={getBankAccount}
              paymentTransactionTypes={paymentTransactionTypes}
              paymentStatuses={paymentStatuses}
              bankAccountPrenoteStatuses={bankAccountPrenoteStatuses}
              permissionsEnabler={permissionsEnabler}
              editWalletFromPlans={editWalletFromPlans}
              setEditWalletFromPlans={setEditWalletFromPlans}
              topOutstandingBalance={topOutstandingBalance}
            />
          </>
        )}
        {activePage === 6 && (
          <>
            <MemberDocumentsTab
              member={thisMember}
              documents={documents}
              attachmentTypes={attachmentTypes}
              getAllDocuments={getDocuments}
              permissionsEnabler={permissionsEnabler}
            />
          </>
        )}
        {/* Comment out for Humboldt release to prod */}
        {activePage === 7 && (
          <>
            <MemberHistoryTab
              member={thisMember}
              permissionsEnabler={permissionsEnabler}
              getMemberHistory={getMemberHistory}
              dateRange={dateRange}
              setDateRange={setDateRange}
              preSelectedDateRange={preSelectedDateRange}
              unsortedHistoryData={unsortedHistoryData}
              today={today}
              beginningOfTimeForMember={beginningOfTimeForMember}
              historyError={historyError}
              setHistoryError={setHistoryError}
            />
          </>
        )}

        <Snackbar
          open={message.messageDisplay}
          // open={true}
          autoHideDuration={message.autoHideDuration}
          onClose={handleMessageClose}
          sx={{ opacity: 0.8 }}
        >
          <MuiAlert
            elevation={6}
            variant="filled"
            // onClose={handleClose}
            severity={message.severity}
            sx={{ width: "100%", backgroundColor: message.backgroundColor }}
          >
            {message.message}
          </MuiAlert>
        </Snackbar>
      </Box>
    </Box>
  )
}
