import React, {
  useEffect,
  useLayoutEffect,
  useState,
  useContext,
  Suspense,
  lazy,
} from 'react';
import { useHistory } from 'react-router';

//Assets
// import PlaceHolderLogo from '../../../assets/images/placeHolder.svg';
// import FullColorBlackText from "../../../assets/images/FullColorBlackText.svg";
// import defaultLogo from "../../../assets/images/FullColorOnlyLogo.png";

//Muicons
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import Close from '@mui/icons-material/Close';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import MenuIcon from '@mui/icons-material/Menu';
import AccountCircle from '@mui/icons-material/AccountCircle';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

//MuiComponents
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import AlerTitle from '@mui/material/AlertTitle';
import Fade from '@mui/material/Fade';
import MobileStepper from '@mui/material/MobileStepper';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Drawer from '@mui/material/Drawer';
import Home from '@mui/icons-material/Home';
import Icon from '@mui/material/Icon';
import ArrowDropDown from '@mui/icons-material/ArrowDropDown';
import Divider from '@mui/material/Divider';
import { Collapse, Link, ListItem, TextField } from '@mui/material';
import { formatCurrency } from '../../../../utils';

export default function PortalPlanSummary(props) {
  const [expanded, setExpanded] = useState(false);
  const handleExpandSection = () => {
    setExpanded(!expanded);
  };

  let expandIcon = expanded ? (
    <RemoveIcon
      fontSize="large"
      sx={{
        //  position: "absolute",
        //  right: "14%",
        //  top: "20%",
        color: 'white',
        backgroundColor: '#195ffb',
        borderRadius: '100px',
        boxShadow: '-1px 1px 4px 0px #00000040',
      }}
    />
  ) : (
    <AddIcon
      fontSize="large"
      sx={{
        //  position: "absolute",
        //  right: "14%",
        //  top: "20%",
        color: '#195ffb',
        borderRadius: '100px',
        boxShadow: '-1px 1px 4px 0px #00000040',
      }}
    />
  );

  const [election, setElection] = useState({});
  useEffect(() => {
    if (props.plan && props.plan?.options?.length > 0) {
      let selectedOption = props.plan.options.find((x) => x.isSelected);
      // console.log('found selection', selectedOption);
      setElection(selectedOption);
    } else {
      let memberElection = props.plan.memberOptions.find((x) => x.isSelected);
      let spouseElection = props.plan.spouseOptions.find((x) => x.isSelected);
      let dependentElection = props.plan.dependentOptions.find(
        (x) => x.isSelected
      );
      setElection({
        member: memberElection,
        spouse: spouseElection,
        dependent: dependentElection,
      });
    }
  }, [props.plan, props.plan?.options]);
  // console.log(props.plan, election);

  return (
    <>
      <Typography
        component={'div'}
        className="col-12"
        sx={{
          fontFamily: 'Archivo',
          textTransform: 'uppercase',
          fontSize: '12px',
          letterSpacing: '1px',
          color: 'primary.main',
        }}
      >
        {props.groupPlan?.name}
      </Typography>

      <Box
        className="col-12"
        sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            // minHeight: "57px",
            fontSize: '20px',
            fontWeight: '600',
            lineHeight: '26px',
            position: 'relative',
            alignItems: 'center',
            padding: '0px',
            fontFamily: 'Poppins',
            color: 'text.primary',
            width: '100%',
            justifyContent: 'space-between',
          }}
        >
          <span
            style={{
              cursor: 'pointer',
              position: 'relative',
            }}
            onClick={handleExpandSection}
          >
            {props.title}
            {/* bigger clickable area */}
          </span>

          <Box
            sx={{
              display: 'flex',
              justifyContent: 'end',
              color: '#195ffb',
              width: ' 18%',
              height: '100%',
              cursor: 'pointer',
            }}
            onClick={handleExpandSection}
          >
            {expandIcon}
          </Box>
        </Box>
      </Box>
      <Typography
        component={'div'}
        variant="body2"
        className="col-12"
        sx={{
          letterSpacing: '.17px',
          fontSize: '14px',
          color: 'text.secondary',
          lineHeight: '20px',
        }}
      >
        {election?.name != null ? (
          <>
            {election?.name}
            {election.name.includes('%') &&
              election?.benefitAmount &&
              ` (Max ${formatCurrency(election.benefitAmount)})`}
          </>
        ) : (
          <>
            <span>
              Member: {formatCurrency(election?.member?.coverageAmount || 0)}{' '}
              <br></br>
            </span>
            <span>
              Spouse: {formatCurrency(election?.spouse?.coverageAmount || 0)}{' '}
              <br></br>
            </span>
            <span>
              Dependent:{' '}
              {formatCurrency(election?.dependent?.coverageAmount || 0)}{' '}
              <br></br>
            </span>
          </>
        )}
      </Typography>
      <Collapse
        // sx={enrollmentPlanStyles.collapseSection}
        in={expanded}
        // unmountOnExit
      >
        <Box
          sx={{
            fontFamily: 'Archivo',
            fontWeight: '500',
            fontSize: '15px',
            lineHeight: '25px',
            textAlign: 'left',
            color: 'text.secondary',
          }}
        >
          <style>
            {`
                  ul {
                     padding-left: 1.3rem;
                     padding-right: 2.3rem;
                  }
                  li {
                     lineheight: 18px;
                     margin: 5px 0px;
                  }
                  `}
          </style>

          <span
            style={{ color: 'text.secondary' }}
            dangerouslySetInnerHTML={{ __html: props.plan?.helpText }}
          ></span>
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            width: '100%',
            marginTop: '16px',
          }}
        >
          <Typography
            component={'span'}
            sx={{
              color: 'primary.text',
              fontWeight: 600,
              fontSize: '15px',
              letterSpacing: '.17px',
              fontFamily: 'Archivo',
              fontWeight: 700,
            }}
          >
            {formatCurrency(
              props.plan?.totalPremiumAmount ||
                election?.memberPremiumAmount ||
                0
            )}
          </Typography>
        </Box>
      </Collapse>

      <Divider
        sx={{
          width: 'calc(100% + 32px)',
          marginBottom: '16px',
          marginTop: '16px',
          marginLeft: '-16px',
          color: 'background.border',
          opacity: 0.5,
        }}
      />
    </>
  );
}
