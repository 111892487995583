import React, { useEffect, useState, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router';

//MuiX
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

//Assets
import FallbackLogo from '../../../assets/images/SourceFileonly icon.png';

//Muicons
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

//MuiComponents
import Button from '@mui/material/Button';
import { Divider, Typography, useTheme, useMediaQuery } from '@mui/material';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Alert from '@mui/material/Alert';

//Services
import memberService from '../../../services/member-service';

// Our components
import ExternalRegisterSetup from '../../app-external/external-register-setup/ExternalRegisterSetup';
import PoweredBy from '../external-enrollments/PoweredBy';
import InfinityLoader from '../../UH-loading-animation/InfinityLoader';
import DuplicateEmailDrawer from '../external-register-setup/DuplicateEmailDrawer';
import ExternalRegisterOrLoginEnrollmentHeader from '../external-enrollments/ExternalRegisterOrLoginEnrollmentHeader';

//shared styles
import externalEnrollmentSharedStyles from '../externalEnrollmentSharedStyles';

//component styles
const componentStyles = {
  inputAndSubmitContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '100%',
    maxWidth: '575px',
    padding: '0px 10px',
  },
  text: {
    textAlign: 'center',
    margin: '20px auto',
  },
  input: {
    margin: '0px auto 15px auto ',
  },
  datePicker: {
    margin: '0px auto 15px auto',
    width: '100%',
    colro: 'primary.main',
  },
  alert: {
    margin: '0px auto 10px',
    width: '90%',
  },
  registerButtonContainer: {
    margin: '20px auto ',
    width: '100%',
  },
  alreadyHaveAccountButton: {
    maxWidth: '575px',
    height: '50px',
    fontSize: '15px',
    fontWeight: 500,
    lineHeight: '25px',
  },
};

function ExternalRegister(props) {
  let params = useLocation();

  let today = new Date();
  let day = today.getDate();
  let month = today.getMonth() + 1;
  let year = today.getFullYear();
  day = day < 10 ? '0' + day : day;
  month = month < 10 ? '0' + month : month;
  let dateMax = `${year - 18}-${month}-${day}`;
  let dateMaxObj = new Date(dateMax);
  let dateMin = `${year - 100}-${month}-${day}`;
  let dateMinObj = new Date(dateMin);

  const [disabled, setDisabled] = useState(true);

  const history = useHistory();

  const goto = (route) => {
    history.push(route);
  };

  const [values, setValues] = useState({
    enrollmentId: '',
    lastName: '',
    dob: null,
    zipCode: '',
    memberObj: {},
    memberReturned: false,
  });

  const [displayError, setDisplayError] = useState(false);

  const handleDobFocus = (e) => {
    e.target.setAttribute('type', 'date');
  };

  const handleMobileDobChange = (e) => {
    let value = e;

    // value = format(new Date(e), 'yyyy-MM-dd')
    setValues({
      ...values,
      dob: value,
    });
  };

  const handleValueChange = (e) => {
    let inputVal = e.target.value;
    let inputProperty = e.target.getAttribute('data-property');

    setValues({
      ...values,
      [inputProperty]: inputVal,
    });

    if (inputProperty === 'zipCode') {
      checkZipError(inputVal);
    }
  };

  const [organization, setOrganization] = useState({});

  const getThisOrganization = async () => {
    try {
      let thisOrganization = await props.getOrganization();
      setOrganization(thisOrganization);
    } catch (e) {
      setOrganization('');
    }
  };

  const [message, setMessage] = useState({});
  useEffect(() => {
    const timeId = setTimeout(() => {
      // After 3 seconds set the show value to false
      setMessage({
        ...message,
        messageDisplay: false,
        autoHideDuration: '',
        severity: '',
        backgroundColor: '',
        message: '',
      });
    }, 5000);

    return () => {
      clearTimeout(timeId);
    };
  }, [message.messageDisplay]);

  const handleMessageClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setMessage({
      ...message,
      messageDisplay: false,
      autoHideDuration: '',
      severity: '',
      backgroundColor: '',
      message: '',
    });
  };

  const getEnrollmentIDFromURL = () => {
    let variables = window.location.href.split('?')[1].split('&');
    let thisVariable = variables.filter((name) => name.includes('id'));
    let regex = /id=(.+)/;
    if (thisVariable[0]?.length <= 3) return;
    let thisEnrollmentId = regex.exec(thisVariable)[1];
    // console.log(/^[\p{L}\p{N}]{6,}$/.test(thisEnrollmentId))
    if (/.{5,6}/.test(thisEnrollmentId)) {
      setValues({
        ...values,
        enrollmentId: thisEnrollmentId,
      });
      setMessage({
        ...message,
        messageDisplay: true,
        autoHideDuration: 2500,
        backgroundColor: '',
        severity: 'success',
        message: 'Successfully Populated Enrollment ID',
      });
    }
  };

  useEffect(() => {
    getThisOrganization();
    if (params.search.length > 0) {
      getEnrollmentIDFromURL();
    }
  }, []);

  let existingUserError = 'Enrollment ID already registered, please log in';
  const continueRegistration = async (e) => {
    props.setLoading(true);
    e.preventDefault();
    // let response = await memberService.searchMemberEnrollment(values)
    // console.log(response)
    let enrollmentSearchObj = {};
    enrollmentSearchObj.enrollmentId = values.enrollmentId;
    if (values.lastName) {
      enrollmentSearchObj.lastName = values.lastName;
    } else {
      enrollmentSearchObj.lastName = '';
    }
    if (values.dob) {
      enrollmentSearchObj.dob =
        values.dob.length > 10
          ? // format(new Date(values.dob), 'yyyy-MM-dd')
            values.dob //already formatted with system timezone
          : values.dob;
    } else {
      enrollmentSearchObj.dob = new Date('1/1/1900');
    }
    if (values.zipCode) {
      enrollmentSearchObj.zipCode = values.zipCode;
    } else {
      enrollmentSearchObj.zipCode = '';
    }

    console.log('EnrollmentSearchObj', enrollmentSearchObj);
    // // console.log(enrollmentSearchObj)

    await memberService.searchMemberEnrollment(enrollmentSearchObj).then(
      (succ) => {
        //if a member already has registration history
        if (succ.isRegistered) {
          props.setLoading(false);
          setEnrollmentError(existingUserError);
        } else {
          //if a member does not have registration history

          props.setLoading(false);
          setValues((values) => ({
            ...values,
            memberObj: succ.member,
            memberReturned: true,
          }));
        }
      },
      (error) => {
        //display incorrect id error differently than enrollment id length verificaiton error
        if (error.response.status === 400 || error.response.status === 404) {
          setEnrollmentIdValidError(
            'Enrollment ID not found. Try again or register with last name, date of birth, and zip code.'
          );
        }
        setDisplayError(true);
        props.setLoading(false);
      }
    );
  };

  const backToLogin = () => {
    history.push('/login');
  };

  //const getOrganizationLogo = () => {
  //    if (organization) {
  //        return `/api/image/organization/${organization.id}`
  //    } else return FallbackLogo
  //}
  const [imgFetchAttempts, setImgFetchAttempts] = useState(0);
  const handleLogoError = (e) => {
    console.log('logo img error', FallbackLogo);
    setImgFetchAttempts((prev) => prev++);
    if (imgFetchAttempts <= 10) e.target.src = FallbackLogo;
  };

  // Enrollment ID Erroring
  const [showEnrollmentError, setShowEnrollmentError] = useState(false);
  // Only show on Blur
  const checkEnrollmentError = () => {
    if (values.enrollmentId != '' && values.enrollmentId.length <= 4) {
      setShowEnrollmentError(true);
      setEnrollmentError('Enrollment ID must be 6 digits long');
    } else setEnrollmentError(null);
    if (values.lastName && values.dob && values.zipCode) {
      setEnrollmentError(null);
    }
    setShowEnrollmentError(true);
  };
  const [enrollmentError, setEnrollmentError] = useState(null);
  const [enrollmentIdValidError, setEnrollmentIdValidError] = useState(false);

  useEffect(() => {
    if (
      values.enrollmentId &&
      (values.enrollmentId.length == 6 || values.enrollmentId.length == 5) &&
      showEnrollmentError
    ) {
      setShowEnrollmentError(false);
      setEnrollmentError(null);
      setEnrollmentIdValidError(false);
    }
  }, [values.enrollmentId]);

  //Dob Error handling
  const [dobError, setDobError] = useState(null);

  const dobErrorMessage = useMemo(() => {
    switch (dobError) {
      case 'maxDate': {
        return 'Must be older than 18 years old';
      }
      case 'minDate': {
        return 'Must be less than 100 years old';
      }
      case 'disableFuture': {
        return 'Must be greater than 0 years old';
      }
      case 'invalidDate': {
        return 'Your date is not valid';
      }
      default: {
        return '';
      }
    }
  }, [dobError]);

  // Zip Erroring
  const [zipError, setZipError] = useState(null);
  const [showZipError, setShowZipError] = useState(false);
  const checkZipError = (value = values.zipCode) => {
    if (!/\b\d{5}\b/.test(value)) {
      setShowZipError(true);
      setZipError('Zip code must be 5 digits long');
    } else {
      setShowZipError(false);
      setZipError(null);
    }
  };

  // const error = (field) => {
  //     if (field == "enrollmentId") {
  //         if (values.enrollmentId && values.enrollmentId.length <= 5) {
  //             return true
  //         } else return false
  //     }
  //     else if (field == 'registered')
  // }

  const handleDisabled = () => {
    //they're either using an enrollment id, or newly registering
    console.log('dob values', values.dob <= dateMinObj);
    let disabled = true;
    if (values.enrollmentId?.length > 0) {
      //they're using an enrollment id, check for validity
      if (values.enrollmentId != '' && values.enrollmentId.length > 4)
        disabled = false;
    } else {
      //they're not using an enrollment id, check for validity of other fields
      let lastNameValid = false;
      let dobValid = false;
      let zipCodeValid = false;
      if (values.lastName?.length > 0) lastNameValid = true;
      if (
        values.dob != '' &&
        values.dob &&
        !(values.dob <= dateMinObj) &&
        !(dateMaxObj <= values.dob) &&
        !dobError
      ) {
        dobValid = true;
      }
      if (values.zipCode?.length > 0 && /\b\d{5}\b/.test(values.zipCode))
        zipCodeValid = true;
      disabled = !(lastNameValid && dobValid && zipCodeValid);
    }
    setDisabled(disabled);
  };

  useEffect(() => {
    handleDisabled();
  }, [values, dobError]);

  return (
    <Box
    sx={externalEnrollmentSharedStyles.externalPreEnrollmentParentContainer}
  >
      {props.loading && (
        <>
          <InfinityLoader
            style={externalEnrollmentSharedStyles.infinityLoader}
          />
          <style>
            {`
              div.lottie-div > svg {
                height:55px!important;
                position:absolute;
                top:50%;
              }
              `}
          </style>
        </>
      )}
      <Box id="enrollment-login" sx={externalEnrollmentSharedStyles.componentContainer}>
        <Box>
          {/* Header */}
          <ExternalRegisterOrLoginEnrollmentHeader
            organization={organization}
            login={false}
          />

          {!values.memberReturned ? (
            <>
              <Typography variant="body2" sx={componentStyles.text}>
                Your Enrollment ID will help you log in{' '}
                <em style={{ fontWeight: '700' }}>faster.</em>
              </Typography>

              <Box sx={componentStyles.inputAndSubmitContainer}>
                <TextField
                  fullWidth
                  sx={componentStyles.input}
                  label="Enrollment ID"
                  variant="outlined"
                  value={values.enrollmentId}
                  onChange={handleValueChange}
                  inputProps={{ 'data-property': 'enrollmentId' }}
                  error={showEnrollmentError && enrollmentError}
                  helperText={showEnrollmentError && enrollmentError}
                  onBlur={checkEnrollmentError}
                  disabled={values.lastName && values.dob && values.zipCode}
                />

                {enrollmentError == existingUserError && (
                  <Button
                    fullWidth
                    variant="contained"
                    sx={externalEnrollmentSharedStyles.largeBlueButton}
                    onClick={backToLogin}
                  >
                    Take me to log in <KeyboardArrowRightIcon />
                  </Button>
                )}
              </Box>

              {enrollmentIdValidError && (
                <Alert severity="error" sx={componentStyles.alert}>
                  {enrollmentIdValidError}
                </Alert>
              )}

              <Divider sx={externalEnrollmentSharedStyles.divider}>Or</Divider>

              <Box sx={componentStyles.inputAndSubmitContainer}>
                <TextField
                  fullWidth
                  sx={componentStyles.input}
                  disabled={values.enrollmentId?.length > 4}
                  label="Last Name"
                  variant="outlined"
                  value={values.lastName}
                  onChange={handleValueChange}
                  inputProps={{ 'data-property': 'lastName' }}
                />

                {/* Date of birth */}

                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    timezone="system"
                    disableFuture
                    disabled={values.enrollmentId?.length > 4}
                    label="Date of birth"
                    openTo="year"
                    views={['year', 'month', 'day']}
                    format="MM/dd/yyyy"
                    minDate={dateMinObj}
                    maxDate={dateMaxObj}
                    value={values.dob || null}
                    onChange={handleMobileDobChange}
                    onError={(error) => setDobError(error)}
                    sx={componentStyles.datePicker}
                    slotProps={{
                      textField: {
                        helperText: dobErrorMessage,
                      },
                      openPickerButton: { sx: { visibility: 'hidden' } },
                    }}
                  />
                </LocalizationProvider>

                {/* Zip Code */}
                <TextField
                  fullWidth
                  sx={componentStyles.input}
                  error={showZipError && zipError}
                  helperText={showZipError && zipError}
                  onChange={handleValueChange}
                  disabled={values.enrollmentId?.length > 4}
                  label="Zip Code"
                  variant="outlined"
                  value={values.zipCode}
                  inputProps={{ 'data-property': 'zipCode' }}
                />

                <Box sx={componentStyles.registerButtonContainer}>
                  <Button
                    fullWidth
                    variant="contained"
                    disabled={disabled}
                    sx={externalEnrollmentSharedStyles.largeBlueButton}
                    onClick={continueRegistration}
                  >
                    Register
                  </Button>
                </Box>

                <Button
                  fullWidth
                  variant="text"
                  sx={externalEnrollmentSharedStyles.largeBlueButton}
                  onClick={backToLogin}
                >
                  Already have an account? Log in here.
                </Button>
              </Box>
            </>
          ) : (
            <ExternalRegisterSetup
              member={values.memberObj}
              organization={organization}
              setLoading={props.setLoading}
            />
          )}
        </Box>
        <PoweredBy />

        <Snackbar
          open={message.messageDisplay}
          // open={true}
          autoHideDuration={message.autoHideDuration}
          onClose={handleMessageClose}
          sx={{ opacity: 0.8, paddingBottom: '40px' }}
        >
          <MuiAlert
            elevation={6}
            variant="filled"
            // onClose={handleClose}
            severity={message.severity}
            sx={{ width: '100%', backgroundColor: message.backgroundColor }}
          >
            {message.message}
          </MuiAlert>
        </Snackbar>
      </Box>
    </Box>
  );
}

export default ExternalRegister;
