const externalEnrollmentSharedStyles = {
  componentContainer: {
    display: 'flex',
    flexDirection: 'column',
    bgcolor: 'background.default',
    color: 'text.primary',
    padding: '50px 35px 30px',
    height: '100vh',
    margin: 'auto',
    maxWidth: '575px',
    justifyContent: 'space-between',
  },
  largeBlueButton: {
    maxWidth: '575px',
    height: '50px',
    fontSize: '15px',
    width: '100%',
  },
  divider: {
    color: '#989898',
    fontWeight: 700,
    fontFamily: 'Poppins',
    maxWidth: '600px',
    margin: '15px auto 25px',
  },
  externalPreEnrollmentParentContainer: {
    display: 'block',
    height: '100vh',
    overflowY: 'auto',
    overflowX: 'hidden',
    WebkitOverflowScrolling: 'touch',
  },
  infinityLoader: {
    position: 'absolute',
    zIndex: 5,
    width: '100%',
    height: '100vh',
    backgroundColor: '#00000050',
    backdropFilter: 'blur(10px)',
  },
};
export default externalEnrollmentSharedStyles;
