import React, { useEffect, useState } from 'react';

//Mui X
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers';

//MUI Components
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Drawer from '@mui/material/Drawer';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

//MUI Icons
import WorkIcon from '@mui/icons-material/Work';
import WorkOffIcon from '@mui/icons-material/WorkOff';
import CloseIcon from '@mui/icons-material/Close';

//Utils
import {
  year,
  month,
  day,
  formatPhoneNumber,
  formatDateObjYYYYMMDD,
} from '../../../utils';

//services
import optionService from '../../../../services/optionService';
import memberService from '../../../../services/member-service';

export default function PortalPersonalSummary(props) {
  const [workStatuses, setWorkStatuses] = useState([]);
  const getWorkStatuses = async () => {
    let _statuses = await optionService.getWorkStatus();
    if (_statuses) {
      setWorkStatuses(_statuses);
    }
  };
  useEffect(() => {
    if (workStatuses?.length === 0) getWorkStatuses();
  }, [workStatuses?.length]);

  let preferredPhone = props.thisMemberPhone.find((phone) => phone.isPreferred);

  let workIcon = (workStatusId) => {
    if (workStatusId === 1) {
      return <WorkIcon />;
    } else {
      return <WorkOffIcon />;
    }
  };

  const [memberInfo, setMemberInfo] = useState(props.thisMember);
  useEffect(() => {
    setMemberInfo(props.thisMember);
  }, [props.thisMember]);

  const handleMemberUpdate = (e) => {
    console.log(e, dateMinObj, e > dateMinObj);
    let key;
    let value;
    if (!e?.target) {
      // if (e < dateMinObj) return;
      key = 'returnToWork';
      value = e;
    } else {
      key = e.target.name;
      value = e.target.value;
    }
    setMemberInfo({ ...memberInfo, [key]: value });
  };

  const handleMemberSave = async () => {
    if (memberInfo?.workStatusId != 1 && !returnToWorkDrawerOpen) {
      setReturnToWorkDrawerOpen(true);
      return;
    }
    setReturnToWorkDrawerOpen(false);
    try {
      await memberService.updateMember(memberInfo);
    } catch (error) {
      console.log(error);
    }

    await props.getThisMember();
  };

  const [returnToWorkDrawerOpen, setReturnToWorkDrawerOpen] = useState(false);

  let dateMin = `${year}-${month}-${day}`;
  let dateMinObj = new Date(dateMin);

  return (
    <>
      <TextField
        disabled
        className="col-12"
        variant="standard"
        label="Name"
        value={
          props.thisMember?.firstName + ' ' + props.thisMember?.lastName || ''
        }
        fullWidth
      />

      <TextField
        disabled
        className="col-12"
        variant="standard"
        label="Mobile Phone Number"
        value={formatPhoneNumber(preferredPhone.number) || ''}
        fullWidth
      />

      <TextField
        disabled
        className="col-12"
        variant="standard"
        label="Email Address"
        value={props.thisMember?.email || ''}
        fullWidth
      />

      <Box className="col-12">
        <Select
          // disabled
          className="col-12"
          variant="standard"
          label="Working Status"
          name="workStatusId"
          sx={{ marginBottom: '10px' }}
          startAdornment={
            <InputAdornment position="start">
              {workIcon(memberInfo?.workStatusId)}
            </InputAdornment>
          }
          value={memberInfo?.workStatusId || ''}
          fullWidth
          onChange={handleMemberUpdate}
        >
          {workStatuses.map((status) => (
            <MenuItem key={status.id} value={status.id}>
              {status.name}
            </MenuItem>
          ))}
        </Select>
        {props.thisMember?.workStatusId != 1 && (
          <Typography
            variant="body1"
            sx={{ fontSize: '14px', marginBottom: '10px', marginTop: '10px' }}
          >
            Estimated Return Date:{' '}
            {formatDateObjYYYYMMDD(new Date(props.thisMember?.returnToWork)) ||
              'Not Set'}
          </Typography>
        )}
        {memberInfo?.workStatusId != props.thisMember?.workStatusId && (
          <Box
            className="col-12"
            sx={{ display: 'flex', justifyContent: 'flex-end' }}
          >
            <Button
              sx={{
                borderRadius: '8px',
                background: 'var(--Light-Primary-Main, #195FFB)',
                boxShadow:
                  '0px 3px 1px -2px rgba(0, 0, 0, 0.20), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);',
                fontFamily: 'Archivo',
                fontSize: '14px',
                fontWeight: 500,
                lineHeight: '24px',
                letterSpacing: '0.4px',
              }}
              // disabled={
              //   memberInfo?.workStatusId != 1 &&
              //   memberInfo?.returnToWork == null
              // }
              variant="contained"
              onClick={handleMemberSave}
            >
              Update
            </Button>
          </Box>
        )}
      </Box>

      <Drawer
        open={returnToWorkDrawerOpen}
        onClose={() => setReturnToWorkDrawerOpen(false)}
        anchor="bottom"
        sx={{ maxHeight: '200px' }}
        PaperProps={{
          sx: {
            borderRadius: '12px 12px 0px 0px',
            padding: '0px 16px 24px',
            bgcolor: 'background.default',
            color: 'text.primary',
            maxHeight: '75%',
          },
        }}
        disableScrollLock={true}
      >
        <Box
          sx={{
            bgcolor: 'paper.background.default',
            color: 'text.primary',
            maxWidth: '600px',
            margin: 'auto',
          }}
        >
          <Box
            className="row"
            sx={{
              marginBottom: '20px',
              backgroundColor: 'white',
              position: 'sticky',
              top: 0,
              paddingTop: '16px',
            }}
          >
            <Typography
              className="col-10"
              variant="blueAccent"
              sx={{ fontSize: '24px' }}
            >
              Confirm Work Status
            </Typography>
            <Box className="col-2" sx={{ textAlign: 'end' }}>
              <IconButton onClick={() => setReturnToWorkDrawerOpen(false)}>
                <CloseIcon sx={{ color: '#195ffb' }} />
              </IconButton>
            </Box>
          </Box>
          <Box className="col-12" sx={{ marginBottom: '20px' }}>
            <Typography variant="body1" sx={{ fontSize: '14px' }}>
              Please note, if you are not actively working you cannot receive
              benefits and payments will not be withdrawn.
            </Typography>
          </Box>

          <Box className="col-12" sx={{ marginBottom: '25px' }}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                // disableFuture
                label="Estimated Return Date*"
                openTo="month"
                views={['year', 'month', 'day']}
                format="MM/dd/yyyy"
                name="returnToWork"
                minDate={dateMinObj}
                // maxDate={dateMaxObj}
                value={new Date(memberInfo?.returnToWork) || null}
                onChange={handleMemberUpdate}
                // onAccept={handleDobBlur}
                // onError={(error) => setDobError(error)}
                sx={{ width: '100%' }}
                slotProps={{
                  textField: {
                    // helperText: dobErrorMessage,
                  },
                  openPickerButton: { sx: { visibility: 'hidden' } }, //hide calendar icon calandar
                }}
              />
            </LocalizationProvider>
          </Box>
          <Box className="col-12" sx={{ textAlign: 'center' }}>
            <Button
              disabled={memberInfo?.returnToWork < dateMinObj}
              fullWidth
              variant="contained"
              onClick={handleMemberSave}
            >
              Confirm
            </Button>
          </Box>
        </Box>
      </Drawer>
    </>
  );
}
