import React, { useContext, useEffect, useState } from "react";

//Mui Components
import {
  Box,
  CardHeader,
  Chip,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Typography,
} from "@mui/material";
import Button from "@mui/material/Button";

// Assets
import vericheckLogo from "../../../../../../../assets/images/vericheckLogo.png";

//Services

//Mui icons
import AccessTimeIcon from "@mui/icons-material/AccessTime"
import DownloadIcon from "@mui/icons-material/Download";
import MoreVertIcon from "@mui/icons-material/MoreVert";

//Our Components
import OurDataGrid from "../../../../../DataGrid/OurDataGrid";
import { useGridApiRef } from "@mui/x-data-grid";
import RefundOrVoidDrawer from "../payment-drawers/RefundOrVoidDrawer";
import PlaceHolderMessage from "../../../PlaceHolderMessages/PlaceHolderMessage";
import PaymentStatusChips from "../PaymentStatusChips";
import NextPaymentChip from "../../../../DisplayChips/NextPaymentChip";
import NonPeakPayChip from "../../../../DisplayChips/NonPeakPayChip";

//utils
import { formatUTCToShortDate } from "../../../../../../utils";

const componentStyles = {
  componentBody: {
    height: "calc(100vh - 371px)",
    borderRadius: "0px",
    backgroundColor: "background.default",
    padding: "16px 20px",
  },
  headerContainer: {display: 'flex', flexDirection: 'row', justifyContent:'space-between'},
  headerTitle: {
    fontFamily: "Poppins",
    fontWeight: 500,
    lengthineHeight: '32px',
  },
  headerDescription: {
    fontFamily: "Archivo",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "28px",
    color: "text.gray",
  },
  historyGridContainer: {
    height: "calc(100% - 150px)" 
  },
  gridTitle: (showPayHistory) => ({
      display: "flex",
      height: "42px",
      width: "97px",
      justifyContent: "center",
      alignItems: "center",
      color: showPayHistory ? 'primary.main' : 'text.secondary',
      borderBottom: "2px solid",
      borderColor: showPayHistory ? 'primary.main' : 'text.secondary',
      fontSize: "14px",
      fontWeight: 500,
      fontFamily: "Archivo",
  }),
  placeHolderMessageContainer: {width: '100%', height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', border: '1px solid', borderColor: 'background.border' },
  exportButton: {
    height: "30px",
    width: "100px",
    fontSize: "13px",
    fontWeight: 500,
    fontFamily: "Archivo",
    justifyContent: "space-between",
    marginTop: '10px'
  },
  
}

export default function PaymentGridCard(props) {
  let UsdFormatterDec = props.UsdFormatterDec;

  console.log("props in PaymentGridCard", props);

  //date formatter - takes in date string
  const dateFormatter = (date) => {
    let dateObj = new Date(date);

    let month = (dateObj.getMonth() + 1).toString().padStart(2, "0");
    let day = dateObj.getDate().toString().padStart(2, "0");
    let year = dateObj.getFullYear();

    const formattedDate = `${month}/${day}/${year}`;
    return formattedDate;
  };

  //props to pass down to void, refund, or rerun drawer that can be opened up by each row menu
  let getAllPayments = props.getAllPayments;
  let oneTimePayments = props.oneTimePayments
  let member = props.member;
  let handleOpenMakePaymentDrawer = props.handleOpenMakePaymentDrawer;
  let allowEdit =
    props.permissionsEnabler.editPayments &&
    props.permissionsEnabler.editScheduledPayments;

  // EACH Row Menu button (Refund, Send Receipt)
  //handle opening refundOrVoid drawer
  //will be selected when menu of specific transaction and 'Refund' is selected

  //open Row Menu
  const [anchorEl, setAnchorEl] = useState({});
  const open = Boolean(anchorEl);
  const [action, setAction] = useState();
  const [refundOrVoidDrawerOpen, setRefundOrVoidDrawerOpen] = useState(false);
  const [refundOrVoidAccountSelected, setRefundOrVoidAccountSelected] =
    useState({
      key: "",
      paymentId: "No transaction selected",
      type: "",
      amount: "",
      status: "",
      processor: "",
      processedDate: "",
      settledDate: "",
      createdDate: "",
      // originatedDate: "",
      schedule: "",
      account: "",
      lastUpdated: "",
      returnCode: "",
    });

  const handleOpenRowMenu = (e, transaction) => {
    //set state based on which payment/transaction was selected to refund
    setRefundOrVoidAccountSelected(transaction);
    setAnchorEl({
      ...anchorEl,
      [e.currentTarget.value]: e.currentTarget,
    });
  };

  const handleCloseRowMenu = () => {
    setAnchorEl({});
  };

  const handleOpenDrawer = (e) => {
    let action = e.target.getAttribute("value");
    //based on menu item clicked (void or refund), set state and use it to render drawer
    setAction(action);
    setRefundOrVoidDrawerOpen(true);
    setAnchorEl({});
  };

  //DATAGRID STUFF
  const apiRef = useGridApiRef();

  const [rows, setRows] = useState([]);
  const buildRows = () => {
    let _rows = [];
    //map over payments that will make up row data
    //have to add an index as key for the grid - need unique ID properties (this property is hidden on the grid - only necessary for rendering)
    let payments = props.allPayments?.map((payment, index) => {
      //get payment type name
      let paymentType = props.paymentTransactionTypes?.find((type) => {
        return type.id === payment.transactionTypeId;
      });

      let bankAccount = props.bankAccount?.find((account) => {
        return account.id === payment.bankAccountId;
      });

      return {
        key: index,
        paymentId: payment.id,
        type: paymentType?.name,
        amount: payment.amount,
        status: payment.paymentStatusId,
        processor: payment.paymentProcessorId,
        settledDate: payment.settledDate,
        createdDate: payment.createdDate,
        originatedDate: payment.originatedDate,
        schedule: payment.scheduledPaymentNumber,
        account: bankAccount,
        lastUpdated: payment.lastUpdated,
        returnCode: payment?.errorMessage,
      };
    });


    //build the rows
    // * processed date is the originated date!
    payments.forEach((payment) => {
      let builtRow = {
        id: payment.key,
        paymentId: payment.paymentId,
        type: payment.type,
        amount: payment.amount,
        status: payment.status,
        processor: payment.processor,
        processedDate: payment.originatedDate,
        settledDate: payment.settledDate,
        createdDate: payment.createdDate,
        // originatedDate: payment.originatedDate,
        schedule: payment.schedule,
        account: payment.account,
        lastUpdated: payment.lastUpdated,
        returnCode: payment?.returnCode,
        rowMenu: payment,
      };
      _rows.push(builtRow);
    });
    setRows(_rows);
  };
  useEffect(() => {
    getGridCols()
    buildRows();
  }, [props.allPayments]);

  // This state object determines which columns are hidden on page load.  The default setting if the user has no past interactions is below.
  const [gridCols, setGridCols] = useState({})

  // Returns all filters from local storage or builds new
  const getGridCols = () => {
     let _gridCols = localStorage.getItem("paymentGridCols")
     if (_gridCols) {
      _gridCols = JSON.parse(_gridCols)
        setGridCols(_gridCols)
     } else {
        setGridCols({
          paymentId: true,
          type: true,
          amount: true,
          status: true,
          processor: true,
          processedDate: true,
          settledDate: true,
          createdDate: true,
          // originatedDate: payment.originatedDate,
          schedule: true,
          account: true,
          lastUpdated: true,
          returnCode: true,
          rowMenu: true,
        })
     }
  }

  // Updates local storage with current settings
  const saveGridCols = () => {
     localStorage.setItem('paymentGridCols', JSON.stringify(gridCols))
     getGridCols()
  }


  useEffect(() => {
    if (Object.keys(gridCols).length != 0){
      saveGridCols()}
  }, [JSON.stringify(gridCols)])

  const columns = [

    {
      field: "paymentId",
      headerName: "ID",
      minWidth: 74,
      flex: 1
      // hide: gridHideCols?.paymentId,
    },
    {
      field: "type",
      headerName: "Type",
      headerClassName: "left-padding",
      minWidth: 112,
      flex: 1
      // hide: gridHideCols?.type,
      // align: 'center',
      // headerClassName: 'hide-right-sep hide-left-sep',
      // renderCell: (params) => {
      //    // return <Box sx={{ backgroundColor: '#F0F0F0', borderRadius: '100px', fontWeight: 700, fontSize: 10, textAlign: 'center', fontFamily: 'Archivo', padding: '2px 13px' }}>1</Box>
      //    // console.log('renderparam',params)
      // }
    },
    {
      field: "amount",
      headerName: "Amount",
      headerClassName: "left-padding",
      // hide: gridHideCols?.amount,
      // headerClassName: ' hide-right-sep hide-left-sep',
      // renderCell: (params) => (
      //    <>
      //       <Box sx={{ height: 24, width: 24, backgroundColor: params.formattedValue === 2 ? 'text.secondary' : 'primary.main', color: 'white', borderRadius: '8px', display: 'flex', alignItems: 'center', justifyContent: 'center', marginRight: '16px' }}><Typography textAlign='center' variant='body1' fontWeight='600'>{params.formattedValue === 2 ? 'C' : 'O'}</Typography></Box>
      //       <Typography variant="overline">{params.formattedValue === 2 ? 'Closed' : 'Open'}</Typography>
      //    </>
      // ),
      // valueFormatter: ({ value }) => {
      //    switch (value) {
      //       case 'open':
      //          return 1;
      //          break;
      //       case 'closed':
      //          return 2;
      //          break;
      //    }
      // },
      // type: 'string',
      minWidth: 101,
      flex: 1,
      // align:'center',
      renderCell: (props) => {
        {
          apiRef.current = props.api;
        }
        return <>{UsdFormatterDec.format(props.formattedValue || "")}</>;
      },
    },
    {
      field: "status",
      headerName: "Status",
      headerClassName: "left-padding",
      minWidth: 115,
      maxWidth: 150,
      // hide: gridHideCols?.status,
      valueFormatter: (value) => (value?.value ? props?.paymentStatuses?.find((status) => status.id === value?.value)?.name : "No status info"),
      renderCell: (renderProps) => {
        return (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
              width: "100%",
            }}
          >
            <PaymentStatusChips
              paymentStatusId={renderProps?.value}
              allPayments={props.allPayments}
              paymentStatuses={props.paymentStatuses}
            />
          </Box>
        );
      },
    },
    {
      field: "account",
      headerName: "Account",
      headerClassName: "left-padding",
      // headerClassName: 'hide-right-sep hide-left-sep',
      minWidth: 100,
      flex: 1,
      // hide: gridHideCols?.account,
      // type:'date',
      valueFormatter: (value) => (value?.value?.accountNumber ? value?.value?.accountNumber?.slice(-4) : "No account info"), //format to display last 4 of account #
      renderCell: (props) => {
        //props.formattedValue = last 4 of account number
        if (props?.formattedValue) {
          let masked = `*${props?.formattedValue}`;
          return <>{masked}</>;
        } else return "No account info";
      },
    },
    {
      field: "processor",
      headerName: "Processor",
      headerClassName: "left-padding",
      minWidth: 130,
      flex: 1,
      valueFormatter: (value) =>('VCI'), // temporarily only using Vericheck so hardcoded, will have a method to get all processors once we have more than one
      renderCell: (props, params) => {
        return (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
              width: "100%",
            }}
          >
            <img src={vericheckLogo} />
          </Box>
        );
      },
    },
    {
      field: "createdDate",
      headerName: "Created",
      headerClassName: "left-padding",
      // headerClassName: 'hide-right-sep hide-left-sep',
      minWidth: 130,
      flex: 1,
      type: "date",
      // hide: gridHideCols?.createdDate,
      renderCell: (props, params) => {
        if (props.formattedValue) {
          let date = formatUTCToShortDate(props.formattedValue);
          if (date.slice(-4) === "9999") {
            return "N/A";
          } else return date;
        } else return "N/A";
      },
    },
    {
      field: "processedDate",
      headerName: "Processed",
      headerClassName: "left-padding",
      // headerClassName: 'hide-right-sep hide-left-sep',
      minWidth: 130,
      flex: 1,
      type: "date",
      // hide: gridHideCols?.processedDate,
      renderCell: (props, params) => {
        if (props.formattedValue) {
          let date = formatUTCToShortDate(props.formattedValue);
          if (date.slice(-4) === "9999") {
            return "N/A";
          } else return date;
        } else return "N/A";
      },
    },

    {
      field: "schedule",
      headerName: "Schedule",
      headerClassName: "left-padding",
      // headerClassName: 'hide-right-sep hide-left-sep',
      minWidth: 130,
      flex: 1,
      // hide: gridHideCols?.schedule,
      renderCell: (props, params) => {
        //check if schedule exists (should be either 1 or 2 for 1st or 2nd payment)
        if (props.formattedValue === 1) {
          return "1st payment";
        } else if (props.formattedValue === 2) {
          return "2nd payment";
        } else {
          return "";
        }
      },
    },

    {
      field: "settledDate",
      headerName: "Settled",
      headerClassName: "left-padding",
      // headerClassName: 'hide-right-sep hide-left-sep',
      minWidth: 130,
      flex: 1,
      type: "date",
      // hide: gridHideCols?.settledDate,
      renderCell: (props, params) => {
        if (props.formattedValue) {
          let date = formatUTCToShortDate(props.formattedValue);
          if (date.slice(-4) === "9999") {
            return "N/A";
          } else return date;
        } else return "N/A";
      },
    },
    {
      field: "lastUpdated",
      headerName: "Last Updated",
      headerClassName: "left-padding",
      // headerClassName: 'hide-right-sep hide-left-sep',
      minWidth: 130,
      flex: 1,
      type: "date",
      // hide: gridHideCols?.lastUpdated,
      renderCell: (props, params) => {
        if (props.formattedValue) {
          let date = formatUTCToShortDate(props.formattedValue);
          if (date.slice(-4) === "9999") {
            return "N/A";
          } else return date;
        } else return "N/A";
      },
    },
    {
      field: "returnCode",
      headerName: "Return Code",
      headerClassName: "left-padding",
      minWidth: 130,
      flex: 1,
      // hide: gridHideCols?.returnCode,
    },
    {
      field: "rowMenu",
      headerName: "",
      headerClassName: "left-padding",
      // headerClassName: 'hide-right-sep hide-left-sep',
      width: 20,
      // hide: gridHideCols?.rowMenu,
      valueFormatter: ({ value }) => value,
      renderCell: (props) => {

        //must use the payment obj selected saved as state upon menu click
        let paymentStatusId = props.formattedValue.status;
        let paymentType = props.formattedValue.type; 
        let reRunValue = props.formattedValue?.amount;

        //only show edit options if agent has permissions
        if (allowEdit) {
          return (
            <>
              <IconButton
                onClick={(e) => handleOpenRowMenu(e, props.formattedValue)}
                value={props.formattedValue.paymentId}
              >
                <MoreVertIcon
                  sx={{
                    color: "text.secondary",
                    float: "right",
                  }}
                />
              </IconButton>
              {/* temporarily only showing entire menu IF payment is in error, return, or NSF status for rerun option, will remove this later */}
             {(paymentStatusId === 2 || paymentStatusId === 8 || paymentStatusId === 9 ) && (
             <Menu
                id="cancel-one-time-payment"
                value={props.formattedValue?.paymentId}
                anchorEl={anchorEl?.[props.formattedValue.paymentId]}
                open={Boolean(anchorEl?.[props.formattedValue.paymentId])}
                onClose={handleCloseRowMenu}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
              >
                {/* Only show refund option if the transaction type is a 'payment' and the status is settled (status id 4) */}
                {/* {paymentType === "Payment" && paymentStatusId === 4 && (
                  <MenuItem value={"refund"} 
                  onClick={handleOpenDrawer}>
                    Refund
                  </MenuItem>
                )} */}

                {/* Can void a payment, payout, refund, or prenote. Only can void if the transaction has an accepted status (status id 1) */}
                {/* {paymentStatusId === 1 && (
                  <MenuItem value={"void"} onClick={handleOpenDrawer}>
                    Void
                  </MenuItem>
                )} */}
                {/* re-run a failed payment (status of error, return, or NSF )- open make a payment drawer, check that oneTimePayment for this amount does not already exist */}
                {(paymentStatusId === 2 || paymentStatusId === 8 || paymentStatusId === 9 ) && (
                  <MenuItem value={"void"} onClick={(e)=>{ 
                    handleOpenMakePaymentDrawer(e, reRunValue)
                    setAnchorEl({});
                    }}>
                    Rerun payment
                  </MenuItem>
                )}
                {/* <MenuItem
                  // value={}
                  disabled={true}
                  onClick={() => console.log("send receipt")}
                >
                  Send Receipt
                </MenuItem> */}
              </Menu>)}
            </>
          );
        } else return <></>;
      },
    },
    // {
    //    field: 'uniqueId',
    //    headerName: 'Ticket ID',
    //    headerClassName: 'hide-right-sep hide-left-sep',
    //    renderCell: (params) => {
    //       return `${params.row.id}`
    //    },
    //    width: 80
    // },
    // {
    //    field: 'assignee',
    //    headerName: 'Assignee',
    //    headerClassName: 'center-grid-header hide-right-sep hide-left-sep',
    //    width: 220,
    //    renderCell: (params) => {
    //       return (
    //          <>
    //             {params.value &&
    //                <>
    //                   <Avatar sx={{ bgcolor: "#195FFB17", color: "#195FFB", fontFamily: "Archivo", fontWeight: "600", width: 40, height: 40, fontSize: 18 }} alt={`${allUsers && allUsers?.find(user => user.id == params.value)?.firstName} ${allUsers && allUsers?.find(user => user.id == params.value)?.lastName}`} src={`api/image/user/${params.value}`} />
    //                   <Typography sx={{ paddingLeft: '10px' }}>
    //                      <span style={{display:'inline-block', lineBreak:'anywhere'}}>
    //                         {allUsers && allUsers?.find(user => user.id == params.value)?.firstName}&nbsp;
    //                      </span>

    //                      <span style={{display:'inline-block',lineBreak:'anywhere'}}>
    //                         {allUsers && allUsers?.find(user => user.id == params.value)?.lastName}
    //                      </span>
    //                   </Typography>
    //                </>
    //             }
    //          </>
    //       )
    //    }
    // },
  ];

  //THIS OVERRIDES THE NO ROWS DATAGRID COMPONENT
  function NoTicketRows(props) {
    return (
      <Box sx={{ backgroundColor: "", height: "100%", textAlign: "center" }}>
        <Box
          sx={{
            margin: "54px auto 13px",
            width: "58px",
            height: "58px",
            backgroundColor: "#195FFB12",
            borderRadius: 100,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {/* <ManageSearchIcon sx={{ width: '28px', height: '28px', color: '#195FFB' }} /> */}
        </Box>
        <Box sx={{ marginBottom: "19px" }}>
          No tickets currently assigned here
        </Box>

        <Box>
          {/* <Button variant='contained' disableRipple
                  sx={{height:40,minWidth:200,borderRadius:100,width:'152px',fontWeight:'500!important'}}
                  onClick={()=>setTicketDrawerOpen(true)}
                  // startIcon={<AddIcon/>}
                  >
                     Open New Ticket
                  </Button> */}
        </Box>
      </Box>
    );
  }

  //THIS OVERRIDES THE NO RESULTS DATAGRID COMPONENT
  function NoTicketResults(props) {
    return (
      <Box sx={{ backgroundColor: "", height: "100%", textAlign: "center" }}>
        <Box
          sx={{
            margin: "54px auto 13px",
            width: "58px",
            height: "58px",
            backgroundColor: "#195FFB12",
            borderRadius: 100,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {/* <ManageSearchIcon sx={{ width: '28px', height: '28px', color: '#195FFB' }} /> */}
        </Box>
        <Box sx={{ marginBottom: "19px" }}>No tickets found</Box>

        <Box>
          {/* <Button variant='contained' disableRipple
                  sx={{height:40,minWidth:200,borderRadius:100,width:'152px',fontWeight:'500!important'}}
                  onClick={()=>setTicketDrawerOpen(true)}
                  // startIcon={<AddIcon/>}
                  >
                     Open New Ticket
                  </Button> */}
        </Box>
      </Box>
    );
  }

  const handleRowClick = async (e) => {
    // console.log(e, e.id, e.row.id)
    // history.push(`../tickets/${e.row.id}`)
    // let thing = await paymentsService.getAllPaymentsByMemberId(props.member?.id)
    //I'm giving you Ted Lasso as your tester
    // int memberId = 1452879;
    // let thing = await paymentsService.getPaymentBalanceByMemberId(1975)
    // let thing = await paymentsService.getAllPaymentsByMemberId(1975)
    // console.log(thing)
  };

  const [selectedRows, setSelectedRows] = useState([]);
  const handleCheckboxClick = (e) => {
    let selectedArray = e;
    setSelectedRows(selectedArray);
  };

  const gridStateChangeHandler = (e) => {
    let gridState = e;
    // console.log(gridState)
  };
  const exportSelectedRows = (e) => {
    console.log(selectedRows, apiRef);
    if (apiRef.current) {
      // let csv = apiRef.current.getDataAsCsv()
      let csv = apiRef.current.exportDataAsCsv();
      console.log(csv);
    }
  };

  const [filterModel, setFilterModel] = useState({
    items: [],
    linkOperator: "and",
    quickFilterLogicOperator: "and",
    quickFilterValues: []
 })

 //logic to determine if the next payment chip should be displayed based on current payment schedules, dates,  if they are disabled/enabled, and if coverage is active (calculatedNextOccurrence < endPaymentDate)
 let displayNextPaymentChip = props?.paymentSchedule?.length > 0 && 
  ((
    (props?.paymentSchedule[0]?.calculatedNextOccurrence &&
      props?.paymentSchedule[0]?.calculatedNextOccurrence <
        props?.paymentSchedule[0]?.endPaymentDate) &&
      props?.paymentSchedule[0]?.isEnabled) ||
      (props?.paymentSchedule[1]?.calculatedNextOccurrence &&
        props?.paymentSchedule[1]?.calculatedNextOccurrence <
        props?.paymentSchedule[1]?.endPaymentDate &&
        props?.paymentSchedule[1]?.isEnabled) ||
      (props?.paymentSchedule[1]?.calculatedNextOccurrence &&
        (props?.paymentSchedule[0]?.calculatedNextOccurrence <
          props?.paymentSchedule[0]?.endPaymentDate) &&
          (props?.paymentSchedule[1]?.calculatedNextOccurrence <
            props?.paymentSchedule[1]?.endPaymentDate) 
    ));


  return (
    <Paper
      className=""
      elevation={0}
      sx={componentStyles.componentBody}
    >
      <RefundOrVoidDrawer
        member={member}
        getAllPayments={getAllPayments}
        action={action}
        setAction={setAction}
        refundOrVoidDrawerOpen={refundOrVoidDrawerOpen}
        setRefundOrVoidDrawerOpen={setRefundOrVoidDrawerOpen}
        refundOrVoidAccountSelected={refundOrVoidAccountSelected}
        setRefundOrVoidAccountSelected={setRefundOrVoidAccountSelected}
        UsdFormatterDec={UsdFormatterDec}
      />
      <Typography variant="h6" sx={componentStyles.headerTitle}>
        Payments
      </Typography>

      <Box sx={componentStyles.headerContainer}>
          <Typography
            sx={componentStyles.headerDescription}
          >
            View upcoming payments, history, and events
          </Typography>

          {/* display NEXT PAYMENT CHIP date IF member on PeakPay, this schedule is not disabled, and IF IT IS THE CURRENT SCHEDULE - do not display if the schedule is upcoming/not yet active*/}
          {displayNextPaymentChip && props?.paymentSchedule[0]?.isPeakPay && (
            <NextPaymentChip paymentSchedule={props.paymentSchedule}/>
          )} 
          {/* display NON PEAK PAY CHIP if member is enrolleld (valid pay schedule) but is not on PeakPay */}
          {(!props?.paymentSchedule[0]?.isPeakPay && props?.memberHasActiveCoverage) ? (
            <NonPeakPayChip/>
          ) : ('')}
          
      </Box>

      <Box sx={componentStyles.historyGridContainer}>
        <Box
          sx={componentStyles.gridTitle(props?.paymentSchedule[0]?.isPeakPay && props?.memberHasActiveCoverage)}
          role="button"
        >
          HISTORY
        </Box>

        {/* History Grid display if member is on PeakPay and have valid pay schedule OR if they have history of previous payments even if they are not currently in active coverage*/}
        {((props?.paymentSchedule[0]?.isPeakPay && props?.paymentSchedule[0]?.id > 0 ) || props?.allPayments?.length > 0) && (
          <OurDataGrid
          // autoPageSize
          apiRef={apiRef}
          checkboxSelection
          manualHeight
          rows={rows}
          columns={columns}
          columnVisibilityModel={gridCols}
          setGridCols={setGridCols}
          handleRowClick={handleRowClick}
          handleCheckboxClick={handleCheckboxClick}
          gridStateChangeHandler={gridStateChangeHandler}
          manualStyle={{ borderRadius: "0px" }}
          filterModel={filterModel}
          setFilterModel={setFilterModel}
          showSaveFilterModel={false}
          // noRowsComp={NoTicketRows} noResultsComp={NoTicketResults}
          // components={{
          //    // NoRowsOverlay: NoTicketRows
          // }}
        />
        )}

        {/* Display if member is NOT on PeakPay but has valid payment schedule/ is enrolled */}
        {(!props?.paymentSchedule[0]?.isPeakPay && props?.memberHasActiveCoverage) ? 
          (
          <Box sx={componentStyles.placeHolderMessageContainer}>
              <PlaceHolderMessage type={'noPeakPay'}/>
          </Box>
          ) : (
            ''
          )}

        {/* Display if member is NOT enrolled and has no previous payment history*/}
        {!props?.memberHasActiveCoverage && props?.allPayments?.length < 1 ? (
        
          <Box sx={componentStyles.placeHolderMessageContainer}>
              <PlaceHolderMessage type={'noActiveCoverage'}/>
          </Box>) : ('')}
    
        <Box>
          <Button
            sx={componentStyles.exportButton}
            disabled={selectedRows.length <= 0}
            onClick={exportSelectedRows}
          >
            
              <DownloadIcon fontSize="small" />
  
            <Typography >EXPORT</Typography>
          </Button>
        </Box>
      </Box>
    </Paper>
  );
}
