import { Button } from '@mui/material';

export default function AgentNavigation(props) {
  const backClickHandler = () => {
    props.setAgentEnrollmentPageState({ [props.lastLocation]: true });
  };

  return (
    <>
      <div
        className="flex-row"
        style={{
          justifyContent: 'flex-end',
          marginTop: '20px',
          marginBottom: '10px',
          gap: '8px',
        }}
      >
        {props.allowBack && (
          <Button
            onClick={backClickHandler}
            sx={{ height: 50 }}
            variant="outlined"
          >
            Back
          </Button>
        )}

        {/* Next Button disabled IF validations not met on member self enrollment OR on agent side if LADD selected w/ NO bennies added */}

        <Button
          // onClick={(e) => {
          //   props.nextClickHandler(props.nextLocation);
          // }}
          //   onClick={() => {
          //     if (!props?.notActivelyWorking){
          //        props.nextClickHandler(props.nextLocation)
          //     } else {
          //        if (props.handleNawExit) props.handleNawExit()
          //     }
          //  }}
          onClick={() => props.nextClickHandler(props.nextLocation)}
          sx={{ height: 50 }}
          variant="contained"
          disabled={
            props.nextValidation
              ? props.nextValidation
              : false ||
                (props.primaryBeneficiaries?.length < 1 && !props.addedBenny) ||
                props.primaryTotalError
          }
        >
          {/* {(props?.notActivelyWorking && props?.nextLocation) == 'payment' ? 'Save & Exit' : 'Next'} */}
          Next
        </Button>
      </div>
    </>
  );
}
