import React, { useEffect, useState, useContext } from "react"
//Assets
//Services
//Mui icons
import ManageSearchIcon from "@mui/icons-material/ManageSearch"
//Mui Components
import { Box } from "@mui/system"
import { Divider, Paper, Typography } from "@mui/material"

//Our Components
import EnrollmentSummaryCard from "./summary-cards/EnrollmentSummaryCard"
import PlanSummaryCard from "./summary-cards/PlanSummaryCard"
import SignatureModal from "../../../../../app-external/external-enrollments/signature-modal/SignatureModal"
import NonPeakPayChip from "../../../DisplayChips/NonPeakPayChip"
//Our components for R-side of plans tab
import MemberPlanPaymentSummary from "./plan-tab-left-side/MemberPlanPaymentSummary"
import MemberPlanPaymentSchedule from "./plan-tab-left-side/MemberPlanPaymentSchedule"
import MemberPlanDocuments from "./plan-tab-left-side/MemberPlanDocuments"
import PaymentHealthChips from "../../../DisplayChips/PaymentHealthChips"

const componentStyles = {
  divider: {
    width: "98%",
    height: "1px",
    margin: "0 auto",
    color: "background.border",
    opacity: 0.6,
  },
}

export default function MemberPlansTab(props) {
  const [permissionsEnabler, setPermissionsEnabler] = useState({})
  useEffect(() => {
    if (props.permissionsEnabler) {
      setPermissionsEnabler(props.permissionsEnabler)
    }
  }, [props.permissionsEnabler])
  const [currentEdit, setCurrentEdit] = useState({})
  let planCosts = props?.planCosts
  const [paymentSchedule, setPaymentSchedule] = useState()
  useEffect(() => {
    setPaymentSchedule(props.paymentSchedule)
  }, [props?.paymentSchedule])
  //make sure a user can edit a wallet from the plans tab
  useEffect(() => {
    props.setEditWalletFromPlans(true)
  }, [])
  //format date to be used in other components throughout plans summary
  const formatDate = (rawDate) => {
    // console.log('formatDate',rawDate)
    // if (rawDate?.length < 11) rawDate = new Date(rawDate).toISOString();
    let [yyyy, mm, dd, hh, m, s] = rawDate.split(/[^\d]+/)
    let date = new Date()
    date.setUTCFullYear(+yyyy)
    date.setUTCDate(+dd)
    date.setUTCMonth(mm - 1)
    date.setUTCHours(+hh)
    date.setUTCMinutes(+m + date.getTimezoneOffset())
    date.setUTCSeconds(+s)
    // console.log('formatDate after',date)
    return date
  }
  //SigModal
  // State starts closed
  const [openEnrollment, setOpenEnrollment] = useState(false)
  const [modalConfig, setModalConfig] = useState("")

  const handleEnrollmentClick = (
    enrollmentStateId,
    cancelEnrollment = false
  ) => {
    if (enrollmentStateId == 2) {
      if (cancelEnrollment) {
        //Show sig modal for cancel
        setModalConfig("cancelEnrollment")
        setSigModalOpen(true)
        //  setEnrollmentMenu(null)
      } else {
        //Show sig modal for reopen
        setModalConfig("Reopen")
        setSigModalOpen(true)
        //  setEnrollmentMenu(null)
      }
    } else if (enrollmentStateId == 3) {
      setSigModalOpen(true)
      setModalConfig("Cancel")
      //  setEnrollmentMenu(null)
    } else {
      setOpenEnrollment(true)
      //  setTabValue(4)
    }
  }
  const [sigModalOpen, setSigModalOpen] = useState(false)
  console.log("mem PLANS TAB props", props)

  //PAY CHIP DISPLAY
  const [payChipDisplay, setPayChipDisplay] = useState("")

  const handlePayChipDisplay = () => {
    console.log('running handlePayChipDisplay')
    if(props?.paymentSchedule?.length < 1 && !props?.topOutstandingBanance >= 0) return ""
    //display health chip if member has active covrage and is on peak pay
    if (
      props?.currentActiveCoverage?.length > 0 &&
      props?.paymentSchedule[0]?.id > 0 &&
      props?.paymentSchedule[0]?.isPeakPay
    ) {
      return (
        <PaymentHealthChips paymentHealthStatus={props.topOutstandingBalance} />
      )
      //display not on peak pay chip if member has active coverage and is not on peak pay
    } else if (
      !props?.paymentSchedule[0]?.isPeakPay &&
      props?.currentActiveCoverage?.length > 0
    ) {
      return <NonPeakPayChip />
    }
  }

  //REFRESH DISPLAY AS DATA IS LOADED
  useEffect(() => {
    let _display = handlePayChipDisplay()
    setPayChipDisplay(_display)
  }, [props?.currentActiveCoverage?.length, props?.paymentSchedule?.length, props?.topOutstandingBalance])

  return (
    <Box
      className="member-router-container"
      sx={{
        height: "calc(100vh - 104px)",
        position: "relative",
        bgcolor: "background.background",
        color: "text.primary",
      }}
    >
      {props.availableEnrollments?.length < 1 &&
      props?.activeCoverage?.length < 1 ? (
        //No Plan Info Message
        <Box
          sx={{
            backgroundColor: "",
            height: "80%",
            textAlign: "center",
            paddingTop: "20px",
          }}
        >
          <Box
            sx={{
              margin: "0 auto",
              width: "58px",
              height: "58px",
              backgroundColor: "#195FFB12",
              borderRadius: 100,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <ManageSearchIcon
              sx={{ width: "28px", height: "28px", color: "#195FFB" }}
            />
          </Box>
          <Box sx={{ marginBottom: "19px" }}>
            Member does not currently have plan information
          </Box>
        </Box>
      ) : (
        //Display existing plan info
        <Paper
          elevation={0}
          sx={{
            height: "100% ",
            border: "0px solid",
            borderRadius: "0px",
            borderColor: "background.border",
            backgroundColor: "background.default",
          }}
        >
          <Box
            className="flex-row"
            sx={{
              flexWrap: "wrap",
              borderBottom: "0px solid",
              borderColor: "background.border",
              height: "95px",
              padding: "17px 9px 8px",
            }}
          >
            <Box className="col-12">
              <Typography variant="h4">
                All Plans
                <Box
                  sx={{
                    display: props.permissionsEnabler?.viewPayments
                      ? "inline"
                      : "none",
                    marginLeft: "10px",
                  }}
                >
                  {payChipDisplay}
                </Box>
              </Typography>
            </Box>
            <Box
              className="col-12"
              sx={{
                fontFamily: "Archivo",
                fontWeight: 400,
                fontSie: "16px",
                lineHeight: "28px",
                letter: ".15px",
              }}
            >
              Overview of all plans that a member is participating in
            </Box>
          </Box>

          <Divider sx={componentStyles.divider} />

          <Box className="flex-row">
            {/* PLAN INFO */}
            <Box
              className="col-7"
              sx={{
                borderRight: "1px solid",
                borderColor: "background.border",
                height: "calc(100vh - 213px)",
                overflowX: "hidden",
                overflowY: "auto",
              }}
            >
              {/* OPEN ENROLLMENTS */}
              {props?.availableEnrollments?.length > 0 && (
                <>
                  <Box
                    className="flex-row"
                    sx={{
                      flexWrap: "wrap",
                      padding: "16px 16px 0px",
                      borderBottom: "0px solid red",
                    }}
                  >
                    <Box className="col-12">
                      <Typography
                        sx={{
                          fontFamily: "Poppins",
                          fontWeight: 500,
                          fontSize: "20px",
                          lineHeight: "32px",
                          letterSpacing: ".15px",
                          color: "light",
                        }}
                      >
                        IN OPEN-ENROLLMENT
                      </Typography>
                    </Box>
                    <Box className="col-12">
                      <Typography
                        sx={{
                          fontFamily: "Archivo",
                          fontWeight: 400,
                          fontSie: "16px",
                          lineHeight: "28px",
                          letter: ".15px",
                          color: "light",
                        }}
                      >
                        View applications that are in open-enrollment or
                        in-progress
                      </Typography>
                    </Box>
                  </Box>
                  {props.availableEnrollments?.length > 0 ? (
                    <>
                      {props.availableEnrollments.map((enrollment) => (
                        <EnrollmentSummaryCard
                          member={props.member}
                          UsdFormatterDec={props.UsdFormatterDec}
                          singleEnrollment={enrollment}
                          planCosts={planCosts}
                          paymentSchedule={paymentSchedule}
                          enrollmentStates={props.enrollmentStates}
                          dependents={props.dependents}
                          spouse={props.spouse}
                          relationshipTypes={props.relationshipTypes}
                          setSelectedEnrollment={props.setSelectedEnrollment}
                          setActivePage={props.setActivePage}
                          handleEnrollmentClick={handleEnrollmentClick}
                          documents={props.documents}
                          getAllDocuments={props.getAllDocuments}
                          formatDate={formatDate}
                          updateSinglePlan={props.updateSinglePlan}
                          cancellationReasons={props.cancellationReasons}
                        />
                      ))}
                    </>
                  ) : (
                    <Box
                      className="flex-row"
                      sx={{ padding: "8px 18px", flexWrap: "wrap" }}
                    >
                      No Open Enrollments
                    </Box>
                  )}
                  <Divider sx={componentStyles.divider} />
                  {/* Active Coverages */}
                </>
              )}
              <Box
                className="flex-row"
                sx={{
                  flexWrap: "wrap",
                  padding: "16px 16px 0px",
                  borderBottom: "0px",
                }}
              >
                <Box className="col-12">
                  <Typography
                    sx={{
                      fontFamily: "Poppins",
                      fontWeight: 500,
                      fontSize: "20px",
                      lineHeight: "32px",
                      letterSpacing: ".15px",
                      color: "light",
                    }}
                  >
                    Active Coverage
                  </Typography>
                </Box>
                <Box className="col-12">
                  <Typography
                    sx={{
                      fontFamily: "Archivo",
                      fontWeight: 400,
                      fontSie: "16px",
                      lineHeight: "28px",
                      letter: ".15px",
                      color: "text.gray",
                    }}
                  >
                    View current plan coverage
                  </Typography>
                </Box>
              </Box>
              <hr sx={{ margin: "20px 24px", color: "text.secondary" }} />
              {props?.currentActiveCoverage?.length > 0 ? (
                <>
                  {props?.currentActiveCoverage.map((coverage) => (
                    <PlanSummaryCard
                      key={coverage?.enrollmentId}
                      member={props.member}
                      currentActiveCoverage={coverage}
                      getActiveCoverage={props.getActiveCoverage}
                      UsdFormatterDec={props.UsdFormatterDec}
                      planCosts={planCosts}
                      paymentSchedule={paymentSchedule}
                      enrollmentStates={props.enrollmentStates}
                      relationshipTypes={props.relationshipTypes}
                      dependents={props.dependents}
                      spouse={props.spouse}
                      allowModify={permissionsEnabler.modifyEnrollment}
                      allowEditEnrollment={permissionsEnabler.editEnrollment}
                      documents={props.documents}
                      getAllDocuments={props.getAllDocuments}
                      cancellationReasons={props.cancellationReasons}
                      getPaymentSchedule={props.getPaymentSchedule}
                      getMemberHistory={props.getMemberHistory}
                      formatDate={formatDate}
                      getEnrollments={props.getEnrollments}
                      handleActivePageChange={props?.handleActivePageChange}
                    />
                  ))}
                </>
              ) : (
                <Box
                  sx={{
                    display: "flex",
                    padding: "8px 18px",
                    flexWrap: "wrap",
                  }}
                >
                  <Typography>No Active Coverages</Typography>
                </Box>
              )}
            </Box>
            {/* RIGHT SIDE - PAYMENT INFO */}
            <Box
              className="col-5"
              sx={{
                height: "calc(100vh - 213px)",
                overflowX: "hidden",
                overflowY: "auto",
              }}
            >
              <MemberPlanPaymentSummary
                totalPlanCosts={props.totalPlanCosts}
                paymentSchedule={props.paymentSchedule}
                UsdFormatterDec={props.UsdFormatterDec}
                paymentSchedule={props.paymentSchedule}
              />
              <MemberPlanPaymentSchedule
                member={props.member}
                UsdFormatterDec={props.UsdFormatterDec}
                bankAccount={props.bankAccount}
                paymentSchedule={props.paymentSchedule}
                paymentFrequencies={props.paymentFrequencies}
                walletService={props.walletService}
                getPaymentSchedule={props.getPaymentSchedule}
                getBankAccount={props.getBankAccount}
                permissionsEnabler={permissionsEnabler}
                setBankAccount={props.setBankAccount}
                bankAccountTypes={props.bankAccountTypes}
                editWalletFromPlans={props.editWalletFromPlans}
                currentActiveCoverage={props.currentActiveCoverage}
              />
              <MemberPlanDocuments
                member={props.member}
                permissionsEnabler={permissionsEnabler}
                documents={props.documents}
                getAllDocuments={props.getAllDocuments}
                attachmentTypes={props.attachmentTypes}
              />
            </Box>
          </Box>
        </Paper>
      )}
      <SignatureModal
        agentEnroller={true}
        setAgentEnrollmentPageState={props.setAgentEnrollmentPageState}
        fullMemberObj={props.member}
        guid={props.guid}
        setGuid={props.setGuid}
        results={props.singleEnrollment}
        open={sigModalOpen}
        handleClose={() => setSigModalOpen(false)}
        handleOpen={() => setSigModalOpen(false)}
        setEnrollmentSelected={props.setSelectedEnrollment}
        signClick={modalConfig}
        setOpenEnrollment={setOpenEnrollment}
        // setTabValue={setTabValue}
        enrollmentId={props?.singleEnrollment?.enrollmentId}
        setSigModalOpen={setSigModalOpen}
        // displayPay={displayPay}
        refreshEnrollments={props.getEnrollments}
        refreshEnrollment={props.getOneEnrollment}
        setActivePage={props.setActivePage}
      />
    </Box>
  )
}
