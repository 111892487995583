import React from 'react';

//MUI Components
import { Box, Typography } from '@mui/material';

//Assets
import FullColorBlackText from '../../../assets/images/FullColorBlackText.svg';

//component styles
const componentStyles = {
  componentContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: 800,
    width: '100%',
    minHeight: '50px',
  },
  text: {
    margin: 'auto',
    fontWeight: 800,
    alignItems: 'center',
    fontSize: '10px!important',
  },
};

export default function PoweredBy(props) {
  return (
    <>
      <Box sx={componentStyles.componentContainer}>
        <Typography sx={componentStyles.text}>
          POWERED BY &nbsp;{' '}
          <img
            style={{ height: '21px' }}
            src={FullColorBlackText}
            alt="UnionHub logo"
          />
        </Typography>
      </Box>
    </>
  );
}
