import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

//Assets
import FullColorBlackText from '../../../../assets/images/FullColorBlackText.svg';

//Services
import enrollmentService from '../../../../services/enrollment-service';
import optionService from '../../../../services/optionService';

//Mui Icons
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';

//MuiComponents
import {
  Box,
  Button,
  Checkbox,
  Collapse,
  Divider,
  InputAdornment,
  InputLabel,
  FormGroup,
  FormControlLabel,
  Fade,
  Link,
  MenuItem,
  Select,
  Switch,
  Tooltip,
  TextField,
  Typography,
} from '@mui/material';

//MuiX
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

//Our components
import StickyProgressTotal from '../StickyProgressTotal';

import { es } from 'date-fns/locale';
import PoweredBy from '../PoweredBy';
import AgentNavigation from '../../../app-internal/Member/member-profile/MemberTabs/agent-enroll-tab/AgentNavigation';
import ExternalEnrollmentHeader from '../ExternalEnrollmentHeader';
import { set } from 'date-fns';

//utils
import { checkMobileDevice } from '../../../utils';

export default function EnrollmentBeneficiaries(props) {
  const isMobileDevice = checkMobileDevice(); //render datepicker vs. textfield for dob input based on device
  let history = useHistory();
  let percentFormatter = new Intl.NumberFormat('en-US', {
    style: 'percent',
    maximumFractionDigits: 2,
  });

  //GET initial bennies every time component mounts
  useEffect(() => {
    props?.getInitialBeneficiaries();
  }, []);

  const [relationshipTypes, setRelationshipTypes] = useState();
  const [relationshipTypeListForEdit, setRelationshipTypeListForEdit] =
    useState(null);

  const getRelationshipTypes = async () => {
    let _relationshipTypes = await optionService.getRelationshipTypes();
    // console.log(_relationshipTypes)
    //if a potential benny, primary benny, or contingent benny is already a spouse, do not give the option of adding another pouse
    let potentialSpouseExists = props?.potentialBeneficiaries?.find((benny) => {
      return benny.relationshipTypeId === 1;
    });
    let spouseExistsAsPrimary = props?.primaryBeneficiaries?.find((benny) => {
      return benny.relationshipTypeId === 1;
    });
    let spouseExistsAsContingent = props?.contingentBeneficiaries?.find(
      (benny) => {
        return benny.relationshipTypeId === 1;
      }
    );
    if (
      potentialSpouseExists ||
      spouseExistsAsPrimary ||
      spouseExistsAsContingent
    ) {
      let filteredRelationshipTypes = _relationshipTypes.filter(
        (rt) => rt.id !== 1
      );

      setRelationshipTypes(filteredRelationshipTypes);
    } else setRelationshipTypes(_relationshipTypes);

    //set full relationship type list for edit beneficiary
    setRelationshipTypeListForEdit(_relationshipTypes);
  };

  useEffect(() => {
    // props.getInitialBeneficiaries()
    getRelationshipTypes();
    // DEBUGCLICK()
    // checkHeight()
  }, [
    props?.primaryBeneficiaries?.length,
    props?.contingentBeneficiaries?.length,
  ]);

  const [expanded, setExpanded] = useState(true);
  const handleExpandClick = () => {
    setExpanded((prev) => !prev);
  };
  let expandIcon = expanded ? (
    <RemoveIcon
      fontSize="large"
      sx={{
        position: 'absolute',
        right: '14%',
        top: '20%',
        color: 'white',
        backgroundColor: '#195ffb',
        borderRadius: '100px',
        boxShadow: '-1px 1px 4px 0px #00000040',
      }}
    />
  ) : (
    <AddIcon
      fontSize="large"
      sx={{
        position: 'absolute',
        right: '14%',
        top: '20%',
        color: '#195ffb',
        borderRadius: '100px',
        boxShadow: '-1px 1px 4px 0px #00000040',
      }}
    />
  );

  const [contingentExpanded, setContingentExpanded] = useState(false);

  const handleContingentExpandClick = () => {
    setContingentExpanded((prev) => !prev);
  };
  let contingentExpandIcon = contingentExpanded ? (
    <RemoveIcon
      fontSize="large"
      sx={{
        position: 'absolute',
        right: '14%',
        top: '20%',
        color: 'white',
        backgroundColor: '#195ffb',
        borderRadius: '100px',
        boxShadow: '-1px 1px 4px 0px #00000040',
      }}
    />
  ) : (
    <AddIcon
      fontSize="large"
      sx={{
        position: 'absolute',
        right: '14%',
        top: '20%',
        color: '#195ffb',
        borderRadius: '100px',
        boxShadow: '-1px 1px 4px 0px #00000040',
      }}
    />
  );

  //   const [autoAllocate, setAutoAllocate] = useState(true);
  //   const [contingentAutoAllocate, setContingentAutoAllocate] = useState(true);

  const [disableSavePrimary, setDisableSavePrimary] = useState(true);
  let primaryAddArray = [
    props.primaryBennyAdd.FirstName,
    props.primaryBennyAdd.LastName,
    props.primaryBennyAdd.RelationshipTypeId,
  ];

  const handleInputChange = (e) => {
    let val = e.target.value;
    let key = e.target.getAttribute('data-update');
    let valWithoutNumbers = val.replace(/[0-9]/g, ''); // Remove any numbers

    //ADD the new benny info to the primaryBennyAdd object IF the user is not editing an existing benny
    //first name primary add
    if (key === 'firstNamePrimary' && !editBenny.id) {
      props.setPrimaryBennyAdd({
        ...props.primaryBennyAdd,
        FirstName: valWithoutNumbers,
      });
    } else if (
      (key === 'firstNamePrimary' || key === 'firstNameContingent') &&
      editBenny.id
    ) {
      //first name primary or cont. edit
      setEditBenny({
        ...editBenny,
        firstName: valWithoutNumbers,
      });
    }
    //last name primary add
    if (key === 'lastNamePrimary' && !editBenny.id) {
      props.setPrimaryBennyAdd({
        ...props.primaryBennyAdd,
        LastName: valWithoutNumbers,
      });
    } else if (
      (key === 'lastNamePrimary' || key === 'lastNameContingent') &&
      editBenny.id
    ) {
      //last name primary or cont. edit
      setEditBenny({
        ...editBenny,
        lastName: valWithoutNumbers,
      });
    }

    // first name contingent add
    if (key === 'firstNameContingent' && !editBenny.id) {
      props.setContingentBennyAdd({
        ...props.contingentBennyAdd,
        FirstName: valWithoutNumbers,
      });
    }
    //last name contingent add
    if (key === 'lastNameContingent' && !editBenny.id) {
      props.setContingentBennyAdd({
        ...props.contingentBennyAdd,
        LastName: valWithoutNumbers,
      });
    }
  };

  useEffect(() => {
    if (props.primaryBennyAdd) {
      let enableSave =
        props.primaryBennyAdd.FirstName?.length > 0 &&
        props.primaryBennyAdd.LastName?.length > 0 &&
        props.primaryBennyAdd.RelationshipTypeId > 0;
      setDisableSavePrimary(!enableSave);
    }
  }, [...primaryAddArray]);

  const handleAddPrimaryBeneficiary = async (e) => {
    setAddedBenny(true);
    let _primaryBeneficiaries = props.primaryBeneficiaries?.map((x) => x);
    let _primaryCheckboxes = props.primaryCheckboxes?.map((x) => x);

    let insertBenny = {
      FirstName: props.primaryBennyAdd.FirstName,
      MiddleInitial: '',
      LastName: props.primaryBennyAdd.LastName,
      RelationshipTypeId: props.primaryBennyAdd.RelationshipTypeId,
      // dob: props.primaryBennyAdd.dob || null,
      IsDeleted: false,
    };
    if (props.primaryBennyAdd.dob) {
      insertBenny.dob = props.primaryBennyAdd.dob;
    }
    let newBenny;
    if (props.agentEnroller) {
      try {
        newBenny = await enrollmentService.addBeneficiaryForMember(
          props?.fullMemberObj?.id,
          insertBenny
        );
      } catch (error) {
        console.log('error adding benny for member', error);
      }
    } else {
      try {
        newBenny = await enrollmentService.addBeneficiary(insertBenny);
      } catch (error) {
        console.log('error adding benny', error);
      }
    }

    _primaryBeneficiaries.push(newBenny);
    props.setPrimaryBeneficiaries(_primaryBeneficiaries);

    let newBennyAllocation = {
      id: newBenny.id,
      amount: 0,
      checked: true,
    };
    setAddedBenny(newBennyAllocation);
    // selectNewBenny(newBennyAllocation)
    _primaryCheckboxes.push(newBennyAllocation);
    props.setPrimaryCheckboxes(_primaryCheckboxes);
    props.setPrimaryBennyAdd(false);
    setDisableSavePrimary(true);

    //If spouse is added as primary benny and spouse is not already in db, add spouse to db
    if (
      !props?.spouse?.spouseInDb &&
      props.primaryBennyAdd.RelationshipTypeId === 1
    ) {
      //set spouse info here and save on next click in agent enrollment wrapper and single enrollment wrapper separately
      props.setSpouse({
        ...props.spouse,
        firstName: props.primaryBennyAdd.FirstName,
        lastName: props.primaryBennyAdd.LastName,
        dateOfBirth: props.primaryBennyAdd.dob,
      });
    }
  };

  const [disableSaveContingent, setDisableSaveContingent] = useState(true);
  let contingentAddArray = [
    props.contingentBennyAdd.FirstName,
    props.contingentBennyAdd.LastName,
    props.contingentBennyAdd.RelationshipTypeId,
  ];
  useEffect(() => {
    if (props.contingentBennyAdd) {
      let enableSave =
        props.contingentBennyAdd.FirstName?.length > 0 &&
        props.contingentBennyAdd.LastName?.length > 0 &&
        props.contingentBennyAdd.RelationshipTypeId > 0;
      setDisableSaveContingent(!enableSave);
    }
  }, [...contingentAddArray]);

  const handleAddContingentBeneficiary = async (e) => {
    // setAddedContingentBenny(true);
    let _contingentBeneficiaries = props.contingentBeneficiaries?.map((x) => x);
    let _contingentCheckboxes = props.contingentCheckboxes?.map((x) => x);

    let insertBenny = {
      FirstName: props.contingentBennyAdd.FirstName,
      MiddleInitial: '',
      LastName: props.contingentBennyAdd.LastName,
      RelationshipTypeId: props.contingentBennyAdd.RelationshipTypeId,
      // dob: props.contingentBennyAdd.dob || null,
      IsDeleted: false,
    };
    if (props.contingentBennyAdd.dob) {
      insertBenny.dob = props.contingentBennyAdd.dob;
    }
    let newBenny;
    if (props.agentEnroller) {
      try {
        newBenny = await enrollmentService.addBeneficiaryForMember(
          props?.fullMemberObj?.id,
          insertBenny
        );
      } catch (error) {
        console.log('error adding benny for member', error);
      }
    } else {
      try {
        newBenny = await enrollmentService.addBeneficiary(insertBenny);
      } catch (error) {
        console.log('error adding benny', error);
      }
    }

    _contingentBeneficiaries.push(newBenny);
    props.setContingentBeneficiaries(_contingentBeneficiaries);

    let newBennyAllocation = {
      id: newBenny.id,
      amount: 0,
      checked: true,
    };
    setAddedContingentBenny(newBennyAllocation);
    _contingentCheckboxes.push(newBennyAllocation);
    props.setContingentCheckboxes(_contingentCheckboxes);

    props.setContingentBennyAdd(false);
    setDisableSaveContingent(true);
    // props.getInitialBeneficiaries()
  };

  useEffect(() => {
    if (
      (!disableSaveContingent && props.contingentBennyAdd) ||
      (!disableSavePrimary && props.primaryBennyAdd)
    ) {
      props.setAddedBennyNotSaved(true);
    } else {
      props.setAddedBennyNotSaved(false);
    }
  }, [disableSavePrimary, disableSaveContingent]);

  // HANDLE BENEFICIARY CHECKBOX CLICK

  const handleBeneficiaryClick = (e, beneficiary) => {
    let _primaryCheckboxes = [...props.primaryCheckboxes];
    let checkObjInState;
    if (beneficiary.id) {
      checkObjInState = _primaryCheckboxes.find(
        (checkObj) => checkObj.id == beneficiary.id
      );
    } else if (beneficiary.dependentId) {
      checkObjInState = _primaryCheckboxes.find(
        (checkObj) => checkObj.dependentId == beneficiary.dependentId
      );
    }
    checkObjInState.checked = !checkObjInState.checked;

    //autoAllocate is set to false when a user manually enteres an allocation amount but never set back to true after that - so no matter how many checkboxes are checked, the autoAllocate logic below this will never run
    //leaving the if(autoAllocate) logic commented out for now and each time there is an error in the manual entry of allocation, checking/unchecking a box resets the allocations divided evenly between checked bennies

    // if (autoAllocate) {
    let totalChecked = 0;
    for (let checkObj of _primaryCheckboxes) {
      if (checkObj.checked) totalChecked += 1;
    }

    let evenSplitAmount = Math.floor(100 / totalChecked);
    let remainder = 100 % totalChecked;

    let i = 0;
    for (let checkObj of _primaryCheckboxes) {
      if (checkObj.checked) {
        if (remainder > 0 && i === 0)
          checkObj.amount = Number(evenSplitAmount + remainder);
        else checkObj.amount = Number(evenSplitAmount);
      } else {
        checkObj.amount = 0;
      }
      i++;
    }
    // } else {
    //    //if a user is manually changing the allocation amount, set all unchecked beneficiaries to allocation amount = 0
    //    for (let checkObj of _primaryCheckboxes) {
    //       if (!checkObj.checked) {
    //          checkObj.amount = 0
    //       }
    //    }
    // }

    checkTotalAmount(_primaryCheckboxes);
    props.setPrimaryCheckboxes(_primaryCheckboxes);
  };

  // HANDLE CONTINGENT BENEFICIARY CHECKBOX CLICK

  const handleContingentBeneficiaryClick = (e, beneficiary) => {
    let _contingentCheckboxes = props.contingentCheckboxes?.map((x) => x);

    let checkObjInState;
    if (beneficiary.id) {
      checkObjInState = _contingentCheckboxes.find(
        (checkObj) => checkObj.id == beneficiary.id
      );
    } else if (beneficiary.dependentId) {
      checkObjInState = _contingentCheckboxes.find(
        (checkObj) => checkObj.dependentId == beneficiary.dependentId
      );
    }
    checkObjInState.checked = !checkObjInState.checked;
    //  if (contingentAutoAllocate) {
    let totalChecked = 0;
    for (let checkObj of _contingentCheckboxes) {
      if (checkObj.checked) totalChecked += 1;
    }

    let evenSplitAmount = Math.floor(100 / totalChecked);
    let remainder = 100 % totalChecked;
    let i = 0;
    for (let checkObj of _contingentCheckboxes) {
      if (checkObj.checked) {
        if (remainder > 0 && i === 0)
          checkObj.amount = Number(evenSplitAmount + remainder);
        else checkObj.amount = Number(evenSplitAmount);
      } else {
        checkObj.amount = 0;
      }
      i++;
    }
    //  } else {
    //    for (let checkObj of _contingentCheckboxes) {
    //      if (!checkObj.checked) {
    //        checkObj.amount = 0;
    //      }
    //    }
    //  }

    checkContingentTotalAmount(_contingentCheckboxes);
    props.setContingentCheckboxes(_contingentCheckboxes);

    //run this to update bool value to show contingent checkboxes have been changed
    manageContingentCheckboxChanges();
  };

  const manualReallocationHandler = (e, beneficiary) => {
    let value = e.target.value;
  };

  //MANUAL ALLOCATION ENTRY
  const manualAllocationHandler = (e, beneficiary, i) => {
    //  setAutoAllocate(false);

    let value = e.target.value;

    //  //if value is greater than 100, return error
    //    if (Number(value) > 100) {
    //       setPrimaryTotalError(true)
    //     }

    let _primaryCheckboxes = props.primaryCheckboxes?.map((x) => x);

    //copy of array [false, true]
    let _enableInputAutoAllocate = [...enableInputAutoAllocate];
    //setting enableInputAutoAllocate of whatever benny allocation amount changed to be false
    _enableInputAutoAllocate[i] = false;

    let checkObjInState;
    if (beneficiary.id) {
      checkObjInState = _primaryCheckboxes.find(
        (checkObj) => checkObj.id == beneficiary.id
      );
    } else if (beneficiary.dependentId) {
      checkObjInState = _primaryCheckboxes.find(
        (checkObj) => checkObj.dependentId == beneficiary.dependentId
      );
    }
    let unhandledAmount = 100;
    //subtract each checked benny amount from unhandledAmount
    for (let j = 0; j < _primaryCheckboxes.length; j++) {
      if (_primaryCheckboxes[j].checked && !enableInputAutoAllocate[j])
        unhandledAmount -= _primaryCheckboxes[j].amount;
    }

    //check that the value entered is not greater than the unhandled amount remaining
    if (value <= unhandledAmount) {
      checkObjInState.amount = Number(value);
    } else {
      console.log('amount entered is greater than unhandled amount');
    }

    // filter out only checked bennies and get the length
    let totalChecked = _primaryCheckboxes.filter(
      (check) => check.checked
    ).length;

    let totalAutoAllowed = _enableInputAutoAllocate.filter(
      (x) => x == true
    ).length;
    let evenSplitAmount = Math.floor(
      Math.abs(unhandledAmount - value) / totalAutoAllowed
    );
    let remainder = (unhandledAmount - value) % totalAutoAllowed;

    let index = 0;
    for (let otherCheckedBenny of _primaryCheckboxes) {
      if (
        otherCheckedBenny.checked &&
        otherCheckedBenny != checkObjInState &&
        value <= unhandledAmount
      ) {
        //There's only two checked, so we can adjust the other regardless of enableInputAutoAllocate[index]
        if (totalChecked == 2) otherCheckedBenny.amount = 100 - value;
        //
        else if (_enableInputAutoAllocate[index]) {
          if (totalAutoAllowed == 1)
            otherCheckedBenny.amount = unhandledAmount - value;
          else otherCheckedBenny.amount = evenSplitAmount;
          if (remainder) otherCheckedBenny.amount += remainder;
          remainder = 0;
        }
      }
      index++;
    }

    checkTotalAmount(_primaryCheckboxes);
    props.setPrimaryCheckboxes(_primaryCheckboxes);
    //update enableInputAutoAllocate array
    setEnableInputAutoAllocate(_enableInputAutoAllocate);
  };

  //MANUAL CONTINGENT ALLOCATION ENTRY
  const manualContingentAllocationHandler = (e, beneficiary, i) => {
    // setContingentAutoAllocate(false)

    let value = e.target.value;
    // console.log(e.target, beneficiary)

    let _contingentCheckboxes = props.contingentCheckboxes?.map((x) => x);

    let _enableContingentInputAutoAllocate = [
      ...enableContingentInputAutoAllocate,
    ];
    _enableContingentInputAutoAllocate[i] = false;

    let checkObjInState;
    if (beneficiary.id) {
      checkObjInState = _contingentCheckboxes.find(
        (checkObj) => checkObj.id == beneficiary.id
      );
    } else if (beneficiary.dependentId) {
      checkObjInState = _contingentCheckboxes.find(
        (checkObj) => checkObj.dependentId == beneficiary.dependentId
      );
    }
    let unhandledAmount = 100;
    //subtract each checked benny amount from unhandledAmount
    for (let j = 0; j < _contingentCheckboxes.length; j++) {
      if (
        _contingentCheckboxes[j].checked &&
        !enableContingentInputAutoAllocate[j]
      )
        unhandledAmount -= _contingentCheckboxes[j].amount;
    }

    //check that the value entered is not greater than the unhandled amount remaining
    if (value <= unhandledAmount) {
      checkObjInState.amount = Number(value);
    } else {
      console.log('amount entered is greater than unhandled amount');
    }

    let totalChecked = _contingentCheckboxes.filter(
      (check) => check.checked
    ).length;
    let totalAutoAllowed = _enableContingentInputAutoAllocate.filter(
      (x) => x == true
    ).length;
    let evenSplitAmount = Math.floor(
      Math.abs(unhandledAmount - value) / totalAutoAllowed
    );
    let remainder = (unhandledAmount - value) % totalAutoAllowed;

    //  console.log(
    //    "value",
    //    value,
    //    "\n\ntotal checked",
    //    totalChecked,
    //    "\n\ntotal auto allowed",
    //    totalAutoAllowed,
    //    "\n\neven split",
    //    evenSplitAmount,
    //    "\n\nremainder",
    //    remainder
    //  );

    let index = 0;
    for (let otherCheckedBenny of _contingentCheckboxes) {
      if (
        otherCheckedBenny.checked &&
        otherCheckedBenny != checkObjInState &&
        value <= unhandledAmount
      ) {
        // console.log('reallocating other bennies here', otherCheckedBenny, 100 - value)
        //There's only two checked, so we can adjust the other regardless of enableInputAutoAllocate[index]
        if (totalChecked == 2) otherCheckedBenny.amount = 100 - value;
        //
        else if (_enableContingentInputAutoAllocate[index]) {
          if (totalAutoAllowed == 1)
            otherCheckedBenny.amount = unhandledAmount - value;
          else otherCheckedBenny.amount = evenSplitAmount;
          if (remainder) otherCheckedBenny.amount += remainder;
          remainder = 0;
        }
      }
      index++;
    }

    checkContingentTotalAmount(_contingentCheckboxes);
    props.setContingentCheckboxes(_contingentCheckboxes);
    setEnableContingentInputAutoAllocate(_enableContingentInputAutoAllocate);
  };

  const [primaryTotalError, setPrimaryTotalError] = useState(false);
  const checkTotalAmount = (primaryChecks) => {
    let total = 0;
    for (let primaryCheck of primaryChecks) {
      if (primaryCheck.checked) {
        total += Number(primaryCheck.amount);
      }
    }

    if (total != 100) {
      setPrimaryTotalError(true);
    } else setPrimaryTotalError(false);
  };
  const [contingentTotalError, setContingentTotalError] = useState(false);
  const checkContingentTotalAmount = (contingentChecks) => {
    let total = 0;
    let checked = false;
    for (let contingentCheck of contingentChecks) {
      if (contingentCheck.checked) {
        checked = true;
        total += Number(contingentCheck.amount);
      }
    }
    if (checked && total !== 100) {
      setContingentTotalError(true);
    } else setContingentTotalError(false);
  };

  // const checkHeight = () => {
  //    if (document.getElementById('enrollment-beneficiaries').offsetHeight > (document.body.scrollHeight - 100)) {
  //       props.setPosition("fixed")
  //    } else {
  //       props.setPosition("fixed")
  //    }
  // }

  const [addedBenny, setAddedBenny] = useState();
  const selectNewBenny = (benny) => {
    let e;
    let _newBenny = props.primaryCheckboxes.find((check) => check == benny);
    if (_newBenny) handleBeneficiaryClick(e, _newBenny);
  };
  //Adding a benny should auto select it
  useEffect(() => {
    if (addedBenny) {
      let e;
      selectNewBenny(addedBenny);
      handleBeneficiaryClick(e, addedBenny);
      setAddedBenny(false);
    }
  }, [props.primaryCheckboxes?.length]);

  const [addedContingentBenny, setAddedContingentBenny] = useState();
  const selectNewContingentBenny = (benny) => {
    let e;
    let _newBenny = props.contingentCheckboxes.find((check) => check == benny);
    if (_newBenny) handleContingentBeneficiaryClick(e, _newBenny);
  };

  useEffect(() => {
    if (addedContingentBenny) {
      let e;
      selectNewContingentBenny(addedContingentBenny);
      handleContingentBeneficiaryClick(e, addedContingentBenny);
      setAddedContingentBenny(false);
    }
  }, [props.contingentCheckboxes?.length]);

  const [showContingents, setShowContingents] = useState(null);

  const clearContingentAmounts = () => {
    let _contingentChecks = [...props.contingentCheckboxes];
    for (let contingentCheck of _contingentChecks) {
      contingentCheck.checked = false;
      contingentCheck.amount = 0;
    }
    props.setContingentCheckboxes(_contingentChecks);
  };

  //When selected in primary, is removed from contingent, thus reallocate
  useEffect(() => {
    let _contingentCheckboxes = [...props?.contingentCheckboxes];
    if (storedAllocations.length <= 0) {
      let totalChecked = 0;
      for (let checkObj of _contingentCheckboxes) {
        if (checkObj.checked) totalChecked += 1;
      }

      let evenSplitAmount = Math.floor(100 / totalChecked);

      for (let checkObj of _contingentCheckboxes) {
        if (checkObj.checked) {
          checkObj.amount = evenSplitAmount;
        } else {
          checkObj.amount = 0;
        }
      }
    } else {
      for (let checkObj of _contingentCheckboxes) {
        if (!checkObj.checked) {
          checkObj.amount = 0;
        }
      }
    }
    props.setContingentCheckboxes(_contingentCheckboxes);

    checkContingentTotalAmount(props.contingentCheckboxes);
  }, [props.contingentBeneficiaries?.length, props.contingentBeneficiaries]);

  const [autoListBuilt, setAutoListBuilt] = useState(false);
  const [enableInputAutoAllocate, setEnableInputAutoAllocate] = useState([
    false,
    true,
  ]);

  //whenever primary checkboxes change (whether it be length OR checked/unchecked), update enableInputAutoAllocate value that corresponds to the index of the primary checkbox
  //IF a primary checkbox is checked, enableInputAutoAllocate is true
  useEffect(() => {
    if (props.primaryCheckboxes && !autoListBuilt) {
      let _enableInputAutoAllocate = [...enableInputAutoAllocate];
      // let i=0

      for (let i = 0; i < props.primaryCheckboxes?.length; i++) {
        //Spouse is first, and will take precedence, so no autoAllocate from other input changes
        // if (i == 0) _enableInputAutoAllocate[i] = false
        //check first in list of primary checkboxes to auto allocate
        if (i == 0) _enableInputAutoAllocate[i] = true;
        //all primary checkboxes that are checked will auto allocate
        else if (props.primaryCheckboxes[i].checked)
          _enableInputAutoAllocate[i] = true;
        // all other primary checkboxes that are not checked will not auto allocate
        else _enableInputAutoAllocate[i] = false;
      }

      setEnableInputAutoAllocate(_enableInputAutoAllocate);
    }
  }, [props.primaryCheckboxes.length, props.primaryCheckboxes]);

  const toggleAutoAllocate = (i) => {
    let _enableInputAutoAllocate = [...enableInputAutoAllocate];
    _enableInputAutoAllocate[i] = !_enableInputAutoAllocate[i];
    setEnableInputAutoAllocate(_enableInputAutoAllocate);
  };
  const disableAutoAllocate = (i) => {
    let _enableInputAutoAllocate = [...enableInputAutoAllocate];
    _enableInputAutoAllocate[i] = false;
    setEnableInputAutoAllocate(_enableInputAutoAllocate);
  };

  const [
    enableContingentInputAutoAllocate,
    setEnableContingentInputAutoAllocate,
  ] = useState([false, true]);

  //whenever contingent checkboxes change (whether it be length OR checked/unchecked), update enableContingentInputAutoAllocate value that corresponds to the index of the contingent checkbox
  //IF a contingent checkbox is checked, enableContingentAutoAllocate is true
  useEffect(() => {
    if (props.contingentCheckboxes && !autoListBuilt) {
      let _enableContingentInputAutoAllocate = [
        ...enableContingentInputAutoAllocate,
      ];
      let i = 0;
      for (let contingentCheck of props.contingentCheckboxes) {
        //Spouse is first, and will take precedence, so no autoAllocate from other input changes
        // if (i == 0) _enableInputAutoAllocate[i] = false
        // if (i == 0) _enableInputAutoAllocate[i] = true
        // else if (primaryCheck.checked) _enableInputAutoAllocate[i] = true
        if (contingentCheck.checked)
          _enableContingentInputAutoAllocate[i] = true;
        else _enableContingentInputAutoAllocate[i] = false;
        i++;
      }
      setEnableContingentInputAutoAllocate(_enableContingentInputAutoAllocate);
      // setAutoListBuilt(true)
    }
  }, [props.contingentCheckboxes?.length, props.contingentCheckboxes]);

  const toggleContingentAutoAllocate = (i) => {
    let _enableContingentInputAutoAllocate = [
      ...enableContingentInputAutoAllocate,
    ];
    _enableContingentInputAutoAllocate[i] =
      !_enableContingentInputAutoAllocate[i];
    setEnableContingentInputAutoAllocate(_enableContingentInputAutoAllocate);
  };
  const disableContingentAutoAllocate = (i) => {
    let _enableContingentInputAutoAllocate = [
      ...enableContingentInputAutoAllocate,
    ];
    _enableContingentInputAutoAllocate[i] = false;
    setEnableContingentInputAutoAllocate(_enableContingentInputAutoAllocate);
  };

  const [storedAllocations, setStoredAllocations] = useState([]);

  //set from storedAllocations in db
  useEffect(() => {
    if (props?.storedAllocations) {
      setStoredAllocations(props.storedAllocations);
    } else {
      setStoredAllocations([]);
    }
  }, [props?.storedAllocations]);

  // const getAllocations = async () => {
  //   console.log('get all allocatons called')
  //   //TECHNICALLY A HARDCODE, CHECK FOR BENNY ON PLAN
  //   props.setLoading(true);
  //   let _storedAllocations;
  //   //check if member-side single enrollment

  //   //We save allocations to all planIds needed, so we can use any of them to get the allocations

  //   //Check for isBeneficiary
  //   let plans = [];
  //   if (props?.results) {
  //     for (let [key, value] of Object.entries(props.results)) {
  //       if (key.slice(key.length - 4, key.length) === "Plan" && value != null) {
  //         plans.push(value);
  //       }
  //     }
  //   }

  //   let electedBennyRequiredPlanIds = [];
  //   for (let plan of plans) {
  //     //check for user selection (decline or otherwise)
  //     //ladd doesnt have selectedOptionId, but has totalPremiumAmount on plan level
  //     if (plan?.totalPremiumAmount > 0) {
  //       if (plan.isBeneficiaryEnabled)
  //         electedBennyRequiredPlanIds.push(plan.planId);
  //     } else if (
  //       plan.selectedOptionId != 0 &&
  //       plan?.options &&
  //       plan.options.length > 0
  //     ) {
  //       for (let option of plan.options) {
  //         //check that the selected option isnt a decline
  //         if (option.isDecline && plan.selectedOptionId != option.member) {
  //           if (plan.isBeneficiaryEnabled)
  //             electedBennyRequiredPlanIds.push(plan.planId);
  //         }
  //       }
  //     }
  //   }
  //   //Want to make sure we default to ladd for previous enrollments
  //   let laddIdOverRide = null;
  //   if (props.results?.laddPlan?.totalPremiumAmount > 0)
  //     laddIdOverRide = props.results?.laddPlan?.planId;

  //   if (!props.agentEnroller) {
  //     const planId = laddIdOverRide || electedBennyRequiredPlanIds[0];
  //     if (planId) {
  //       try {
  //         _storedAllocations = await enrollmentService.getBeneficiaryAllocations(
  //           planId
  //         );

  //         if (!_storedAllocations || _storedAllocations.length === 0) {
  //           setStoredAllocations([]);
  //         } else {
  //           setStoredAllocations(_storedAllocations);
  //         }
  //         props.setLoading(false);
  //       } catch (error) {
  //         props.setLoading(false);
  //         console.error("error grabbing allocations", error);
  //       }
  //     }
  //   } else {
  //     //get allocations for agent-side single enrollment with planId and memberId
  //     const planId = laddIdOverRide || electedBennyRequiredPlanIds[0];
  //     const memberId = props?.fullMemberObj?.id;
  //     if (planId && memberId) {
  //       try {
  //         _storedAllocations =
  //           await enrollmentService.getBeneficiaryAllocationsForMember(
  //             planId,
  //             memberId
  //           );

  //         if (!_storedAllocations || _storedAllocations.length === 0) {
  //           setStoredAllocations([]);
  //         } else {
  //           setStoredAllocations(_storedAllocations);
  //         }
  //         props.setLoading(false);
  //       } catch (error) {
  //         props.setLoading(false);
  //         console.error("error grabbing allocations for member side", error);
  //       }

  //     }
  //   }
  // };

  // useEffect(() => {
  //   if (props?.results) {
  //     if (props.agentEnroller && props?.fullMemberObj?.id > 0){
  //       getAllocations();
  //     } else if (!props.agentEnroller) {
  //       getAllocations();
  //     }
  //   }
  // }, [
  //   props.beneficiariesInDB?.length,
  //   props.beneficiariesInDB,
  //   props?.fullMemberObj?.id,
  //   props?.results,
  // ]);

  // useEffect(() => {
  //   //reset stored allocations to empty array when new member is selected
  //   setStoredAllocations([]);
  // }, [props?.fullMemberObj?.member?.id])

  useEffect(() => {
    if (storedAllocations.length > 0) {
      // console.log('stored alocations before split', storedAllocations)
      // let _primaryCheckboxes = [...props.primaryCheckboxes];
      // let _contingentCheckboxes = [...props.contingentCheckboxes];
      // for (let allocation of storedAllocations) {
      //   if (!allocation.isDeleted) {
      //     if (allocation.isPrimary) {
      //       let bennyCheckInList = _primaryCheckboxes.find(
      //         (primaryCheck) => primaryCheck.id == allocation.id
      //       );

      //       if (bennyCheckInList && allocation?.amount) {
      //         bennyCheckInList.amount = allocation.amount;
      //         bennyCheckInList.checked = allocation.amount > 0;
      //       }
      //     } else {
      //       //find corresponding benny
      //       let bennyCheckInList = _contingentCheckboxes.find(
      //         (contingentCheck) => contingentCheck.id == allocation.id
      //       );
      //       if (bennyCheckInList && allocation?.amount) {
      //         bennyCheckInList.amount = allocation.amount;
      //         bennyCheckInList.checked = allocation.amount > 0;
      //       }
      //     }
      //   }
      // }
      console.log(
        'stored allocations',
        storedAllocations,
        '\n\n PRIMARY CHECKBOXES',
        props.primaryCheckboxes,
        '\n\n PRIMARY bennies',
        props.primaryBeneficiaries,
        '\n\n CONT CHECKBOXES',
        props.contingentCheckboxes,
        '\n\n Contingent Bennies',
        props.contingentBeneficiaries
      );
      // console.log('primary checkboxes after allocations', _primaryCheckboxes)
      // console.log('contingent checkboxes after allocations', _contingentCheckboxes)
      // props.setPrimaryCheckboxes(_primaryCheckboxes);
      // props.setContingentCheckboxes(_contingentCheckboxes);
    }
  }, [storedAllocations, props.beneficiariesInDB?.length]);

  useEffect(() => {
    let showContingents = false;
    if (props.contingentCheckboxes) {
      for (let contCheck of props.contingentCheckboxes) {
        if (contCheck.checked) showContingents = true;
      }
      setShowContingents(showContingents);
      setContingentExpanded(showContingents);
    }
  }, [props.contingentCheckboxes]);

  const debugClick = () => {
    console.log('bennies in db', props.beneficiariesInDB);

    console.log('potential bennies', props.potentialBeneficiaries);

    console.log('results', props.results);

    console.log('primaryBennies', props.primaryBeneficiaries);

    console.log('primary checks', props.primaryCheckboxes);

    console.log('contingent checks', props.contingentCheckboxes);
  };

  //Edit and Delete Beneficiary

  const [editBenny, setEditBenny] = useState({ id: null });

  useEffect(() => {
    setEditBenny({ id: null });
  }, []);

  const openEditBeneficiary = (e, beneficiary) => {
    //set the editBenny to the id of the benny editing
    console.log('editBenny BEFORE', editBenny);
    setEditBenny(beneficiary);
    console.log('editBenny AFTER', editBenny);
    console.log('primary checkboxes', props.primaryCheckboxes);
    console.log('open edit benny form', beneficiary);
  };

  const cancelEditBenny = () => {
    console.log('edit benny before cancel click', editBenny);
    setEditBenny({ id: null });
    props.setPrimaryBennyEdit(false);
    props.setContingentBennyEdit(false);
  };

  //Update an exisiting beneficiary in the list on the benny page
  const handleEditBeneficiary = async (beneficiary, val) => {
    let valueToUpdate = val;
    console.log('edit beneficiary', beneficiary);
    console.log('value to update', valueToUpdate);
    let _primaryBennyUpdated = {
      firstName: beneficiary.firstName,
      middleInitial: beneficiary?.middleInitial || '',
      lastName: beneficiary.lastName,
      dob: beneficiary?.dob,
      memberId: beneficiary.memberId,
      id: beneficiary?.id,
      isPrimary: true,
      relationshipTypeId: beneficiary?.relationshipTypeId,
      isDeleted: beneficiary?.isDeleted || false,
      lastModifiedBy: beneficiary?.lastModifiedBy,
      isPrimary: beneficiary?.isPrimary || true,
      // amount: beneficiary?.amount,
    };
    console.log('built primary benny to update', _primaryBennyUpdated);

    let contingentBennyUpdated = {
      firstName: beneficiary.firstName,
      middleInitial: beneficiary?.middleInitial || '',
      lastName: beneficiary.lastName,
      dob: beneficiary?.dob,
      memberId: beneficiary.memberId,
      id: beneficiary?.id,
      isPrimary: false,
      relationshipTypeId: beneficiary?.relationshipTypeId,
      isDeleted: beneficiary?.isDeleted || false,
      lastModifiedBy: beneficiary?.lastModifiedBy,
      isPrimary: beneficiary?.isPrimary || true,
      // amount: beneficiary?.amount,
    };

    console.log('primaryBennnyList BEFORE update', props.primaryBeneficiaries);

    //update local state for BENEFICIARY for either primary or contingent beneficiary display
    //check if primary or contingent exist with matching id or dependentIds and update individual beneficiary with new values
    if (valueToUpdate === 'isPrimary') {
      console.log('updating primary...');
      props.setPrimaryBeneficiaries((prevBeneficiaries) =>
        prevBeneficiaries?.map((prevBeneficiary) =>
          (prevBeneficiary.id && prevBeneficiary.id === beneficiary.id) ||
          (prevBeneficiary.dependentId &&
            prevBeneficiary.dependentId === beneficiary.dependentId)
            ? { ...prevBeneficiary, ...beneficiary }
            : prevBeneficiary
        )
      );
    } else if (valueToUpdate === 'isContingent') {
      console.log('updating contingent...');
      props.setContingentBeneficiaries((prevBeneficiaries) =>
        prevBeneficiaries?.map((prevBeneficiary) =>
          (prevBeneficiary.id && prevBeneficiary.id === beneficiary.id) ||
          (prevBeneficiary.dependentId &&
            prevBeneficiary.dependentId === beneficiary.dependentId)
            ? { ...prevBeneficiary, ...beneficiary }
            : prevBeneficiary
        )
      );
    }

    //Check if spouse info changed and  update for spouse table in DB
    //next click will save spouse OR update spouse back in DB w/ saveSpouseInfo() in wrapper component
    if (beneficiary.relationshipTypeId === 1) {
      //set spouse info here and save on next click in agent enrollment wrapper and single enrollment wrapper separately
      console.log('beneficiary on submit', beneficiary);
      props.setSpouse({
        ...props.spouse,
        firstName: beneficiary?.firstName,
        lastName: beneficiary?.lastName,
        dateOfBirth: beneficiary?.dob,
      });
    }

    //UPDATE BENEFICIARY IN DB
    // check if agent enroller AND if benny is in db yet, and update accordingly for agent or member side
    if (props.agentEnroller && _primaryBennyUpdated?.id) {
      try {
        await enrollmentService.updateBeneficiaryForMember(
          beneficiary.memberId,
          _primaryBennyUpdated
        );
        console.log('updating benny for agent');
      } catch (error) {
        console.log('error updating _primaryBennyUpdated by agent', error);
      }
    } else {
      try {
        await enrollmentService.updateBeneficiary(_primaryBennyUpdated);
        console.log('updating benny');
      } catch (error) {
        console.log('error updating beneficiary', error);
      }
    }

    setEditBenny({ id: null });
  };

  const handleMobileDobChange = (e, isPrimary) => {
    console.log('benny dob change', e);
    let value = e; //format = date object
    if (isPrimary) {
      props.setPrimaryBennyAdd({
        ...props.primaryBennyAdd,
        dob: value,
      });
    } else {
      props.setContingentBennyAdd({
        ...props.contingentBennyAdd,
        dob: value,
      });
    }
    //update spouse dob if spouse is being added as primary or contingent
    if (
      (props?.primaryBennyAdd &&
        props?.primaryBennyAdd?.relationshipTypeId === 1) ||
      (props?.contingentBennyAdd &&
        props?.contingentBennyAdd?.relationshipTypeId === 1)
    ) {
      props.setSpouse({
        ...props.spouse,
        dateOfBirth: value,
      });
    }
  };
  const handleDobFocus = (e) => {
    e.target.setAttribute('type', 'date');
  };
  const handleDobBlur = (e) => {
    if (!e.target.value) e.target.setAttribute('type', '');
    // checkError("dob")
  };

  //THESE TWO USE EFFECTS HANDLE toggling checkboxes between primary and contingents

  //PRIMARY CHECKBOX CHANGES
  useEffect(() => {
    if (props?.contingentCheckboxes && props?.contingentBeneficiaries) {
      let _contingentCheckboxes = [...props?.contingentCheckboxes];
      let _contingentBeneficiaries = [...props?.contingentBeneficiaries];
      // console.log('start of contCheckboxes', _contingentCheckboxes,)
      // console.log('start of contBennies', _contingentBeneficiaries)
      // console.log('primary bennies in useEffect', props?.primaryBeneficiaries)
      // console.log('primary checkboxes in useEffect', props?.primaryCheckboxes)
      for (let primaryCheck of props?.primaryCheckboxes) {
        //if checked in primary list
        if (primaryCheck.checked) {
          // check all in primary array against all in contingent array
          if (primaryCheck.id) {
            //this section is good!
            _contingentCheckboxes = _contingentCheckboxes.filter(
              (contingentCheck) => {
                // console.log('checking IDDDDDDD',contingentCheck.id !== primaryCheck.id, contingentCheck.id, primaryCheck.id)
                return contingentCheck.id != primaryCheck.id;
              }
            );
            // console.log('\n\ncontingents after filtering from db', _contingentCheckboxes)
            _contingentBeneficiaries = _contingentBeneficiaries.filter(
              (contingentBeneficiary) =>
                contingentBeneficiary.id != primaryCheck.id
            );
          } else if (primaryCheck.dependentId) {
            _contingentCheckboxes = _contingentCheckboxes.filter(
              (contingentCheck) =>
                contingentCheck.dependentId != primaryCheck.dependentId
            );

            _contingentBeneficiaries = _contingentBeneficiaries.filter(
              (contingentBeneficiary) =>
                contingentBeneficiary.dependentId != primaryCheck.dependentId
            );
          }
        } else if (!primaryCheck.checked) {
          //I think this is the issue area
          //else if the primary benny is not checked
          let _primaryBenny;
          if (primaryCheck.id) {
            _primaryBenny = props?.primaryBeneficiaries.find(
              (primaryBenny) => primaryBenny.id == primaryCheck.id
            );
          } else if (primaryCheck.dependentId) {
            _primaryBenny = props?.primaryBeneficiaries.find(
              (primaryBenny) =>
                primaryBenny.dependentId == primaryCheck.dependentId
            );
          }
          //Push it back into the contingents IF id doesn't exist in contingents
          if (
            !_contingentCheckboxes.find((contingentCheck) => {
              let _tempCheck = { ...contingentCheck };
              delete _tempCheck.amount;
              let _tempPrimaryCheck = { ...primaryCheck };
              delete _tempPrimaryCheck.amount;
              // console.log('temp check',_tempCheck, JSON.stringify(_tempCheck) == JSON.stringify(_tempPrimaryCheck))
              return (
                JSON.stringify(_tempCheck) ==
                  JSON.stringify(_tempPrimaryCheck) &&
                (contingentCheck.id == primaryCheck.id ||
                  contingentCheck?.dependentId == primaryCheck?.dependentId)
              );
            })
          ) {
            _contingentCheckboxes.push({ ...primaryCheck });
          }

          if (
            !_contingentBeneficiaries.find((contingentBenny) => {
              return (
                JSON.stringify(contingentBenny) ==
                  JSON.stringify(_primaryBenny) &&
                (contingentBenny.id == primaryCheck.id ||
                  contingentBenny?.dependentId == primaryCheck?.dependentId)
              );
            })
          ) {
            _contingentBeneficiaries.push({ ..._primaryBenny });
          }
          //  _contingentCheckboxes = {..._contingentCheckboxes,primaryCheck}
        }
      }
      // console.log('checking contingents and setting in useEffect', _contingentCheckboxes)
      // console.log('checking cont bennies in useEffect', _contingentBeneficiaries)
      props?.setContingentCheckboxes(_contingentCheckboxes);
      props?.setContingentBeneficiaries(_contingentBeneficiaries);
    }
  }, [props?.primaryCheckboxes, props?.primaryCheckboxes?.length]);

  //track changes made to contingent checkboxes in state and only run below useEffect when this piece of state updates
  const [contingentCheckboxesChanged, setContingentCheckboxesChanged] =
    useState(false);

  const manageContingentCheckboxChanges = () => {
    setContingentCheckboxesChanged(!contingentCheckboxesChanged);
  };

  //CONTINGENT CHECKBOX CHANGES
  //update primary checkboxes and bennies if changes in contingent checkboxes - bool value contingentCheckboxesChanged in dependency array because keeping contingentCheckboxes in dep array caues infinte loop with above useEFfect
  useEffect(() => {
    if (props?.primaryCheckboxes && props?.primaryBeneficiaries) {
      let _primaryCheckboxes = [...props?.primaryCheckboxes];
      let _primaryBeneficiaries = [...props?.primaryBeneficiaries];
      // console.log('start of primCheckboxes', _primaryCheckboxes,)
      // console.log('start of primBennies', _primaryBeneficiaries)
      // console.log('cont bennies in useEffect', props?.contingentBeneficiaries)
      // console.log('contingent checkboxes in useEffect', props?.contingentCheckboxes)
      for (let contingentCheck of props?.contingentCheckboxes) {
        //if checked in contingent list
        if (contingentCheck.checked) {
          // check all in contingent array against all in primary array
          if (contingentCheck.id) {
            //this section is good!
            _primaryCheckboxes = _primaryCheckboxes.filter((primaryCheck) => {
              return primaryCheck.id != contingentCheck.id;
            });
            // console.log('\n\nprimaries after filtering from db', _primaryCheckboxes)
            _primaryBeneficiaries = _primaryBeneficiaries.filter(
              (primaryBenefeciary) =>
                primaryBenefeciary.id != contingentCheck.id
            );
          } else if (contingentCheck.dependentId) {
            _primaryCheckboxes = _primaryCheckboxes.filter(
              (primaryCheck) =>
                primaryCheck.dependentId != contingentCheck.dependentId
            );

            _primaryBeneficiaries = _primaryBeneficiaries.filter(
              (primaryBenefeciary) =>
                primaryBenefeciary.dependentId != contingentCheck.dependentId
            );
          }
        } else if (!contingentCheck.checked) {
          //I think this is the issue area
          //else if the contingent benny is not checked
          let _contingentBenny;
          if (contingentCheck.id) {
            _contingentBenny = props?.contingentBeneficiaries.find(
              (contingentBenny) => contingentBenny.id == contingentCheck.id
            );
          } else if (contingentCheck.dependentId) {
            _contingentBenny = props?.contingentBeneficiaries.find(
              (contingentBenny) =>
                contingentBenny.dependentId == contingentCheck.dependentId
            );
          }
          //Push it back into the primary IF id doesn't exist in primary
          if (
            !_primaryCheckboxes.find((primaryCheck) => {
              let _tempCheck = { ...primaryCheck };
              delete _tempCheck.amount;
              let _tempContingentCheck = { ...contingentCheck };
              delete _tempContingentCheck.amount;
              // console.log('temp check',_tempCheck, JSON.stringify(_tempCheck) == JSON.stringify(_tempCont))
              return (
                JSON.stringify(_tempCheck) ==
                  JSON.stringify(_tempContingentCheck) &&
                (primaryCheck.id == contingentCheck.id ||
                  primaryCheck?.dependentId == contingentCheck?.dependentId)
              );
            })
          ) {
            _primaryCheckboxes.push({ ...contingentCheck });
          }

          if (
            !_primaryBeneficiaries.find((primaryBenny) => {
              return (
                JSON.stringify(primaryBenny) ==
                  JSON.stringify(_contingentBenny) &&
                (primaryBenny.id == contingentCheck.id ||
                  primaryBenny?.dependentId == contingentCheck?.dependentId)
              );
            })
          ) {
            _primaryBeneficiaries.push({ ..._contingentBenny });
          }
          //  _primaryCheckboxes = {..._primaryCheckboxes,primaryCheck}
        }
      }
      // console.log('checking primaries and setting in useEffect', _primaryCheckboxes)
      // console.log('checking primary bennies in useEffect', props?.primaryBeneficiaries)
      props?.setPrimaryCheckboxes(_primaryCheckboxes);
      props?.setPrimaryBeneficiaries(_primaryBeneficiaries);
    }
  }, [contingentCheckboxesChanged]);

  return (
    <>
      <div
        style={{
          overflowY: 'auto',
          height: props.agentEnroller ? 'calc(100vh - 276px)' : '',
        }}
      >
        <div
          id="enrollment-beneficiaries"
          className="flex-container"
          style={{
            height: props.agentEnroller
              ? 'calc(100vh - 200px)'
              : 'calc(100vh - 160px)',
            overflowY: 'none',
            maxWidth: props.agentEnroller ? '100%' : '600px',
            margin: props.agentEnroller ? '0 auto' : '0 auto',
            padding: props.agentEnroller ? '20px' : '30px',
            width: '100%',
            fontFamily: 'Archivo',
          }}
        >
          {!props.agentEnroller && (
            <ExternalEnrollmentHeader
              fullMemberObj={props.thisMember}
              agentEnroller={props.agentEnroller}
              selectedEnrollment={props.results}
              basicHeader={true}
            />
          )}

          {props.agentEnroller && (
            <>
              <div
                className="flex-row"
                style={{
                  textAlign: 'left',
                  // paddingLeft: "5px",
                  marginTop: '5px',
                  marginBottom: '0px',
                  marginLeft: '-7px',
                }}
              >
                <Typography variant="h5" fontFamily={'Poppins'}>
                  Beneficiaries
                </Typography>
              </div>

              <div
                className="flex-row"
                style={{
                  textAlign: 'left',
                  // paddingLeft: "5px",
                  marginTop: '5px',
                  // marginBottom: "38px",
                  marginLeft: '-7px',
                }}
              >
                <Typography variant="body2" color="text.gray">
                  Input primary and contingent beneficiaries for the member.
                </Typography>
              </div>
            </>
          )}

          <Box
            className="flex-row"
            sx={{
              fontFamily: 'Poppins',
              minHeight: '57px',
              color: expanded ? 'primary.main' : '#646464',
              fontSize: '20px',
              fontWeight: 600,
              lineHeight: '30px',
              position: 'relative',
              justifyContent: 'flex-start',
              alignItems: 'center',
              padding: '0px',
            }}
          >
            <span
              className=""
              style={{ cursor: 'pointer', position: 'relative' }}
              onClick={handleExpandClick}
            >
              {/* {stdPlan.title}  */}
              Primary Beneficiary
              {/* bigger clickable area */}
              {/* <div style={{ position: "absolute", width: "100%", height: "300%", top: "-100%" }} />   */}
            </span>

            <div
              onClick={handleExpandClick}
              style={{
                position: 'absolute',
                right: '0%',
                top: '0%',
                color: '#195ffb',
                width: '18%',
                height: '100%',
                cursor: 'pointer',
              }}
            >
              {expandIcon}
            </div>
          </Box>
          <Divider
            sx={{ position: 'relative', bottom: '5px', color: '#F0F0F0' }}
          />

          <Collapse in={expanded}>
            <Box
              className="flex-row"
              sx={{
                fontWeight: 700,
                fontSize: '15px',
                lineHeight: '25px',
                // marginBottom: "10px",
                paddingLeft: '0px',
              }}
            >
              Please select your primary beneficiaries.
              <Tooltip
                title="If adding multiple beneficiaries, allocation must total 100%"
                placement="bottom-end"
                arrow
                enterTouchDelay={100}
              >
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <InfoOutlinedIcon
                    sx={{ height: '20px', color: '#00000038' }}
                  />
                </div>
              </Tooltip>
            </Box>

            {/* for future use */}
            {/* <FormGroup sx={{margin: '0px 0px 10px 0px'}}>
                <FormControlLabel
                  control={
                    <Switch
                      // checked={evenSplitPrimary}
                      // onChange={() => setEvenSplitPrimary(!evenSplitPrimary)}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  }
                  label="Evenly split allocations"
                />
            </FormGroup> */}

            {/* {primaryTotalError &&
                <div className="flex-row" style={{ color: 'red', fontWeight: 700, fontSize: '18px', lineHeight: '25px', marginBottom: '10px', paddingLeft: '0px' }}>
                   Allocations must total 100%
                </div>
             } */}

            {/* PRIMARIES */}
            {props.primaryBeneficiaries &&
              props.primaryBeneficiaries?.map((primaryBeneficiary, i) => {
                let correspondingContingent = props.contingentCheckboxes.find(
                  (contCheck) => {
                    if (
                      contCheck?.id &&
                      contCheck.id == primaryBeneficiary.id
                    ) {
                      // console.log('have an id', contCheck)
                      return contCheck;
                    } else if (
                      contCheck?.dependentId &&
                      contCheck.dependentId == primaryBeneficiary.dependentId
                    ) {
                      // console.log("have a dep id", contCheck);
                      return contCheck;
                    }
                  }
                );
                if (
                  !correspondingContingent ||
                  correspondingContingent.checked == false
                ) {
                  return (
                    <>
                      <div
                        className="flex-row"
                        style={{ alignItems: 'center', marginBottom: '23px' }}
                        key={
                          primaryBeneficiary?.id ||
                          primaryBeneficiary.dependentId
                        }
                      >
                        <Checkbox
                          checked={
                            props.primaryCheckboxes.find((checkObj) => {
                              if (
                                checkObj.id &&
                                checkObj.id == primaryBeneficiary.id
                              )
                                return checkObj;
                              else if (
                                checkObj.dependentId &&
                                checkObj.dependentId ==
                                  primaryBeneficiary.dependentId
                              )
                                return checkObj;
                              else return undefined;
                            })?.checked || false
                          }
                          onClick={(e) => {
                            handleBeneficiaryClick(e, primaryBeneficiary);
                            toggleAutoAllocate(i);
                          }}
                          sx={{ padding: '0px', marginRight: '10px' }}
                          icon={
                            <CheckBoxOutlineBlankIcon sx={{ height: '19px' }} />
                          }
                          checkedIcon={
                            <CheckBoxIcon
                              sx={{ height: '19px', color: 'primary.main' }}
                            />
                          }
                        />

                        <div className="col-4">
                          <div style={{ fontWeight: 600, fontSize: '15px' }}>
                            {primaryBeneficiary.firstName}{' '}
                            {primaryBeneficiary.lastName}
                          </div>

                          <div style={{ fontSize: '15px', color: '#646464' }}>
                            {relationshipTypes &&
                              (relationshipTypes.find(
                                (relationshipType) =>
                                  relationshipType.id ==
                                  primaryBeneficiary.relationshipTypeId
                              )?.name ||
                                'Spouse')}
                          </div>
                        </div>

                        <div className="col-6">
                          <TextField
                            fullWidth
                            label="Allocation"
                            error={primaryTotalError}
                            helperText={
                              primaryTotalError &&
                              props.primaryCheckboxes[i]?.checked
                                ? 'allocations must total 100%'
                                : ''
                            }
                            type="number"
                            autoComplete="off"
                            InputProps={{
                              sx: { borderRadius: '8px', height: '40px' },
                              startAdornment: (
                                <InputAdornment position="start">
                                  %
                                </InputAdornment>
                              ),
                            }}
                            InputLabelProps={{ shrink: true }}
                            inputProps={{
                              max: '100',
                              min: '0',
                              type: 'number',
                              'data-allocate': enableInputAutoAllocate[i],
                            }}
                            onFocus={(e) => e.target.select()}
                            onBlur={(e) => {
                              disableAutoAllocate(i);
                            }}
                            value={
                              Number(
                                props.primaryCheckboxes.find((checkObj) => {
                                  if (checkObj.id)
                                    return checkObj.id == primaryBeneficiary.id;
                                  else if (checkObj.dependentId)
                                    return (
                                      checkObj.dependentId ==
                                      primaryBeneficiary.dependentId
                                    );
                                })?.amount
                              ) || ''
                            }
                            onChange={(e) => {
                              manualAllocationHandler(e, primaryBeneficiary, i);
                            }}
                            // onClick={(e)=>console.log(e.target)}
                            //disable textfield if checkbox is not checked
                            disabled={
                              !props.primaryCheckboxes.find((checkObj) => {
                                if (
                                  checkObj.id &&
                                  checkObj.id === primaryBeneficiary.id
                                )
                                  return checkObj;
                                else if (
                                  checkObj.dependentId &&
                                  checkObj.dependentId ===
                                    primaryBeneficiary.dependentId
                                )
                                  return checkObj;
                                else return undefined;
                              })?.checked
                            }
                          />
                        </div>

                        {/* FUTURE edit beneficiary button */}

                        {/* <div className="col-2">
                        <Button onClick={()=> props.deleteBeneficiary(primaryBeneficiary?.memberId, primaryBeneficiary?.id, primaryBeneficiary?.dependentId)}>X</Button>
                      </div> */}
                        {/* <div className="col-2">
                        <IconButton
                          onClick={(e) => {
                            openEditBeneficiary(e, primaryBeneficiary);
                            console.log(editBenny);
                          }}
                          disabled={primaryBeneficiary.relationshipTypeId !== 1}
                          // disabled={!primaryBeneficiary?.id}
                          // sx={{ opacity: !primaryBeneficiary?.id ? 0 : 1}}
                        >
                          <EditIcon />
                        </IconButton>
                      </div> */}
                      </div>

                      {/* EDIT EXISTING BENNY - open textfield to edit selected benny*/}
                      {((editBenny?.id &&
                        editBenny?.id === primaryBeneficiary?.id) ||
                        (editBenny?.dependentId &&
                          editBenny?.dependentId ===
                            primaryBeneficiary?.dependentId)) && (
                        <>
                          <div
                            className="flex-row"
                            style={{ marginBottom: '15px' }}
                          >
                            <TextField
                              fullWidth
                              label="First Name"
                              variant="outlined"
                              value={editBenny?.firstName || ''}
                              onChange={handleInputChange}
                              inputProps={{
                                'data-update': 'firstNamePrimary',
                              }}
                            />
                          </div>

                          <div
                            className="flex-row"
                            style={{ marginBottom: '15px' }}
                          >
                            <TextField
                              fullWidth
                              label="Last Name"
                              variant="outlined"
                              value={editBenny?.lastName || ''}
                              onChange={handleInputChange}
                              inputProps={{
                                'data-update': 'lastNamePrimary',
                              }}
                            />
                          </div>

                          <div
                            className="flex-row"
                            style={{ marginBottom: '15px' }}
                          >
                            <TextField
                              fullWidth
                              select
                              sx={{
                                '& fieldset': { borderRadius: '8px' },
                                //   '& fieldset > legend': { display: 'none' },
                                '&.MuiOutlinedInput-root.Mui-focused > fieldset':
                                  {
                                    border: '2px solid #195ffb',
                                  },
                              }}
                              variant="outlined"
                              label="Relationship"
                              value={editBenny?.relationshipTypeId || ''}
                              onChange={(e) => {
                                console.log(
                                  'editing benny relationshipID...',
                                  e.target.value
                                );
                                setEditBenny({
                                  ...editBenny,
                                  relationshipTypeId: e.target.value,
                                });
                              }}
                            >
                              {relationshipTypeListForEdit &&
                                relationshipTypeListForEdit?.map(
                                  (relationshipType) => (
                                    <MenuItem
                                      key={relationshipType.id}
                                      value={relationshipType.id}
                                    >
                                      {relationshipType.name}
                                    </MenuItem>
                                  )
                                )}
                            </TextField>
                          </div>

                          <div
                            className="flex-row"
                            style={{
                              display: 'flex',
                              justifyContent: 'flex-end',
                              margin: '0 10px 40px',
                            }}
                          >
                            <div className="" style={{ width: '20%' }}>
                              <Button
                                variant="outlined"
                                fullWidth
                                sx={{
                                  borderRadius: '100px',
                                  height: '40px',
                                  fontWeight: '500!important',
                                }}
                                onClick={() => cancelEditBenny()}
                              >
                                Cancel
                              </Button>
                            </div>
                            <div className="" style={{ width: '20%' }}>
                              <Button
                                variant="contained"
                                fullWidth
                                sx={{
                                  borderRadius: '100px',
                                  height: '40px',
                                  fontWeight: '500!important',
                                  marginLeft: '10px',
                                }}
                                inputProps={{
                                  'data-update': 'updatePrimary',
                                }}
                                onClick={() =>
                                  handleEditBeneficiary(editBenny, 'isPrimary')
                                }
                              >
                                Update
                              </Button>
                            </div>
                          </div>
                        </>
                      )}
                    </>
                  );
                }
              })}

            <div className="flex-row" style={{ marginBottom: '20px' }}>
              {/* <Link underline='none' sx={{fontSize:'15px',color:'#195FFB'}}
             onClick={()=>setPrimaryBennyAdd(true)}
             >
                + add new beneficiary
             </Link> */}
              {/* <Button variant="text" fontSize="15px"
                      onClick={() => props.setPrimaryBennyAdd(true)}>
                      + add new beneficiary
                   </Button> */}
              <Checkbox
                checked={props.primaryBennyAdd}
                onClick={() => {
                  props.setPrimaryBennyAdd((state) => !state);
                  //if editBenny was true, set to false
                  setEditBenny({ id: null });
                }}
                sx={{ padding: '0px', marginRight: '10px' }}
                icon={<CheckBoxOutlineBlankIcon sx={{ height: '19px' }} />}
                checkedIcon={
                  <CheckBoxIcon
                    sx={{ height: '19px', color: 'primary.main' }}
                  />
                }
              />

              <div className="" style={{ fontWeight: 600, fontSize: '15px' }}>
                Add additional primary beneficiary
              </div>
            </div>
            {/* ADD A NEW BENNY */}
            {props.primaryBennyAdd && (
              <>
                <div className="flex-row" style={{ marginBottom: '15px' }}>
                  <TextField
                    fullWidth
                    label="First Name"
                    variant="outlined"
                    value={props.primaryBennyAdd.FirstName || ''}
                    onChange={handleInputChange}
                    inputProps={{
                      'data-update': 'firstNamePrimary',
                    }}
                  />
                </div>

                <div className="flex-row" style={{ marginBottom: '15px' }}>
                  <TextField
                    fullWidth
                    label="Last Name"
                    variant="outlined"
                    value={props.primaryBennyAdd.LastName || ''}
                    onChange={handleInputChange}
                    inputProps={{
                      'data-update': 'lastNamePrimary',
                    }}
                  />
                </div>

                <div className="flex-row" style={{ marginBottom: '15px' }}>
                  <TextField
                    fullWidth
                    select
                    sx={{
                      '& fieldset': { borderRadius: '8px' },
                      //   '& fieldset > legend': { display: 'none' },
                      '&.MuiOutlinedInput-root.Mui-focused > fieldset': {
                        border: '2px solid #195ffb',
                      },
                    }}
                    variant="outlined"
                    label="Relationship"
                    value={props.primaryBennyAdd.RelationshipTypeId || ''}
                    onChange={(e) =>
                      props.setPrimaryBennyAdd({
                        ...props.primaryBennyAdd,
                        RelationshipTypeId: e.target.value,
                      })
                    }
                  >
                    {relationshipTypes &&
                      relationshipTypes?.map((relationshipType) => (
                        <MenuItem
                          key={relationshipType.id}
                          value={relationshipType.id}
                        >
                          {relationshipType.name}
                        </MenuItem>
                      ))}
                  </TextField>
                </div>

                <div className="flex-row" style={{ marginBottom: '15px' }}>
                  {/* {isMobileDevice
                  ?
                  <Box sx={{width:'100%'}}>
                    <LocalizationProvider required={false} dateAdapter={AdapterDateFns}>
                      <DatePicker required={false}
                        // onBlur={(e) => checkError("dob")}
                        disableFuture
                        openTo='year'
                        views={['year', 'month', 'day']}
                        value={props.primaryBennyAdd.dob || ''} onChange={e => handleMobileDobChange(e,true)}
                        format='MM/dd/yyyy'
                        renderInput={(params) =>
                          <TextField {...params}
                            // error={dobError}
                            // onBlur={(e) => checkError("dob")}
                            variant='outlined' label='Date of birth'
                            placeholder="mm/dd/yyyy"
                            fullWidth
                            required={false}
                            // InputLabelProps={{ sx: { color: 'primary.main' } }}
                          />
                        }
                      />
                    </LocalizationProvider>
                  </Box>
                  :
                  <Box sx={{width:'100%'}}>
                    <TextField fullWidth
                      type={props.primaryBennyAdd.dob ? 'date' : ''}
                      required={false}
                      // error={dobError}
                      label="Date of Birth" variant="outlined"
                      value={props.primaryBennyAdd.dob || ''}
                      onChange={(e) => props.setPrimaryBennyAdd({ ...props.primaryBennyAdd, dob: e.target.value })}
                      onFocus={handleDobFocus} onBlur={handleDobBlur}
                      // InputLabelProps={{ sx: { color: 'primary.main' } }}
                    />
                  </Box>
                } */}

                  {/* PrimaryDob */}
                  <Box sx={{ width: '100%' }}>
                    <LocalizationProvider
                      required={false}
                      dateAdapter={AdapterDateFns}
                    >
                      <DatePicker
                        required={false}
                        // onBlur={(e) => checkError("dob")}
                        disableFuture
                        label="Date of birth"
                        openTo="year"
                        views={['year', 'month', 'day']}
                        value={props.primaryBennyAdd.dob || null}
                        onChange={(e) => handleMobileDobChange(e, true)}
                        format="MM/dd/yyyy"
                        sx={{ width: '100%' }}
                        slotProps={{
                          openPickerButton: { sx: { visibility: 'hidden' } }, //hide calendar icon calandar
                        }}
                      />
                    </LocalizationProvider>
                  </Box>
                </div>

                <div
                  className="flex-row"
                  style={{ flexWrap: 'wrap', marginBottom: '40px' }}
                >
                  <div className="col-7" />
                  <div className="col row">
                    <div className="" style={{ textAlign: 'end' }}>
                      <Button
                        variant="contained"
                        fullWidth
                        disabled={disableSavePrimary}
                        sx={{
                          borderRadius: '100px',
                          height: '40px',
                          fontWeight: '500!important',
                        }}
                        onClick={handleAddPrimaryBeneficiary}
                      >
                        Save
                      </Button>
                    </div>
                  </div>
                </div>
              </>
            )}
          </Collapse>
          {!expanded && (
            <>
              {props.primaryBeneficiaries &&
                props.primaryBeneficiaries?.map((primaryBeneficiary, i) => {
                  let primaryCheck = props.primaryCheckboxes.find(
                    (primaryCheck) => primaryCheck.id == primaryBeneficiary.id
                  );
                  if (primaryCheck?.checked) {
                    return (
                      <div
                        className="flex-row"
                        style={{
                          justifyContent: 'space-between',
                          marginTop: '5px',
                          marginBottom: '5px',
                        }}
                      >
                        <span
                          style={{
                            fontWeight: 500,
                            fontSize: '15px',
                            color: '#646464',
                          }}
                        >
                          {primaryBeneficiary.firstName}{' '}
                          {primaryBeneficiary.lastName}
                        </span>

                        <span
                          style={{
                            fontWeight: 700,
                            fontSize: '15px',
                            color: '#646464',
                          }}
                        >
                          {primaryCheck.amount}%
                        </span>
                      </div>
                    );
                  }
                })}
            </>
          )}

          {/* <Divider sx={{ position: 'relative', bottom: '5px', color: '#F0F0F0',marginBottom:'10px' }} /> */}

          <div
            className="flex-row"
            style={{
              fontFamily: 'Archivo',
              fontWeight: 500,
              fontSize: '15px',
              lineHeight: '25px',
              color: '#646464',
              marginBottom: '23px',
              marginTop: '30px',
            }}
          >
            Would you like to add a Contingent Beneficiary?
          </div>

          <div className="flex-row" style={{ gap: '15px' }}>
            <div className="col" style={{ textAlign: 'start' }}>
              <Button
                variant={showContingents === true ? 'contained' : 'outlined'}
                fullWidth
                onClick={() => {
                  setShowContingents(true);
                  setContingentExpanded(true);
                }}
              >
                YES
              </Button>
            </div>
            <div className="col" style={{ textAlign: 'end' }}>
              <Button
                variant={showContingents === false ? 'contained' : 'outlined'}
                fullWidth
                onClick={() => {
                  setShowContingents(false);
                  setContingentExpanded(false);
                  clearContingentAmounts();
                }}
              >
                NO
              </Button>
            </div>
          </div>

          <Fade in={showContingents}>
            <Box
              className="flex-row"
              sx={{
                fontFamily: 'Poppins',
                minHeight: '57px',
                color: contingentExpanded ? 'primary.main' : '#646464',
                fontSize: '20px',
                fontWeight: 600,
                lineHeight: '30px',
                position: 'relative',
                justifyContent: 'flex-start',
                alignItems: 'center',
                padding: '0px',
              }}
            >
              <span
                className=""
                style={{ cursor: 'pointer', position: 'relative' }}
                onClick={handleContingentExpandClick}
              >
                {/* {stdPlan.title}  */}
                Contingent Beneficiary
                {/* bigger clickable area */}
                {/* <div style={{ position: "absolute", width: "100%", height: "300%", top: "-100%" }} />   */}
              </span>

              <div
                onClick={handleContingentExpandClick}
                style={{
                  position: 'absolute',
                  right: '0%',
                  top: '0%',
                  color: '#195ffb',
                  width: '18%',
                  height: '100%',
                  cursor: 'pointer',
                }}
              >
                {contingentExpandIcon}
              </div>
            </Box>
          </Fade>
          <Divider
            sx={{ position: 'relative', bottom: '5px', color: '#F0F0F0' }}
          />

          <Collapse in={contingentExpanded}>
            {/* CONTINGENTS */}
            <Box
              className="flex-row"
              sx={{
                fontWeight: 700,
                fontSize: '15px',
                lineHeight: '25px',
              }}
            >
              Please select your contingent beneficiaries
              <Tooltip
                title="if adding multiple beneficiaries, allocation must total 100%"
                placement="bottom-end"
                arrow
                enterTouchDelay={100}
              >
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <InfoOutlinedIcon
                    sx={{ height: '20px', color: '#00000038' }}
                  />
                </div>
              </Tooltip>
            </Box>

            {/* for future use */}
            {/* <FormGroup sx={{margin: '0px 0px 10px 0px'}}>
                <FormControlLabel
                  control={
                    <Switch
                      // checked={evenSplitPrimary}
                      // onChange={() => setEvenSplitPrimary(!evenSplitPrimary)}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  }
                  label="Evenly split allocations"
                />
          </FormGroup> */}

            {contingentExpanded &&
              props.contingentBeneficiaries?.length > 0 &&
              props.contingentBeneficiaries?.map((contingentBeneficiary, i) => (
                <div
                  className="flex-row"
                  style={{ alignItems: 'center', marginBottom: '23px' }}
                  key={
                    contingentBeneficiary?.id ||
                    contingentBeneficiary.dependentId
                  }
                >
                  <Checkbox
                    checked={
                      props.contingentCheckboxes.find((checkObj) => {
                        if (
                          checkObj.id &&
                          checkObj.id == contingentBeneficiary.id
                        ) {
                          // console.log('found cont id', checkObj)
                          return checkObj;
                        } else if (
                          checkObj.dependentId &&
                          checkObj.dependentId ==
                            contingentBeneficiary.dependentId
                        ) {
                          // console.log('ELSE found', checkObj)
                          return checkObj;
                        } else return null;
                      })?.checked || false
                    }
                    onClick={(e) => {
                      handleContingentBeneficiaryClick(
                        e,
                        contingentBeneficiary
                      );
                      toggleContingentAutoAllocate(i);
                    }}
                    sx={{ padding: '0px', marginRight: '10px' }}
                    icon={<CheckBoxOutlineBlankIcon sx={{ height: '19px' }} />}
                    checkedIcon={
                      <CheckBoxIcon
                        sx={{ height: '19px', color: 'primary.main' }}
                      />
                    }
                  />

                  <div className="col-4">
                    <div style={{ fontWeight: 600, fontSize: '15px' }}>
                      {contingentBeneficiary.firstName}{' '}
                      {contingentBeneficiary.lastName}
                    </div>

                    <div style={{ fontSize: '15px', color: '#646464' }}>
                      {relationshipTypes &&
                        relationshipTypes.find(
                          (relationshipType) =>
                            relationshipType.id ==
                            contingentBeneficiary.relationshipTypeId
                        )?.name}
                    </div>
                  </div>

                  <div className="col-6">
                    <TextField
                      fullWidth
                      label="allocation"
                      error={contingentTotalError}
                      type="number"
                      autoComplete="off"
                      InputProps={{
                        sx: { borderRadius: '8px', height: '40px' },
                        startAdornment: (
                          <InputAdornment position="start">%</InputAdornment>
                        ),
                      }}
                      InputLabelProps={{ shrink: true }}
                      inputProps={{
                        max: '100',
                        min: '0',
                        type: 'number',
                        'data-allocate': enableContingentInputAutoAllocate[i],
                      }}
                      onFocus={(e) => e.target.select()}
                      onBlur={(e) => disableContingentAutoAllocate(i)}
                      value={
                        Number(
                          props.contingentCheckboxes.find((checkObj) => {
                            if (checkObj.id)
                              return checkObj.id == contingentBeneficiary.id;
                            else if (checkObj.dependentId)
                              return (
                                checkObj.dependentId ==
                                contingentBeneficiary.dependentId
                              );
                          })?.amount
                        ) || ''
                      }
                      onChange={(e) =>
                        manualContingentAllocationHandler(
                          e,
                          contingentBeneficiary,
                          i
                        )
                      }
                      disabled={
                        !props.contingentCheckboxes.find((checkObj) => {
                          if (
                            checkObj.id &&
                            checkObj.id === contingentBeneficiary.id
                          )
                            return checkObj;
                          else if (
                            checkObj.dependentId &&
                            checkObj.dependentId ===
                              contingentBeneficiary.dependentId
                          )
                            return checkObj;
                          else return undefined;
                        })?.checked
                      }
                    />
                  </div>
                </div>
              ))}

            <div className="flex-row" style={{ marginBottom: '35px' }}>
              {/* <Link underline='none' sx={{fontSize:'15px',color:'#195FFB'}}
          onClick={handleContingentOpen}
          >
             + add contingent beneficiary
          </Link> */}
              {/* <Button variant="text" fontSize="15px"
                      onClick={e => props.setContingentBennyAdd(true)}>
                      + add contingent beneficiary
                   </Button> */}
              <Checkbox
                checked={props.contingentBennyAdd}
                onClick={() => props.setContingentBennyAdd((state) => !state)}
                sx={{ padding: '0px', marginRight: '10px' }}
                icon={<CheckBoxOutlineBlankIcon sx={{ height: '19px' }} />}
                checkedIcon={
                  <CheckBoxIcon
                    sx={{ height: '19px', color: 'primary.main' }}
                  />
                }
              />

              <div className="" style={{ fontWeight: 600, fontSize: '15px' }}>
                Add additional contingent beneficiary
              </div>
            </div>
            {props.contingentBennyAdd && (
              <>
                <div className="flex-row" style={{ marginBottom: '15px' }}>
                  <TextField
                    fullWidth
                    label="First Name"
                    variant="outlined"
                    value={props.contingentBennyAdd.FirstName || ''}
                    onChange={handleInputChange}
                    inputProps={{
                      'data-update': 'firstNameContingent',
                    }}
                  />
                </div>

                <div className="flex-row" style={{ marginBottom: '15px' }}>
                  <TextField
                    fullWidth
                    label="Last Name"
                    variant="outlined"
                    value={props.contingentBennyAdd.LastName || ''}
                    onChange={handleInputChange}
                    inputProps={{
                      'data-update': 'lastNameContingent',
                    }}
                  />
                </div>

                <div className="flex-row" style={{ marginBottom: '15px' }}>
                  <TextField
                    fullWidth
                    select
                    sx={{
                      '& fieldset': { borderRadius: '8px' },
                      //   '& fieldset > legend': { display: 'none' },
                      '&.MuiOutlinedInput-root.Mui-focused > fieldset': {
                        border: '2px solid #195ffb',
                      },
                    }}
                    variant="outlined"
                    label="Relationship"
                    value={props.contingentBennyAdd.RelationshipTypeId || ''}
                    onChange={(e) =>
                      props.setContingentBennyAdd({
                        ...props.contingentBennyAdd,
                        RelationshipTypeId: e.target.value,
                      })
                    }
                  >
                    {relationshipTypes &&
                      relationshipTypes?.map((relationshipType) => (
                        <MenuItem
                          key={relationshipType.id}
                          value={relationshipType.id}
                        >
                          {relationshipType.name}
                        </MenuItem>
                      ))}
                  </TextField>
                </div>

                <div className="flex-row" style={{ marginBottom: '15px' }}>
                  {/* {isMobileDevice
                  ?
                  <Box sx={{width:'100%'}}>
                    <LocalizationProvider required={false} dateAdapter={AdapterDateFns}>
                      <DatePicker required={false}
                        // onBlur={(e) => checkError("dob")}
                        disableFuture
                        openTo='year'
                        views={['year', 'month', 'day']}
                        value={props.contingentBennyAdd.dob || ''} onChange={e => handleMobileDobChange(e,false)}
                        format='MM/dd/yyyy'
                        renderInput={(params) =>
                          <TextField {...params}
                            // error={dobError}
                            // onBlur={(e) => checkError("dob")}
                            variant='outlined' label='Date of birth'
                            placeholder="mm/dd/yyyy"
                            fullWidth
                            required={false}
                            // InputLabelProps={{ sx: { color: 'primary.main' } }}
                          />
                        }
                      />
                    </LocalizationProvider>
                  </Box>
                  :
                  <Box sx={{width:'100%'}}>
                    <TextField fullWidth
                      type={props.contingentBennyAdd.dob ? 'date' : ''}
                      required={false}
                      // error={dobError}
                      label="Date of Birth" variant="outlined"
                      value={props.contingentBennyAdd.dob || ''}
                      onChange={(e) => props.setContingentBennyAdd({ ...props.contingentBennyAdd, dob: e.target.value })}
                      onFocus={handleDobFocus} onBlur={handleDobBlur}
                      // InputLabelProps={{ sx: { color: 'primary.main' } }}
                    />
                  </Box>
                } */}

                  <Box sx={{ width: '100%' }}>
                    <LocalizationProvider
                      required={false}
                      dateAdapter={AdapterDateFns}
                    >
                      <DatePicker
                        required={false}
                        // onBlur={(e) => checkError("dob")}
                        disableFuture
                        label="Date of birth"
                        openTo="year"
                        views={['year', 'month', 'day']}
                        value={props.contingentBennyAdd.dob || null}
                        onChange={(e) => handleMobileDobChange(e, false)}
                        format="MM/dd/yyyy"
                        sx={{ width: '100%' }}
                        slotProps={{
                          openPickerButton: { sx: { visibility: 'hidden' } }, //hide calendar icon calandar
                        }}
                      />
                    </LocalizationProvider>
                  </Box>
                </div>

                <div
                  className="flex-row"
                  style={{ flexWrap: 'wrap', marginBottom: '40px' }}
                >
                  <div className="col-7" />
                  <div className="col row">
                    {/* <div className='col' >
                            <Button variant='outlined'
                               sx={{
                                  borderRadius: '100px',
                                  height: "40px", border: '1px solid #195ffb!important', fontWeight: '500!important'
                               }}
                               onClick={() => props.setContingentBennyAdd(false)}
                            >
                               Cancel
                            </Button>
                         </div> */}
                    <div className="" style={{ textAlign: 'end' }}>
                      <Button
                        variant="contained"
                        fullWidth
                        disabled={disableSaveContingent}
                        sx={{
                          borderRadius: '100px',
                          height: '40px',
                          fontWeight: '500!important',
                        }}
                        onClick={handleAddContingentBeneficiary}
                      >
                        Save
                      </Button>
                    </div>
                  </div>
                </div>
              </>
            )}
          </Collapse>
          {!contingentExpanded && (
            <>
              {props.contingentBeneficiaries &&
                props.contingentBeneficiaries?.map(
                  (contingentBeneficiary, i) => {
                    let contingentCheck = props.contingentCheckboxes.find(
                      (contingentCheck) =>
                        contingentCheck.id == contingentBeneficiary.id
                    );
                    if (contingentCheck?.checked) {
                      return (
                        <div
                          className="flex-row"
                          style={{
                            justifyContent: 'space-between',
                            marginTop: '5px',
                            marginBottom: '5px',
                          }}
                        >
                          <span
                            style={{
                              fontWeight: 500,
                              fontSize: '15px',
                              color: '#646464',
                            }}
                          >
                            {contingentBeneficiary.firstName}{' '}
                            {contingentBeneficiary.lastName}
                          </span>

                          <span
                            style={{
                              fontWeight: 700,
                              fontSize: '15px',
                              color: '#646464',
                            }}
                          >
                            {contingentCheck.amount}%
                          </span>
                        </div>
                      );
                    }
                  }
                )}
            </>
          )}

          {/* <div className="flex-row" style={{ textAlign: 'center', fontSize: '15px', color: '#646464', marginBottom: '20px', padding: '0px 30px' }}>
             if adding multiple beneficiaries, allocation must total 100%
          </div> */}

          {/* <StickyProgressTotal progress={90} nextClickHandler={handleNextClick} /> */}
          {!props.agentEnroller && <PoweredBy />}

          {props.agentEnroller && (
            <AgentNavigation
              nextLocation={'personalInfo'}
              allowBack={true}
              lastLocation={'plans'}
              nextClickHandler={props.nextClickHandler}
              agentEnrollmentPageState={props.agentEnrollmentPageState}
              setAgentEnrollmentPageState={props.setAgentEnrollmentPageState}
              primaryBeneficiaries={props.primaryBeneficiaries}
              addedBenny={addedBenny}
              primaryTotalError={primaryTotalError}
            />
          )}
        </div>
      </div>
    </>
  );
}
