import React, {
  useEffect,
  useLayoutEffect,
  useState,
  useContext,
  Suspense,
  lazy,
} from 'react';
// import { useHistory } from "react-router-dom";
import { useHistory } from 'react-router';
// import { format, differenceInCalendarYears } from "date-fns";

//Assets
// import PlaceHolderLogo from '../../../assets/images/placeHolder.svg';
// import FullColorBlackText from "../../../assets/images/FullColorBlackText.svg";
// import defaultLogo from "../../../assets/images/FullColorOnlyLogo.png";

//Muicons
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import Close from '@mui/icons-material/Close';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import MenuIcon from '@mui/icons-material/Menu';
import AccountCircle from '@mui/icons-material/AccountCircle';

//MuiComponents
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import AlerTitle from '@mui/material/AlertTitle';
import Fade from '@mui/material/Fade';
import MobileStepper from '@mui/material/MobileStepper';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Drawer from '@mui/material/Drawer';
import Home from '@mui/icons-material/Home';
import Icon from '@mui/material/Icon';
import ArrowDropDown from '@mui/icons-material/ArrowDropDown';
import Checkbox from '@mui/material/Checkbox';
import Collapse from '@mui/material/Collapse';
import Link from '@mui/material/Link';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';

//Services
// import enrollmentService from "../../../services/enrollment-service";
// import attachmentService from "../../../services/attachment-service";
import memberService from '../../../../services/member-service';

//Our Components
// import PoweredBy from "../../external-enrollments/PoweredBy";
import InfinityLoader from '../../../UH-loading-animation/InfinityLoader';
import { set } from 'date-fns';
import userService from '../../../../services/user-service';
import registerService from '../../../../services/register-service';

export default function PortalSettings(props) {
  const [loading, setLoading] = useState(false);

  const [memberInfo, setMemberInfo] = useState(props.thisMember);
  useEffect(() => {
    setMemberInfo(props.thisMember);
  }, [props.thisMember]);

  const handleMemberUpdate = (e) => {
    let key = e.target.name;
    let value = e.target.value;
    setMemberInfo({ ...memberInfo, [key]: value });
  };

  const handleMemberSave = async () => {
    if (makePrimary) {
      let _primaryEmail = memberInfo.email;
      let _secondaryEmail = memberInfo.secondEmail;
      memberInfo.email = _secondaryEmail;
      memberInfo.secondEmail = _primaryEmail;
    }
    setMakePrimary(false);
    try {
      await memberService.updateMember(memberInfo);
    } catch (error) {
      console.log(error);
    }

    await props.getThisMember();
  };

  const handleCancelUpdate = () => {
    setMemberInfo(props.thisMember);
    setMakePrimary(false);
  };

  const [makePrimary, setMakePrimary] = useState(false);

  const [passwordReset, setPasswordReset] = useState({
    currentPassword: '',
    newPassword: '',
    confirmNewPassword: '',
    verificationCode: (Math.round(Math.random(0) * 1000) + 1000).toString(),
  });
  const sendPasswordReset = async () => {
    await registerService.forgotPassword(
      props?.thisUser?.email,
      passwordReset?.verificationCode
    );
  };
  const handlePasswordUpdate = (e) => {
    let key = e.target.name;
    let value = e.target.value;
    setPasswordReset({ ...passwordReset, [key]: value });
  };
  const handlePasswordSave = async () => {
    try {
      await userService.updatePassword(
        passwordReset?.newPassword,
        passwordReset?.currentPassword
      );
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      {loading && (
        <>
          <InfinityLoader
            style={{
              position: 'absolute',
              zIndex: 5,
              width: '100%',
              height: '100vh',
              backgroundColor: '#00000050',
              backdropFilter: 'blur(10px)',
            }}
          />
          <style>
            {`
                      div.lottie-div > svg {
                         height:55px!important;
                         position:absolute;
                         top:50%;
                      }
                   `}
          </style>
        </>
      )}
      <Box
        // component={'main'}
        className="flex-container"
        flex={1}
        overflow="auto"
        style={{
          flexWrap: 'wrap',
          minHeight: '700px',
          //  margin: 'auto',
        }}
        sx={{
          bgcolor: 'background.default',
          color: 'text.primary',
          paddingTop: '66px',
          paddingBottom: '66px',
        }}
      >
        <Box
          className="row"
          // component={'main'}
          sx={{
            display: 'flex',
            maxWidth: '575px',
            margin: '0 auto',
            // maxHeight: "1000px",
            // height:'10000px',
            paddingLeft: '18px',
            paddingRight: '18px',
            // gap: '20px',
          }}
        >
          <Typography variant="subtitle1" sx={{ fontWeight: 600 }}>
            Settings
          </Typography>
          <Typography
            variant="body1"
            sx={{ fontSize: '16px', marginBottom: '20px' }}
          >
            Update Email Preferences
          </Typography>

          <Box className="col-12" sx={{ marginBottom: '20px' }}>
            <TextField
              className="col-12"
              variant="outlined"
              label="Primary Email"
              name="email"
              value={memberInfo?.email || ''}
              onChange={handleMemberUpdate}
              fullWidth
            />
          </Box>

          <Box className="col-12" sx={{ marginBottom: '10px' }}>
            <TextField
              className="col-12"
              variant="outlined"
              label="Secondary Email"
              name="secondEmail"
              value={memberInfo?.secondEmail || ''}
              onChange={handleMemberUpdate}
              fullWidth
            />
          </Box>

          <Box
            className="col-12"
            sx={{ display: 'flex', justifyContent: 'flex-end' }}
          >
            <FormControl
              onChange={(e) => {
                setMakePrimary((prev) => !prev);
              }}
              defaultValue=""
              name=""
              className=""
            >
              <FormControlLabel
                control={<Checkbox value={true} checked={makePrimary} />}
                label="Make Primary"
              />
            </FormControl>
          </Box>

          {(props.thisMember?.email != memberInfo?.email ||
            props.thisMember?.secondEmail != memberInfo?.secondEmail ||
            makePrimary) && (
            <Box
              className="col-12"
              sx={{ display: 'flex', justifyContent: 'space-between' }}
            >
              <Button
                variant="text"
                color="primary"
                sx={{ marginTop: '20px' }}
                onClick={handleCancelUpdate}
              >
                Cancel
              </Button>

              <Button
                variant="contained"
                color="primary"
                sx={{ marginTop: '20px' }}
                onClick={handleMemberSave}
              >
                Update
              </Button>
            </Box>
          )}

          <Divider
            sx={{
              // width: 'calc(100% + 32px)',
              marginBottom: '16px',
              marginTop: '16px',
              color: 'background.border',
              opacity: 0.5,
            }}
          />
          <Typography variant="body1" sx={{ fontSize: '16px' }}>
            Reset Password
          </Typography>

          <Typography
            variant="body1"
            sx={{ fontSize: '15px', marginBottom: '20px' }}
          >
            For a strong password, try choosing a unique phrase that you don’t
            use on any other accounts.
          </Typography>

          <Box className="col-12" sx={{ marginBottom: '24px' }}>
            <TextField
              className="col-12"
              variant="outlined"
              label="Current Password"
              name="currentPassword"
              value={passwordReset.currentPassword || ''}
              onChange={handlePasswordUpdate}
              fullWidth
            />
          </Box>

          <Box
            className="col-12"
            sx={{
              marginBottom: '24px',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Button
              disabled
              variant="text"
              color="primary"
              sx={{ marginTop: '20px' }}
              onClick={sendPasswordReset}
            >
              Forgot your password?
            </Button>
          </Box>

          <Box className="col-12" sx={{ marginBottom: '24px' }}>
            <TextField
              className="col-12"
              variant="outlined"
              label="New Password"
              name="newPassword"
              value={passwordReset.newPassword || ''}
              onChange={handlePasswordUpdate}
              fullWidth
            />
          </Box>

          <Box className="col-12" sx={{ marginBottom: '24px' }}>
            <TextField
              className="col-12"
              variant="outlined"
              label="Confirm Password"
              name="confirmNewPassword"
              value={passwordReset.confirmNewPassword || ''}
              onChange={handlePasswordUpdate}
              fullWidth
            />
          </Box>

          <Box className="col-12" sx={{ textAlign: 'center' }}>
            <Button
              disabled={true}
              fullWidth
              variant="contained"
              onClick={handlePasswordSave}
            >
              Confirm
            </Button>
          </Box>
        </Box>
      </Box>

      {/* {!isAuthenticated && history.push('/login')} */}
    </>
  );
}
