import React from 'react';

//MuiComponents
import Slider from '@mui/material/Slider';
import Radio from '@mui/material/Radio';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

//Mui Icons
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import RadioButtonIcon from '@mui/icons-material/RadioButtonUnchecked';

//Our icons
import CustomCheckIcon from '../../../../../../assets/images/CustomCheckIcon.svg';

//Our Components
import RecommendedBenefitDisplay from '../../../RecommendedBenefitDisplay';

//Our Enrollment Plan Styles
import enrollmentPlanStyles from '../../enrollmentPlanStyles';

export default function EnrollmentLaddMember(props) {
  let UsdFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
  });
  let UsdFormatterDec = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
  });

  let results = props.results;
  let lifeADDPlan = results?.lifeADDPlan;
  let dataUHTest = props.dataUHTest;

  const memberValuetext = (value) =>
    `${UsdFormatter.format(lifeADDPlan?.memberOptions[value]?.coverageAmount)}`;
  // console.log(UsdFormatter.format(results?.lifeADDPlan.memberOptions[props.memberSlider].coverageAmount))

  const autoCompleteHandler = (e) => {
    let tagName = e.target.tagName.toLowerCase();
    if (tagName == 'li') {
      let value = e.target.innerHTML.slice(1).replace(',', '');
      if (value.includes('(max)')) value = value.replace('(max)', '');
      if (value.includes('(recommended)'))
        value = value.replace('(recommended)', '');
      for (let i = 0; i < lifeADDPlan.memberOptions.length; i++) {
        let coverageAmount = lifeADDPlan.memberOptions[i]?.coverageAmount;
        if (coverageAmount == value) {
          props.setMemberSlider(i);
        }
      }
    } else if (tagName == 'input') {
      let value = e.target.value.slice(1).replace(',', '');
      if (value.includes('(max)')) value = value.replace('(max)', '');
      if (value.includes('(recommended)'))
        value = value.replace('(recommended)', '');
      for (let i = 0; i < lifeADDPlan.memberOptions.length; i++) {
        let coverageAmount = lifeADDPlan.memberOptions[i]?.coverageAmount;
        if (coverageAmount == value) {
          props.setMemberSlider(i);
        }
      }
    }
  };

  let maxCoverage =
    lifeADDPlan?.memberOptions[lifeADDPlan?.memberOptions.length - 1]
      ?.coverageAmount;

  //display value logic for dropdown select
  let benefitSelectionValue = () => {
    if (
      lifeADDPlan.memberOptions[props.memberSlider]?.coverageAmount ===
        maxCoverage &&
      maxCoverage !== props?.currRec
    ) {
      //last but not rec amount
      return `${UsdFormatter.format(
        lifeADDPlan.memberOptions[props.memberSlider]?.coverageAmount
      )} (max)`;
    } else if (
      lifeADDPlan.memberOptions[props.memberSlider]?.coverageAmount ===
        maxCoverage &&
      maxCoverage === props?.currRec
    ) {
      //last and rec amount
      return `${UsdFormatter.format(
        lifeADDPlan.memberOptions[props.memberSlider]?.coverageAmount
      )} (recommended)`;
    } else if (
      lifeADDPlan.memberOptions[props.memberSlider]?.coverageAmount ===
        props?.currRec &&
      props?.currRec !== maxCoverage
    ) {
      //rec amount but not last
      return `${UsdFormatter.format(
        lifeADDPlan.memberOptions[props.memberSlider]?.coverageAmount
      )} (recommended)`;
    } else {
      return UsdFormatter.format(
        lifeADDPlan.memberOptions[props.memberSlider]?.coverageAmount
      );
    }
  };

  return (
    <>
      {/* Radio Button and Option Row */}
      <Box sx={enrollmentPlanStyles.optionRow}>
        <Box sx={enrollmentPlanStyles.optionRadioAndTextContainer}>
          <Radio
            sx={enrollmentPlanStyles.planSelectRadioButton}
            value={props.checkboxes.member}
            checked={
              props.checkboxes.member &&
              props?.checkboxes?.member !== 'truthy' &&
              props?.checkboxes?.member !== 'decline' &&
              !props?.memberLocalDecline
            }
            onChange={props?.laddSelectionHandler}
            name={'0'}
            icon={
              <RadioButtonIcon
                sx={enrollmentPlanStyles.radioButtonIcon(false)}
              />
            }
            checkedIcon={
              <RadioButtonCheckedIcon
                sx={enrollmentPlanStyles.radioButtonIcon(false)}
              />
            }
            inputProps={{
              'data-amount':
                lifeADDPlan?.memberOptions[props.memberSlider]?.premiumAmount,
              'data-person': 'member',
              'data-uhtest': dataUHTest,
              'data-coverage':
                lifeADDPlan?.memberOptions[props.memberSlider]?.coverageAmount,
            }}
          />

          <Box sx={enrollmentPlanStyles.singleOptionTextContainer}>
            <Typography
              sx={enrollmentPlanStyles.singleOptionText(
                props.checkboxes.member &&
                  props?.checkboxes?.member !== 'decline'
              )}
            >
              Member (
              {UsdFormatter.format(
                results?.lifeADDPlan.memberOptions[props.memberSlider]
                  ?.coverageAmount
              )}
              )
            </Typography>
          </Box>
        </Box>

        {/* premium amount */}
        <Box
          sx={enrollmentPlanStyles.singleOptionText(
            props.checkboxes.member && props?.checkboxes?.member !== 'decline'
          )}
        >
          {props.checkboxes.member === true && '+'}
          {!props.dummyDisplay &&
            UsdFormatterDec.format(
              results?.lifeADDPlan.memberOptions[props.memberSlider]
                ?.premiumAmount
            )}
          {props.dummyDisplay && '$0.00 '}
        </Box>
      </Box>

      <RecommendedBenefitDisplay currRec={props?.currRec} />

      <Box sx={enrollmentPlanStyles.benefitAmountDropdownContainer}>
        <Autocomplete
          disablePortal
          fullWidth
          autoComplete
          options={lifeADDPlan?.memberOptions.map((option, i) => {
            let isLast = i == lifeADDPlan.memberOptions.length - 1;
            if (isLast && props?.currRec !== option?.coverageAmount) {
              // last but not rec amount
              return `${UsdFormatter.format(option.coverageAmount)} (max)`;
            } else if (isLast && props?.currRec === option?.coverageAmount) {
              //last and = rec amount
              return `${UsdFormatter.format(
                option.coverageAmount
              )} (recommended)`;
            } else if (!isLast && props?.currRec === option?.coverageAmount) {
              //not last and = rec amount
              return `${UsdFormatter.format(
                option.coverageAmount
              )} (recommended)`;
            } else {
              return UsdFormatter.format(option.coverageAmount);
            }
          })}
          value={benefitSelectionValue() || 0}
          onChange={autoCompleteHandler}
          renderInput={(params) => (
            <TextField
              {...params}
              InputLabelProps={{ sx: { color: 'primary.main' } }}
              label="Coverage Amount"
              sx={enrollmentPlanStyles.activeBlueDropdownField(false)}
            />
          )}
          componentsProps={{
            popupIndicator: { sx: { color: 'primary.main' } },
          }}
        />
      </Box>

      {/* Slider  */}
      <Box sx={enrollmentPlanStyles.incSliderSection}>
        {/* Slider min */}
        <Box sx={enrollmentPlanStyles.incSliderLowAmount}>
          {`$${results?.lifeADDPlan.memberOptions[0]?.coverageAmount / 1000}K`}
        </Box>

        {/* Slider container */}
        <Box sx={enrollmentPlanStyles.incSliderContainer}>
          <Slider
            onChange={props.memberSliderChange}
            disabled={props.checkboxes.member !== true}
            value={props.memberSlider}
            step={1}
            min={0}
            max={results?.lifeADDPlan.memberOptions.length - 1}
            sx={enrollmentPlanStyles.incSlider}
            valueLabelDisplay="auto"
            valueLabelFormat={memberValuetext}
          />
        </Box>

        {/* Slider max */}
        <Box sx={enrollmentPlanStyles.incSliderHighAmount}>
          {`$${
            results?.lifeADDPlan.memberOptions[
              results?.lifeADDPlan.memberOptions.length - 1
            ]?.coverageAmount / 1000
          }K`}
        </Box>
      </Box>
    </>
  );
}
