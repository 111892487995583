import { useEffect, useState } from "react"
import { format, addMonths, subMonths, addYears, subYears } from "date-fns"

//Mui components
import Box from "@mui/material/Box"
import MobileStepper from "@mui/material/MobileStepper"
import Paper from "@mui/material/Paper"
import Typography from "@mui/material/Typography"
import Button from "@mui/material/Button"
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft"
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight"
import IconButton from "@mui/material/IconButton"
import { TextField } from "@mui/material"
import { DatePicker } from "@mui/x-date-pickers/DatePicker"
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"

export default function EditCoverageEffectiveDateStepper(props) {

  //todo: pass down update effective date funtion (create this in parent component that holds all enrollment summary cards)
  //set props.setEffectiveDate locally and update in db with 'SAVE CHANGES' button click

  const today = new Date()
  const maxDate = addMonths(today, 11) // Allow scrolling up to 12 months in advance
  
  const [currentEffectiveDate, setCurrentEffectiveDate] = useState(today)

  //set the local state with current effective date from props if it exists
  useEffect(() => {
    if (props?.currentEffectiveDate) {
      //set current effective date as current date in db and make into date obj
      setCurrentEffectiveDate(new Date(props?.currentEffectiveDate))
    }
  }, [props?.currentEffectiveDate])

  const handlePrevMonth = () => {
    setCurrentEffectiveDate(subMonths(currentEffectiveDate, 1))
  }

  const handleNextMonth = () => {
    setCurrentEffectiveDate(addMonths(currentEffectiveDate, 1))
  }

  return (
    <Box sx={{ width: "100%"}}>
      <TextField
        variant="outlined"
        disabled={true}
        size="small"
        label="Effective Date"
        value={format(currentEffectiveDate, "MMMM yyyy")}
        sx={{input: {textAlign: 'center'}}}
        InputProps={{
          sx: { 
            borderRadius: "4px", 
            fontSize: '16px', 
        },
          startAdornment: (
            <IconButton
              onClick={handlePrevMonth}
              edge="start"
              // disabled={currentEffectiveDate < today}
              disabled={true}
            >
              <KeyboardArrowLeft />
            </IconButton>
          ),
          endAdornment: (
            <IconButton
              onClick={handleNextMonth}
              edge="end"
              // disabled={currentEffectiveDate >= maxDate}
              disabled={true}
            >
              <KeyboardArrowRight />
            </IconButton>
          ),
        
        }}
      />
    </Box>
  )
}
