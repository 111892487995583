import React, {useState, useEffect} from 'react'
import { set, format } from 'date-fns';



//Mui icons
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

//Mui Components
import Chip from "@mui/material/Chip";
import Fade from "@mui/material/Fade";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/system";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Collapse } from "@mui/material";
import Select from '@mui/material/Select';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from "@mui/icons-material/MoreVert";
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';

//our components
import InfinityLoader from "../../../../../../../UH-loading-animation/InfinityLoader";
import EditCoverageEffectiveDateStepper from '../EditCoverageEffectiveDateStepper';


export default function EnrollmentSummaryCardADD (props) {

   let plan = props.plan

   const [loading, setLoading] = useState(false)

   const [edit, setEdit] = useState()
   //This is bolding the payment info on the right side when edit==true
   useEffect(() => {
      if (props.setCurrentEdit){
         if (edit) {
            props.setCurrentEdit({
               ...props.currentEdit,
               add: true
            })
         }
         else {
            props.setCurrentEdit({
               ...props.currentEdit,
               add: false
            })
         }
      }
   }, [edit])

   const [addPlan, setAddPlan] = useState()
   useEffect(() => {
      if (plan) setAddPlan(plan)
   }, [plan])

   let handleAddPlanChange = (e) => {
      let value = e.target.value;
      // console.log(addPlan)
      setAddPlan({
         ...addPlan,
         selectedOptionId: value
      })
   }

   let updateSinglePlan = async () => {
      setLoading(true)
      await props.updateSinglePlan('addPlan', addPlan)
      await props.getThisPlan()
      //setTimeout(() => {
      //   setEdit(false)

      //   setLoading(false)
      //},[2000])
      setEdit(false)

       setLoading(false)
   }

   const [coveredLives,setCoveredLives] = useState('')
   const [selectedOption,setSelectedOption] = useState({})
   const [premiumAmount,setPremiumAmount] = useState(0)

   useEffect(() => {
      if(addPlan){
         let memberIsSelected;
         let memberAndSpouseIsSelected;
         let memberAndDependentsIsSelected;
         let familyIsSelected;
         let selectedOption;
         for (let option of addPlan.options) {
            if(addPlan?.selectedOptionId == option.memberOptionId) memberIsSelected = true
            if(addPlan?.selectedOptionId == option.memberAndSpouseOptionId) memberAndSpouseIsSelected = true
            if(addPlan?.selectedOptionId == option.memberAndDependentsOptionId) memberAndDependentsIsSelected = true
            if(addPlan?.selectedOptionId == option.familyOptionId) familyIsSelected = true
            if (memberIsSelected || memberAndSpouseIsSelected || memberAndDependentsIsSelected || familyIsSelected) {
               selectedOption = option
               setSelectedOption(option)
               break
            }
         }
         let premiumAmount = 0
         let _coveredLives = ''
         if (memberIsSelected) {
            premiumAmount = selectedOption?.memberPremiumAmount
            _coveredLives = 'Member'
         } 
         else if (memberAndSpouseIsSelected) {
            premiumAmount = selectedOption?.memberAndSpousePremiumAmount 
            _coveredLives = 'Member and Spouse'
         }
         else if (memberAndDependentsIsSelected) {
            premiumAmount = selectedOption?.memberAndDependentsPremiumAmount 
            _coveredLives = 'Member and Dependents'
         }
         else if (familyIsSelected) {
            premiumAmount = selectedOption?.familyPremiumAmount
            _coveredLives = 'Family'
         } 
         if (premiumAmount == 0) {
            _coveredLives = "Declined"
         }
         setCoveredLives(_coveredLives)
         setPremiumAmount(premiumAmount)
      }
   },[addPlan])

   
   const [editEffectiveDate, setEditEffectiveDate] = useState(false)
   const updateCoverageEffectiveDate = async () => {

      setLoading(true)
      // await props.updateCoverageEffectiveDate('stdPlan', stdPlan)
      // await props.getThisPlan()
      //setTimeout(() => {
      //   setEditEffectiveDate(false)
      //   setLoading(false)
      //},[2000])
      setEditEffectiveDate(false)
      setLoading(false)
   }

 //open verticle dot menu to edit or cancel coverage
 const [anchorEl, setAnchorEl] = useState({});

 const handleOpenRowMenu = (e) => {
   setAnchorEl({
     ...anchorEl,
     [e.currentTarget.value]: e.currentTarget,
   });
 };


 const handleCloseRowMenu = () => {
   setAnchorEl({});
 };
 
  //Open cancel coverage drawer
  const handleOpenCancelCoverageDrawer = (e, plan, planName) => {
   props.handleOpenDrawer(plan, planName);
   handleCloseRowMenu();
}

   return (
      <>
   <Box className="flex-row" sx={{padding:'8px 18px', flexWrap:'wrap'}}>
      <div className="flex-row col-12" style={{marginBottom:'15.5px'}}>
         <div className="col-6">
            <Typography component={'span'} sx={{fontFamily:'Poppins', fontWeight:500, fontSize:'20px', lineHeight:'32px', letterSpacing:'.15px', color:'#195FFB'}}>
               {props.planName.toUpperCase()}
            </Typography>
         </div>

         <div className="col-6" style={{display:'flex',justifyContent:'flex-end', alignItems:'center'}}>
            <Typography component={'span'} sx={{fontFamiily:'Poppins', fontWeight:400, fontSize:'24px', lineHeight:'32px', color:'#195FFB'}}>
               {props.UsdFormatterDec.format(premiumAmount || 0)}
            </Typography>
            <Typography component={'span'} sx={{fontFamily:'Archivo', fontWeight:400, fontSize:'14px', lineHeight:'20px', letterSpacing:'.17px', color:'text.secondary'}}>
               /per month
            </Typography>

           {props?.activeCoveragePlanSummary &&
            <>
                  <IconButton
                  onClick={(e) => handleOpenRowMenu(e, props?.plan)}
                  value={props?.plan?.planId}
                  sx={{ padding: "0px", marginLeft: "12px" }}
                  >
                  <MoreVertIcon
                     sx={{
                        color: "text.secondary",
                        float: "right",
                     }}
                  />
                  </IconButton>
                  <Menu
                  anchorEl={anchorEl?.[props?.plan.planId]}
                  open={Boolean(anchorEl?.[props?.plan.planId])}
                  onClose={handleCloseRowMenu}
                  anchorOrigin={{
                     vertical: "top",
                     horizontal: "left",
                  }}
                  transformOrigin={{
                     vertical: "top",
                     horizontal: "left",
                  }}
                  >
                  <MenuItem
                     onClick={(e) => {
                        if(props?.activePlansNotPendingCancel?.length > 1){
                          //cancel single line of coverage
                          handleOpenCancelCoverageDrawer(e, props?.plan, props?.planName);
                        }else if (props?.activePlansNotPendingCancel?.length === 1){
                          //cancel all coverage
                          props?.openCancelAllCoverageModal();
                        }
                     }}
                     disabled={!props.allowModify || props?.pendingCancellation}
                  >
                     {/* if not pending cancel and not last active line of coverage */}
                     {!props?.pendingCancellation && props?.planName && props?.activePlansNotPendingCancel?.length > 1 && `Cancel ${props?.planName.toUpperCase() } Coverage`}

                     {/* if not pending cancel and is last active line of coverage */}
                     {!props?.pendingCancellation && props?.planName && props?.activePlansNotPendingCancel?.length === 1 && 'Cancel All Coverage'}

                     {/* if pending cancel */}
                     {props?.pendingCancellation && 'Pending Cancellation'}
                  </MenuItem>

                  <MenuItem
                     //   value={}
                     onClick={() => {
                        setEdit(true);
                        handleCloseRowMenu();
                     }}
                     disabled={!props.allowModify}
                  >
                     Edit Coverage
                  </MenuItem>
                  {/* )} */}
                  </Menu>
          </>}
         </div>
      </div>

      {props.pendingCancellation && props.pendingCancellationObj && premiumAmount <= 0 &&
         props.pendingCancellationWarning(props.pendingCancellationObj)
      }

      <div className="flex-row col-12" style={{marginBottom:'16px'}}>
         <div className="col-6">
            <Typography variant="body1">
               Benefit Amount
            </Typography>
         </div>

         <div className="col-6" style={{textAlign:'end'}}>
            <Typography variant="body1">
               {selectedOption?.name || 'Declined'}
            </Typography>
         </div>
      </div>

      <div className="flex-row col-12" style={{justifyContent: 'space-between'}}>
         <div className="col-6">
            <Typography variant="body1">
               Covered Lives
            </Typography>
         </div>

         <div className="col-5" style={{textAlign:'end'}}>
            {!edit &&
               <Typography variant="body1" sx={{height:'40px'}}>
                  {coveredLives}
               </Typography>
            }
            {edit &&
               <FormControl fullWidth sx={{ marginBottom: "10px" }}>
                  <InputLabel id="coveredLives">Covered Lives</InputLabel>
                  <Select variant='outlined' size='small' fullWidth label="Covered Lives" labelId="coveredLives"
                     sx={{
                        height: "40px", borderRadius: "4px", padding: "10px 0px", width: "100%",
                        "&.MuiOutlinedInput-root.Mui-focused > fieldset": {
                          border: "2px solid #195ffb",
                        }, textAlign: "left", 
                      }}
                     IconComponent={() => <KeyboardArrowDownIcon sx={{ position: 'absolute', right: 7 }} />}
                     value={addPlan.selectedOptionId}
                     onChange={handleAddPlanChange}
                  >
                     {addPlan && addPlan.options.sort((a,b) => a.displayOrder - b.displayOrder).map(option => {
                        if (!option.isDecline) {
                           let itemList = []
                           if (option.memberPremiumAmount > 0) itemList.push(
                              <MenuItem value={option.memberOptionId} key={option.memberOptionId}>
                                 Member {props.UsdFormatterDec.format(option?.memberPremiumAmount || 0)}
                              </MenuItem>
                           )
                           if (option.memberAndSpousePremiumAmount > 0) itemList.push(
                              <MenuItem value={option.memberAndSpouseOptionId} key={option.memberAndSpouseOptionId}>
                                 Member and Spouse {props.UsdFormatterDec.format(option?.memberAndSpousePremiumAmount || 0)}
                              </MenuItem>
                           )
                           if (option.memberAndDependentsPremiumAmount > 0) itemList.push(
                              <MenuItem value={option.memberAndDependentsOptionId} key={option.memberAndDependentsOptionId}>
                                 Member and Dependent {props.UsdFormatterDec.format(option?.memberAndDependentsPremiumAmount || 0)}
                              </MenuItem>
                           )
                           if (option.familyPremiumAmount > 0) itemList.push(
                              <MenuItem value={option.familyOptionId} key={option.familyOptionId}>
                                 Family {props.UsdFormatterDec.format(option?.familyPremiumAmount || 0)}
                              </MenuItem>
                           )
                           return (
                              itemList.map(item => item)
                           )
                        } else {
                           return (
                              <MenuItem value={option.memberOptionId} key={option.memberOptionId}>
                                 Decline
                              </MenuItem>
                           )
                        }
                     })}

                  </Select>
               </FormControl>
            }
         </div>
      </div>

      {props.allowModify &&
         <div className="flex-row col-12" style={{  }}>
            {!edit && !editEffectiveDate &&
            <div className="col-12 flex-row">
                 Effective Date:{" "}
                {props?.plan?.effectiveDate
                  ? format(props?.formatDate(props?.plan?.effectiveDate), "MM/dd/yyyy")
                  : "N/A"}
            </div>
               
            }
            {(edit || editEffectiveDate) &&
               <>
                  <div
                     className=""
                     style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        marginTop: '10px'
                  }}
                  >
                  
                     <EditCoverageEffectiveDateStepper currentEffectiveDate={props?.plan?.effectiveDate}/>
             

                     <div className="col-5 flex-row"   style={{ justifyContent: "flex-end" }}>
                        <Button
                           variant="outlined" 
                           // fullWidth
                           sx={{ borderRadius: '8px', height: '40px', fontWeight: '500!important', marginRight:'8px'}}
                           onClick={() => {
                              setEdit(false)
                              setEditEffectiveDate(false)
                           }} 
                           // disabled={!props.allowModify}
                        >
                           BACK
                        </Button>

                        <Button
                           variant="contained" 
                           // fullWidth
                           sx={{ borderRadius: '8px', height: '40px', fontWeight: '500!important', }}
                           onClick={edit ? updateSinglePlan : updateCoverageEffectiveDate}
                           disabled={loading}
                        >
                           {loading ?
                           <>
                              <div style={{width:'100%',position:'absolute'}}>
                                 <InfinityLoader style={{
                                    zIndex:5,
                                    height:'38px',
                                 }}/> 
                              </div>
                              <span style={{opacity:0}}>SAVE CHANGES</span>
                           </>
                           :
                           <>
                             SAVE CHANGES
                           </>
                           }
                        </Button>
                     </div>
                  </div>
               </>
            }

         </div>
      }
   </Box>
   <hr style={{marginTop:'20px', color: "text.primary"}}/>
   </>
   )
}