import React from 'react';

import Typography from '@mui/material/Typography';
import { formatDateObjMMDDYYYYDisplay, formatCurrency } from '../../../utils';

export default function PortalPaymentSummary(props) {
  // console.log('props paySummary', props);
  return (
    <>
      <Typography
        component={'div'}
        className="col-12"
        sx={{
          fontFamily: 'Archivo',
          textTransform: 'uppercase',
          fontSize: '12px',
          letterSpacing: '1px',
          color: 'primary.main',
        }}
      >
        {props.enrollment.name}
      </Typography>

      <Typography
        component={'div'}
        className="col-12"
        sx={{
          color: 'text.primary',
          fontFamily: 'Poppins',
          fontSize: '20px',
          fontWeight: 600,
          letterSpacing: '.15px',
        }}
      >
        {formatCurrency(props.payment[0]?.paymentAmount || 0)}
        <Typography
          component={'span'}
          sx={{
            fontSize: '14px',
            fontWeight: 400,
            color: 'text.secondary',
            letterSpacing: '.17px',
            fontFamily: 'Archivo',
          }}
        >
          &nbsp;/mo
        </Typography>
      </Typography>

      <Typography
        component={'div'}
        className="col-12"
        sx={{
          fontSize: '14px',
          fontWeight: 400,
          color: 'text.primary',
          letterSpacing: '.17px',
          fontFamily: 'Archivo',
        }}
      >
        Next payment{' '}
        {formatDateObjMMDDYYYYDisplay(
          new Date(props.payment[0]?.nextOccurance)
        )}
      </Typography>
    </>
  );
}
