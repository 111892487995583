import React, { useEffect, useState, useRef } from "react"
import { format, differenceInCalendarYears, set } from "date-fns"

//Assets
import FallbackLogo from "../../../../../../../assets/images/SourceFileonly icon.png"

//Services
import planService from "../../../../../../../services/plan-service"
import enrollmentService from "../../../../../../../services/enrollment-service"
import activeCoverageService from "../../../../../../../services/activeCoverage-service"
import optionService from "../../../../../../../services/optionService"

//Mui icons
import EventIcon from "@mui/icons-material/Event"
// import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown"

//Mui Components
import Chip from "@mui/material/Chip"
import Fade from "@mui/material/Fade"
import Typography from "@mui/material/Typography"
import { Box } from "@mui/system"
import Button from "@mui/material/Button"
import ButtonGroup from "@mui/material/ButtonGroup"
import Menu from "@mui/material/Menu"
import MenuItem from "@mui/material/MenuItem"
import { Collapse } from "@mui/material"
import Skeleton from "@mui/material/Skeleton"
import Alert from "@mui/material/Alert"
import Divider from "@mui/material/Divider"
import Tooltip from "@mui/material/Tooltip"

//Our Components
import ActiveLineOfCoverage from "./activeCoverage-summary-plans/ActiveLineOfCoverage"
import ActiveLineOfCoverageLadd from "./activeCoverage-summary-plans/ActiveLineOfCoverageLadd"
import EnrollmentSummaryCardLTD from "./enrollment-summary-plans/EnrollmentSummaryCardLTD"
import EnrollmentSummaryCardSTD from "./enrollment-summary-plans/EnrollmentSummaryCardSTD"
import EnrollmentSummaryCardLADD from "./enrollment-summary-plans/EnrollmentSummaryCardLADD"
import EnrollmentSummaryCardAccident from "./enrollment-summary-plans/EnrollmentSummaryCardAccident"
import EnrollmentSummaryCardCancer from "./enrollment-summary-plans/EnrollmentSummaryCardCancer"
import EnrollmentSummaryCardADD from "./enrollment-summary-plans/EnrollmentSummaryCardADD"
import EnrollmentSummaryCardLTDIncremental from "./enrollment-summary-plans/EnrollmentSummaryCardLTDIncremental"
import EnrollmentSummaryCardSTDIncremental from "./enrollment-summary-plans/EnrollmentSummaryCardSTDIncremental"
import EnrollmentSummaryCardHospital from "./enrollment-summary-plans/EnrollmentSummaryCardHospital"
import CancelCoverageDrawer from "./CancelCoverageDrawer"
import CancelAllCoverageModal from "./CancelAllCoverageModal"
import ReinstateCoverageDrawer from "./ReinstateCoverageDrawer"

//Our active coverage global styles
import activeCoverageStyles from "./activeCoverage-summary-plans/activeCoverageStyles"

//utils
import { getPersonTypeFromId } from "../../../../../../utils"
//component styles
const componentStyles = {
  menuOptions: {
    color: "text.primary",
    fontFamily: "Archivo",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "24px",
    height: "48px",
    "&:hover": { backgroundColor: "primary.light" },
  },
  collapsedPlanSection: {
    minHeight: "119px",
    padding: "8px 18px",
    display: "flex",
    flexDirection: "row",
    // border: '1px solid blue'
  },
  collapsedPlanInfoContainer: {
    padding: "20px 0px 5px 15px",
    flex: 4,
    // border: "1px solid red",
  },
  planTitlAndMenuRow: {
    display: "flex",
    flexDirection: "row",
    marginBottom: "10px",
    justifyContent: "space-between",
  },
  planImageContainer: {
    marginTop: "20px",
    textAlign: "center",
    maxWidth: "120px",
  },
  planMenuContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    textAlign: "end",
  },
  collapsedLineOfCoverageContainer: {
    paddingBottom: "4px",
    width: "100%",
    // border: '1px solid green'
  },
  planNameAndDateRow: {
    display: 'flex', 
    flexDirection: "row", 
    flexWrap: 'wrap'
  },
  planNameAndDate: {
    fontFamily: "Archivo",
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "22px",
    letterSpacing: ".1px",
    color: "text.gray",
    marginLeft: '6px'
  },
  policyNumberText: {
    fontFamily: "Archivo",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "28px",
    letterSpacing: ".15px",
    color: "text.gray",
  },
  dividerStyle: {
    color: "text.secondary", height: "1px"
  }
}
export default function PlanSummaryCard(props) {

  let UsdFormatterDec = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 2,
  })
  let formatDate = props?.formatDate

  //get plan info from enrollmetn Id to show options for editing individual lines of coverage
  const [planInfo, setPlanInfo] = useState(null)

  const getPlanOptionsInfo = async () => {
    let planInfo
    try {
      planInfo = await enrollmentService.getEnrollmentById(
        props?.currentActiveCoverage.enrollmentId
      )
      if (planInfo) {
        console.log("plan infoooooo", planInfo)
        setPlanInfo(planInfo)
      } else {
        setPlanInfo(props?.currentActiveCoverage)
      }
    } catch (error) {
      console.log(
        `Error grabbing enrollment ${props?.currentActiveCoverage.enrollmentId}`,
        error
      )
      setPlanInfo(props?.currentActiveCoverage)
    }
  }

  useEffect(() => {
    if (props?.currentActiveCoverage && !planInfo) {
      getPlanOptionsInfo()
    }
  }, [props?.currentActiveCoverage?.linesOfCoverage?.length])

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ]

  const formatEffective = (date) => {
    // let fullDate = new Date(props?.enrollmentResults?.coverageEffectiveDate);
    let sliceDate = date.slice(0, 10)
    let thisYear = Number(sliceDate.slice(0, 4))
    let thisMonth = Number(sliceDate.slice(5, 7)) - 1
    return `${months[thisMonth]} ${thisYear} Effective`
  }

  ////////////////// ACTIVE COVERAGE STUFF /////////////////////////////////

  const [activeCoveragePlansAndOptions, setActiveCoveragePlansAndOptions] =
    useState([])
  //organize life/LADD plans for member/spouse and dep into a single group
  const [activeLaddPlansAndOptions, setActiveLaddPlansAndOptions] = useState([])

  //match each active line of coverage with the corresponding planInfo options for editing individual lines of coverage
  useEffect(() => {
    if (planInfo && props?.currentActiveCoverage?.linesOfCoverage?.length > 0) {
      let _activeCoveragePlansAndOptions =
        props?.currentActiveCoverage?.linesOfCoverage?.map((plan) => {
          let _matchingPlanOptions
          for (let singlePlan in planInfo) {
            if (plan.planId == planInfo[singlePlan]?.planId) {
              _matchingPlanOptions = planInfo[singlePlan]
            }
          }
          //build object of active plan and matching plan options for that plan
          return { plan: plan, options: _matchingPlanOptions }
        })
      console.log(
        "\n\n\nactiveCoveragePlansAndOptions",
        _activeCoveragePlansAndOptions
      )
      if (_activeCoveragePlansAndOptions.length > 0) {
        setActiveCoveragePlansAndOptions(_activeCoveragePlansAndOptions)
      }

      //separate out life plans into a single group
      let _lifePlans = _activeCoveragePlansAndOptions.filter((plan) => {
        if (plan?.plan?.displayName.toUpperCase()?.includes("LADD")) {
          return plan
        }
      })

      if (_lifePlans.length > 0) {
        console.log("LADD PLAN GROUP", _lifePlans)
        setActiveLaddPlansAndOptions(_lifePlans)
      }
    }
  }, [planInfo, props?.currentActiveCoverage?.linesOfCoverage])

  //filter out all active coverages that are not pending cancelation
  const [activePlansNotPendingCancel, setActivePlansNotPendingCancel] =
    useState([])

  console.log(activePlansNotPendingCancel)
  useEffect(() => {
    if (props?.currentActiveCoverage?.linesOfCoverage.length > 0) {
      let _activePlans = props?.currentActiveCoverage?.linesOfCoverage?.filter(
        (plan) => {
          if (!plan?.isCancelled) {
            return plan
          }
        }
      )
      setActivePlansNotPendingCancel(_activePlans)
    }
  }, [props?.currentActiveCoverage?.linesOfCoverage])

  //show/hide expanded plan section
  const [resumeAnchorEl, setResumeAnchorEl] = useState(null)
  const [collapse, setCollapse] = useState(false)

  const handleRegenerateClick = async () => {
    console.log("Trying to regenerate...")
    let sendEmail = false
    try {
      //await enrollmentService.generateEnrollmentReport(
      //  activeCoveragePlansAndOptions[0]?.plan?.enrollmentId,
      //  sendEmail
        //)
        await enrollmentService.generateACReport(
            activeCoveragePlansAndOptions[0]?.plan?.enrollmentId,
            new Date(),
            sendEmail
        )
        console.log("Went through regeneration...?")
    } catch (error) {
      console.log("error generating enrollment report", error)
    }
    setTimeout(async () => {
      await props.getAllDocuments()
    }, 200)
    setResumeAnchorEl(null)
  }

  const handleRegenerateFutureClick = async () => {
    console.log("Trying to regenerate...")
    let sendEmail = false
    try {
      //await enrollmentService.generateEnrollmentReport(
      //  activeCoveragePlansAndOptions[0]?.plan?.enrollmentId,
      //  sendEmail
        //)
        var date = new Date();
        date.setMonth(date.getMonth() + 1, 1);
        await enrollmentService.generateACReport(
            activeCoveragePlansAndOptions[0]?.plan?.enrollmentId,
            date,
            sendEmail
        )
        console.log("Went through regeneration...?")
    } catch (error) {
      console.log("error generating enrollment report", error)
    }
    setTimeout(async () => {
      await props.getAllDocuments()
    }, 200)
    setResumeAnchorEl(null)
  }

  /////////////CANCEL (reason and date) LOGIC /////////////////
  //Date - range: today OR first of next month
  const today = new Date()
  const todayString = today.toISOString().slice(0, 10)
  const todayFormatted = format(today, "MM/dd/yyyy")
  const currentMonth = today.getMonth() + 1
  const lastDayOfMonth = new Date(today.getFullYear(), currentMonth, 0)
  const endOfCurrentMonth = lastDayOfMonth.toISOString().slice(0, 10)
  const beginningOfNextMonth = new Date(today.getFullYear(), currentMonth, 1)
  const beginningOfNextMonthString = beginningOfNextMonth.toISOString().slice(0, 10)
  const beginningOfNextMonthFormatted = format(beginningOfNextMonth, "MM/dd/yyyy")


  const dateSelectOptions = [
    {
      name: 'Today',
      value: todayString,
      displayDate: todayFormatted
    },
    {
      name: 'Next Month',
      value: beginningOfNextMonthString,
      displayDate: beginningOfNextMonthFormatted
    }
  ]

  const [dateOfCancelation, setDateOfCancelation] = useState(beginningOfNextMonthString)
  const [cancelDateError, setCancelDateError] = useState(false)
  //REASON 
  const [reasonForCancelation, setReasonForCancelation] = useState("")

  const handleChangeReasonForCancelation = (e) => {
    setReasonForCancelation(e.target.value)
  }

  useEffect(() => {
    setDateOfCancelation(beginningOfNextMonthString)
  }, [])

  //cancelation date selection
  const handleCancelDateChange = (e) => {
    let inputValue = e.target.value
    //check if date is within range before updating value
    setDateOfCancelation(inputValue)
  }

  //not currently allowing specific date selection
  // const checkCancelDateError = () => {
  //   console.log(
  //     "date stuff",
  //     todayString === endOfCurrentMonth &&
  //       dateOfCancelation === endOfCurrentMonth
  //   )
  //   //cannot cancel in past and can only cancel today if it is last day of month
  //   if (
  //     todayString === endOfCurrentMonth &&
  //     dateOfCancelation === endOfCurrentMonth
  //   ) {
  //     setCancelDateError(false)
  //   } else if (dateOfCancelation < tomorrowString) {
  //     setCancelDateError(
  //       "Date of cancelation must be after today and before end of month"
  //     )
  //   }
  //   //cannot cancel after end of month
  //   if (
  //     dateOfCancelation > endOfCurrentMonth &&
  //     todayString !== endOfCurrentMonth
  //   ) {
  //     setCancelDateError(
  //       "Date of cancelation cannot be after the end of the current month"
  //     )
  //   }
  //   if (
  //     dateOfCancelation >= tomorrowString &&
  //     dateOfCancelation <= endOfCurrentMonth
  //   ) {
  //     setCancelDateError(false)
  //   }
  // }

  // CANCEL SINGLE LOGIC
  const [cancelCoverageDrawerOpen, setCancelCoverageDrawerOpen] =
    useState(false)

  const [planToCancel, setPlanToCancel] = useState(null) 
  const [planToReinstate, setPlanToReinstate] = useState(null)

  const handleOpenCancelCoverageDrawer = (e, planSelected) => {
    console.log("planSelected", planSelected)
    if(planSelected === 'cancelAllCoverage'){
      setCancelAllCoverage(true)
    }
    if (planSelected){ 
      setPlanToCancel({ ...planSelected })
    }
    setCancelCoverageDrawerOpen(true)
    //close menu
    setResumeAnchorEl(null)
  }

  //CANCEL ALL LOGIC
  const [cancelAllCoverage, setCancelAllCoverage] = useState(false)
  const handleCancelAllCoverage = async () => {
    let memberId = props?.member?.id

    //iterate over each active coverage and check current isCancelled status
      //if not cancelled, continue with cancel
    //STEFAN - I added in cancel all option. Later we will want to add in a new separate method for cancel all, but for now I'm just using the same method
      // activeCoveragePlansAndOptions?.forEach(async (plan) => {
      let plan = activeCoveragePlansAndOptions[0]
        let activeCoverageId = plan?.plan?.id
        let cancelReason = `Cancel all coverage - ${reasonForCancelation}`
        let cancelAll = true
        try {
          await activeCoverageService.cancelActiveCoverageForMember(
            activeCoverageId,
            cancelReason,
            dateOfCancelation,
            cancelAll
          )
        } catch (error) {
          console.error("error canceling active coverage", error)
        }
    //})
    setTimeout(() => {
      //refresh enrollment data
      props?.getActiveCoverage(memberId)
      //refresh payment schedules
      props?.getPaymentSchedule()
    }, 500)
  }

  //ACTIVE COVERAGE Pending cancelation or modification warning
  //can still use this w/ updated cancel active coverage - need to pass in endDate
  const pendingChangesMessage = (plan) => {
    let _formattedDate;
    let _futureCoverageTitle = '';
    //determine if future coverage has benefit amount or covered lives - some accident, add, hospital, cancer will have 0 benefit amount, but still have premium and covered lives
    if(plan?.futureBenefitAmount > 0){
      //check for ADD coverage specifically - this has both benefit amount AND covered lives - show futureCoveredLives value
      if (plan?.displayName === 'ADD'){
        _futureCoverageTitle = `Future benefit ${props?.UsdFormatterDec.format(
          plan?.futureBenefitAmount
         )} | Covered lives - ${plan?.futurePersonTypeId >= 0 ? getPersonTypeFromId(plan?.futurePersonTypeId) : ''} | Premium ${props?.UsdFormatterDec.format(
           plan?.futurePremiumAmount
         )} `
      } else {
        _futureCoverageTitle = `Future benefit ${props?.UsdFormatterDec.format(
                 plan?.futureBenefitAmount
                )} | Premium ${props?.UsdFormatterDec.format(
                  plan?.futurePremiumAmount
                )} `

      }
    } else if (plan?.futureBenefitAmount === 0 && plan?.futurePremiumAmount > 0){
      _futureCoverageTitle = `Future covered lives - ${plan?.futurePersonTypeId >= 0 ? getPersonTypeFromId(plan?.futurePersonTypeId) : ''} |  Premium ${props?.UsdFormatterDec.format(plan?.futurePremiumAmount)}`
    }

    //cancelled plan display
    if (plan?.isCancelled) {
      //show current plan end date for cancelled plan
      _formattedDate = format(props?.formatDate(plan?.endDate), "MM/dd/yyyy")
      return (
        <Box sx={activeCoverageStyles.pendingChangesContainer}>
          <Chip
            sx={activeCoverageStyles.pendingChangesChip("cancel")}
            label={`Cancellation scheduled for ${_formattedDate}` || ""}
          />
        </Box>
      )
      //modified plan display
    } else if (plan?.futureActiveCoverageId && !plan?.isCancelled) {
      //show future plan begin date for edited plan
      _formattedDate = format(props?.formatDate(plan?.futureBeginDate), "MM/dd/yyyy")
      return (
        <Box sx={activeCoverageStyles.pendingChangesContainer}>
          <Tooltip 
            title={_futureCoverageTitle}
            placement="bottom-start"
            arrow
            >
          <Chip
            sx={activeCoverageStyles.pendingChangesChip("modified")}
            label={
              `Coverage changes scheduled for ${_formattedDate}` || ""}
          />
          </Tooltip>
        </Box>
      )
    }
  }

  const handleLogoError = (e) => {
    e.target.src = FallbackLogo
  }

  //Policy Number - get unique array of numbers that can differ for each line of active coverage
  const [uniquePolicyNumbers, setUniquePolicyNumbers] = useState([])

  useEffect(() => {
    if (props?.currentActiveCoverage?.linesOfCoverage?.length > 0) {
      let _policyNumsArr = props?.currentActiveCoverage?.linesOfCoverage?.map(
        (plan) => {
          if (plan?.policyNumber !== "" && plan?.policyNumber !== " " && plan?.policyNumber) {
            return plan?.policyNumber
          }
        }
      )
      let _uniquePolicyNums = [...new Set(_policyNumsArr)]
      setUniquePolicyNumbers(_uniquePolicyNums)
    }
  }, [props?.currentActiveCoverage?.linesOfCoverage?.length])

  const [reinstateCoverageDrawerOpen, setReinstateCoverageDrawerOpen] = useState(false)

  const handleOpenReinstateDrawer = (e, planSelected) => {
    if (planSelected) setPlanToReinstate({ ...planSelected })
      setReinstateCoverageDrawerOpen(true)
    }

    const formatDisplayNameFriendly = (plan) => {
      switch(plan?.displayName){
        case 'LADD':
          return 'LIFE-Member'
        case 'LADD-S':
          return 'LIFE-Spouse'
        case 'LADD-D':
          return 'LIFE-Dependent'
        default:
          return plan?.displayName
      }
    }

  return (
    <>
      <CancelCoverageDrawer
        cancelCoverageDrawerOpen={cancelCoverageDrawerOpen}
        setCancelCoverageDrawerOpen={setCancelCoverageDrawerOpen}
        planToCancel={planToCancel}
        planEnrollmentId={planInfo?.enrollmentId}
        setPlanToCancel={setPlanToCancel}
        formatDate={formatDate}
        getActiveCoverage={props?.getActiveCoverage}
        getAllDocuments={props?.getAllDocuments}
        cancellationReasons={props.cancellationReasons}
        updateSinglePlan={props.updateSinglePlan}
        member={props.member}
        groupPlanId={planInfo?.groupPlanId}
        getPaymentSchedule={props?.getPaymentSchedule}
        getMemberHistory={props?.getMemberHistory}
        //cancel all coverage
        handleCancelAllCoverage={handleCancelAllCoverage}
        cancelAllCoverage={cancelAllCoverage}
        setCancelAllCoverage={setCancelAllCoverage}
        //reason
        reasonForCancelation={reasonForCancelation}
        setReasonForCancelation={setReasonForCancelation}
        handleChangeReasonForCancelation={handleChangeReasonForCancelation}
        //select date props
        dateOfCancelation={dateOfCancelation}
        setDateOfCancelation={setDateOfCancelation}
        handleCancelDateChange={handleCancelDateChange}
        cancelDateError={cancelDateError}
        setCancelDateError={setCancelDateError}
        endOfCurrentMonth={endOfCurrentMonth}
        beginningOfNextMonthString={beginningOfNextMonthString}
        dateSelectOptions={dateSelectOptions}
      />

      <ReinstateCoverageDrawer
        reinstateCoverageDrawerOpen={reinstateCoverageDrawerOpen}
        setReinstateCoverageDrawerOpen={setReinstateCoverageDrawerOpen}
        planToReinstate={planToReinstate}
        planEnrollmentId={planInfo?.enrollmentId}
        setPlanToReinstate={setPlanToReinstate}
        formatDate={formatDate}
        getActiveCoverage={props?.getActiveCoverage}
        getAllDocuments={props?.getAllDocuments}
        cancellationReasons={props.cancellationReasons}
        updateSinglePlan={props.updateSinglePlan}
        member={props.member}
        groupPlanId={planInfo?.groupPlanId}
        getPaymentSchedule={props?.getPaymentSchedule}
        //select date props
        dateOfCancelation={dateOfCancelation}
        setDateOfCancelation={setDateOfCancelation}
        handleCancelDateChange={handleCancelDateChange}
        cancelDateError={cancelDateError}
        setCancelDateError={setCancelDateError}
        endOfCurrentMonth={endOfCurrentMonth}
        beginningOfNextMonthString={beginningOfNextMonthString}
        dateSelectOptions={dateSelectOptions}
      />

      <Box sx={componentStyles.collapsedPlanSection}>
        <Box sx={componentStyles.planImageContainer}>
          <Fade in={props?.member?.organizationId ? true : false}>
            <img
              src={
                props.member &&
                `api/image/organization/${props.member?.organizationId}`
              }
              alt="organization logo"
              width="100%"
              onError={handleLogoError}
            />
          </Fade>
        </Box>

        <Box sx={componentStyles.collapsedPlanInfoContainer}>
          <Box sx={componentStyles.planTitlAndMenuRow}>
            <Typography variant="h6">
              {planInfo?.name ? planInfo.name : <Skeleton width="16rem" />}
            </Typography>

            <Box sx={componentStyles.planMenuContainer}>
              <ButtonGroup
                ref={resumeAnchorEl}
                disabled={activeCoveragePlansAndOptions.length < 1}
                variant="text"
                sx={{ height: "36px" }}
              >
                <Button
                  // endIcon={<ArrowDropDownIcon  />}
                  sx={{
                    fontFamily: "Archivo",
                    fontWeight: 500,
                    fontSize: "14px",
                    lineHeight: "24px",
                    letterSpacing: ".4px",
                  }}
                  onClick={() => {
                    setCollapse(!collapse)
                  }}
                  disabled={activeCoveragePlansAndOptions.length < 1}
                >
                  {!collapse ? "SHOW" : "HIDE"}
                </Button>

                <Button
                  size="small"
                  sx={{ width: "32px!important", padding: "0px" }}
                  onClick={(e) => setResumeAnchorEl(e.currentTarget)}
                  disabled={activeCoveragePlansAndOptions.length < 1}
                >
                  <ArrowDropDownIcon />
                </Button>
              </ButtonGroup>

              <Menu
                anchorEl={resumeAnchorEl}
                open={resumeAnchorEl ? true : false}
                fullWidth
                onClose={() => setResumeAnchorEl(null)}
                sx={{ 'ul': { padding: '0px' }, justifyContent: "flex-start" }}
                PaperProps={{
                  style: {
                    borderRadius: "4px",
                    // width:'127px',
                    padding: '0px'
                  }
                }}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
              >
                <MenuItem
                  onClick={handleRegenerateClick}
                  sx={componentStyles.menuOptions}
                >
                  Re-Generate PDF (current coverage)
                </MenuItem>
                <MenuItem
                  onClick={handleRegenerateFutureClick}
                  sx={componentStyles.menuOptions}
                >
                  Re-Generate PDF (future coverage)
                </MenuItem>
                <MenuItem
                  onClick={(e) => handleOpenCancelCoverageDrawer(e, 'cancelAllCoverage')}
                  disabled={
                    activeCoveragePlansAndOptions.length < 1 ||
                    (activePlansNotPendingCancel.length < 1 &&
                      activeCoveragePlansAndOptions.length > 0)
                  }
                  sx={componentStyles.menuOptions}
                >
                  Cancel All Coverage
                </MenuItem>
              </Menu>
            </Box>
          </Box>

          {/* Plan Lines of Coverage Summary */}
          <Box sx={componentStyles.collapsedLineOfCoverageContainer}>
            {/* Display Policy Numbers */}
            {props?.currentActiveCoverage?.linesOfCoverage?.length > 0 &&
              uniquePolicyNumbers?.length > 0 ? (
              uniquePolicyNumbers?.map((num, i) => {
                if(num){
                  return (
                    <Typography key={i} sx={componentStyles.policyNumberText}>
                      {`Policy #${num}`}
                    </Typography>
                  )
                }
                else return ''
              })
            ) : (
              <Typography key={0} sx={componentStyles.policyNumberText}>
                Policy Number N/A{" "}
              </Typography>
            )}

            {/* Display Active Coverage Plans List */}
            <Box>
              {activeCoveragePlansAndOptions.length > 0 &&
                activeCoveragePlansAndOptions.map((plan) => {
                  let singlePlan = plan?.plan
                  return (
                    <Box
                      key={singlePlan?.benefitAmount}
                      sx={componentStyles.planNameAndDateRow}
                    >
                      <Typography
                        variant="body2"
                        sx={componentStyles.planNameAndDate}
                      >
                        {formatDisplayNameFriendly(singlePlan)}
                        :
                      </Typography>

                      {/* show effective date if not pending cancellation or modification sinclePlan.futureActiveCoverage: 0 = not modified*/}
                      {!singlePlan?.isCancelled &&
                        !singlePlan.futureActiveCoverageId && (
                          <Typography
                            variant="body2"
                            sx={componentStyles.planNameAndDate}
                          >
                            {formatEffective(singlePlan?.effectiveStartDate)}
                          </Typography>
                        )}

                      {/* show pending cancelation*/}
                      {singlePlan?.isCancelled && //temporarily hidden until isCancelled is added by Stefan
                        singlePlan?.endDate > todayString &&
                        singlePlan?.endDate?.slice(0, 4) != "9999" ? ( // temporarily here until isCancelled is added by Stefan
                        <Typography
                          variant="body2"
                          sx={{
                            ...componentStyles.planNameAndDate,
                            color: "#C62828",
                          }}
                        >
                          PENDING CANCELLATION{" "}
                          {format(
                            props?.formatDate(singlePlan?.endDate),
                            "MM/dd/yyyy"
                          )}
                        </Typography>
                      ) : null}

                      {/* show canceled line of coverage*/}
                      {singlePlan?.isCancelled &&
                        singlePlan?.endDate < todayString ? (
                        <Typography
                          variant="body2"
                          sx={componentStyles.planNameAndDate}
                        >
                          CANCELED{" "}
                          {format(
                            props?.formatDate(singlePlan?.endDate),
                            "MM/dd/yyyy"
                          )}
                        </Typography>
                      ) : null}

                      {/* show modified line of coverage */}
                      {singlePlan?.futureActiveCoverageId &&
                        !singlePlan?.isCancelled && //temporarily hidden until isCancelled is added by Stefan
                        todayString <= singlePlan?.endDate ? (
                        <Typography
                          variant="body2"
                          sx={{
                            ...componentStyles.planNameAndDate,
                            color: "primary.pendingModifiedCoverage",
                          }}
                        >
                          PENDING COVERAGE CHANGES{" "}
                          {format(
                            props?.formatDate(singlePlan?.futureBeginDate), //coverage changes have futureBeginDate that we use instead of endDate - cancel coverage only has endDate
                            "MM/dd/yyyy"
                          )}
                        </Typography>
                      ) : null}
                    </Box>
                  )
                })}
            </Box>
          </Box>
        </Box>
      </Box>

      {/* Expanded Plan section */}

      <Collapse in={collapse}>
        <Divider sx={componentStyles.dividerStyle} />
        {/*\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\ display all plans active coverage except ladd  */}
        {activeCoveragePlansAndOptions.length > 0 &&
          activeCoveragePlansAndOptions.map((plan) => {
            //render all plans except LADD
            if (!plan?.plan?.displayName.toUpperCase()?.includes("LADD")) {
              return (
                <>
                  <ActiveLineOfCoverage
                    key={plan?.plan?.planOptionId}
                    member={props.member}
                    singlePlan={plan}
                    UsdFormatterDec={UsdFormatterDec}
                    formatDate={formatDate}
                    allowModify={props.allowModify}
                    allowEditEnrollment={props.allowEditEnrollment}
                    handleOpenCancelCoverageDrawer={
                      handleOpenCancelCoverageDrawer
                    }
                    // openCancelAllCoverageModal={openCancelAllCoverageModal}
                    getActiveCoverage={props.getActiveCoverage}
                    pendingChangesMessage={pendingChangesMessage}
                    activePlansNotPendingCancel={activePlansNotPendingCancel}
                    handleOpenReinstateDrawer={handleOpenReinstateDrawer}
                    getPaymentSchedule={props.getPaymentSchedule}
                  />
                  <Divider sx={componentStyles.dividerStyle} />
                </>
              )
            }
          })}

        {/* display ladd active coverage*/}
        {activeLaddPlansAndOptions.length > 0 ? (
          <ActiveLineOfCoverageLadd
            activeLaddPlansAndOptions={activeLaddPlansAndOptions}
            UsdFormatterDec={UsdFormatterDec}
            formatDate={formatDate}
            member={props.member}
            relationshipTypes={props.relationshipTypes}
            dependents={props?.dependents}
            spouse={props?.spouse}
            allowModify={props.allowModify}
            allowEditEnrollment={props.allowEditEnrollment}
            handleOpenCancelCoverageDrawer={handleOpenCancelCoverageDrawer}
            // openCancelAllCoverageModal={openCancelAllCoverageModal}
            getActiveCoverage={props.getActiveCoverage}
            pendingChangesMessage={pendingChangesMessage}
            activePlansNotPendingCancel={activePlansNotPendingCancel}
            handleOpenReinstateDrawer={handleOpenReinstateDrawer}
            getPaymentSchedule={props.getPaymentSchedule}
          />
        ) : (
          ""
        )}
      </Collapse>
    </>
  )
}
